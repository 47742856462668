import WasmController from "react-lib/frameworks/WasmController";
import preflightM from "issara-sdk/apis/preflightM";
import RequestToken from "issara-sdk/apis/RequestTokenAPI_users";
import GetProviderInfo from "issara-sdk/apis/GetProviderInfo_apps_HRM";
import DivisionHasUserList from "issara-sdk/apis/DivisionHasUserList_apps_PRX";
import DeviceList from "issara-sdk/apis/DeviceList_core";
import RoleList from "issara-sdk/apis/RoleList_users";
import UserProfileAPI from "issara-sdk/apis/UserProfileAPI_users";
import ScreenListView from "issara-sdk/apis/ScreenListView_users";
import GetCurrentDoctorView from "issara-sdk/apis/GetCurrentDoctorView_core";
import MyPositionDetail from 'issara-sdk/apis/MyPositionDetail_users';
import MyPermissionView from "issara-sdk/apis/MyPermissionView_users";
import DivisionList from "issara-sdk/apis/DivisionList_core";
import DivisionHasUserListForOtherUser from "issara-sdk/apis/DivisionHasUserListForOtherUser_apps_PRX";
import UserEmployeeDetailAPIView from "issara-sdk/apis/UserEmployeeDetailAPIView_users";
import UserDetailAPIView from "issara-sdk/apis/UserDetailAPIView_users";

import { Mode } from "../Model";
import * as CONSTANT from "react-lib/utils/constant";
import config from "config/config";
import Cookies from "js-cookie";
import DivisionDetail from "issara-sdk/apis/DivisionDetail_core";
import {
  registerServiceWorker,
  createNotificationSubscription,
  getUserSubscription,
} from "react-lib/apps/IsHealth/Common/push-notifications";
import * as serviceWorker from "serviceWorker";
import Fingerprint2 from "fingerprintjs2";
import axios from "axios";
import { getSideMenuIndex } from "../CommonInterface";

export type State = {
  django?: any;
  isDoctor?: boolean;
  isNurse?: boolean;
  providerInfo?: any;
  providerEmployeeInfo?: any;
  userRoleList?: any;
  divisionType?: string | null;
  divisionList?: any[];
  divisionOptions?: any[];
  divisionHasUserItems?: any[];
  selectedDivision?: any;
  deviceOptions?: any[];
  selectedDevice?: any;
  showMenuNoti?: boolean;
  openVideoCallModal?: boolean;
  closeOverriding?: boolean;
  videoCallRoom?: string;

  loggedin?: boolean;
  currentDoctor?: any;
  userPosition?: any,
  myPermission?: any,
  // offline Mode
  isOffline?: boolean;

  AppInitializeSequence?: {
    sequenceIndex: string | null;
    username?: string;
    password?: string;
  } | null;

  mode?: Mode;
  resetLayout?: boolean;
  openPassword?: boolean;
  loadingLogin?: boolean;
  errorMessage?: null;
  initialLayout?: number;

  userId?: string;
  // From CU
  showColorInfo?: boolean;
  showTabName?: string;
};

export const StateInitial: State = {
  django: {},
  isDoctor: false,
  isNurse: false,
  providerInfo: null,
  providerEmployeeInfo: null,
  userRoleList: [],
  divisionType: null,
  divisionList: [],
  divisionOptions: [],
  divisionHasUserItems: [],
  selectedDivision: null,
  deviceOptions: [],
  selectedDevice: null,
  showMenuNoti: false,

  loggedin: false,
  currentDoctor: null,
  userPosition: null,
  myPermission: null,

  isOffline: false,

  AppInitializeSequence: null,

  mode: "patient",
  resetLayout: false,
  openPassword: false,
  loadingLogin: false,
  errorMessage: null,
  initialLayout: 0,

  userId: Cookies.get("user_id"),
  showColorInfo: false,
  showTabName: ""
};

export type Event = { message: "RunSequence"; params: {} };

export type Data = {
  division?: number;
  divisionHasUser?: any[];
  divisionDict?: any;
  allDivisionList?: any[];
  divisionDetail?: any;
  device?: number;
  deviceDict?: any;
  user?: number;
  provider?: number;
  userProfile?: Record<string, any>;
};

export const DataInitial = {
  division: 0,
  divisionHasUser: [],
  divisionDict: {},
  allDivisionList: [],
  divisionDetail: {},
  device: 0,
  deviceDict: {},
  userProfile: {},
};

type Handler = (
  controller: WasmController<State, Event, Data>,
  params?: any
) => any;

export const First: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AppInitializeSequence) return;
  const apiToken = controller.apiToken;
  // Go to Login if no apitoken
  if (typeof apiToken === "undefined" || apiToken === null || apiToken === "") {
    console.log("not logged in");
    return controller.setState({
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "Login",
        username: "",
        password: "",
      },
    });
  }
  // User already login on load, go straight to Greeting
  controller.setState(
    {
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "GetUserInfo",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: params })
  );
  return;
};

export const Login: Handler = async (controller, params) => {
  const state = controller.getState();
  controller.setState({
    loadingLogin: true,
  });
  if (!state.AppInitializeSequence) return;
  if (params?.action === "LoginSubmit") {
    console.log("called getFingerPrintAndSubscription");
    let deviceId = ""
    let userSubscription = null;
    if (config.BYPASS_SERVICE_WORKER) {
      // ไม่มี Service worker
      const options = {};
      let components = await Fingerprint2.getPromise(options);
      let values = components.map((component: any) => component.value);
      deviceId = Fingerprint2.x64hash128(values.join(""), 31);;
      console.log("bypass sw.js");
    } else {
      console.log(" getFingerPrintAndSubscription ");
      let FPAndUS = await getFingerPrintAndSubscription();
      console.log(" getFingerPrintAndSubscription FPAndUS: ", FPAndUS);
      deviceId = FPAndUS[0];
      userSubscription = FPAndUS[1];
      console.log(" sw.js");
    }
    console.log("called getFingerPrintAndSubscription Done");
    const login = await RequestToken.post({
      data: {
        username: state.AppInitializeSequence.username,
        password: state.AppInitializeSequence.password,
        device_id: deviceId,
        device_type: "webpush",
        device_name: `${getBrowserName()} on ${getOSName()}`,
        device_token: userSubscription ? JSON.stringify(userSubscription) : "",
        application: config.APP_NAME,
      },
      apiToken: controller.apiToken,
    });
    // if (login[1]) return console.log(login[1]);
    if (login) {
      console.log("login", login);
      controller.setState({
        loadingLogin: false,
      });

      // * Response: Error หรือ login แล้วไม่ได้ token
      const isError = login[1] || !login[0]?.token
      console.log('isError: ', isError);

      if (isError) {
        console.log(login[1]);
        controller.setState({
          errorMessage: (login[2]?.response?.status >= 500) ? {server: "Internal Server 500"} : login[1] || { request_token_errors: ["CORS errors"] },
        });
        return;
      }
    }

    // Set token in cookie and controller for future use
    Cookies.set("apiToken", login[0]?.token);
    controller.apiToken = login[0].token;

    Cookies.set("user_id", login[0].user_id.toString());
    controller.data.user = login[0].user_id;

    // Successful login, go to GetUserInfo
    controller.setState(
      {
        AppInitializeSequence: {
          ...state.AppInitializeSequence,
          sequenceIndex: "GetUserInfo",
        },
      },
      () => controller.handleEvent({ message: "RunSequence", params: params })
    );

    params.onSuccess?.();
  }
};

export const GetUserInfo: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AppInitializeSequence) return;

  const [
    divisions,
    preFlight,
    provider,
    providerEmployee,
    roleList,
    screenRes,
    divisionHasUser,
    userPosition,
    myPermission,
    userEmployee,
    userDetail,
    currentDoctor,
    divisionHasUserForOther,
  ] = await Promise.all([
    DivisionList.list({
      params: {},
      apiToken: controller.apiToken,
    }),
    preflightM.get({
      apiToken: controller.apiToken,
    }),
    GetProviderInfo.get({
      user_id: controller.data.user,
      params: { type: "doctor" },
      apiToken: controller.apiToken,
    }),
    GetProviderInfo.get({
      user_id: controller.data.user,
      apiToken: controller.apiToken,
    }),
    RoleList.list({
      params: {},
      apiToken: controller.apiToken,
    }),
    ScreenListView.list({
      apiToken: controller.apiToken,
      params: { limit: 9999 },
    }),
    DivisionHasUserList.list({
      params: {},
      apiToken: controller.apiToken,
    }),
    MyPositionDetail.retrieve({
      apiToken: controller.apiToken,
    }),
    MyPermissionView.list({
      apiToken: controller.apiToken,
    }),
    UserEmployeeDetailAPIView.retrieve({
      apiToken: controller.apiToken,
      pk: controller.data.user,
      extra: { division: controller.data.division },
    }),
    UserDetailAPIView.retrieve({
      apiToken: controller.apiToken,
      pk: controller.data.user,
      extra: { division: controller.data.division },
    }),
    GetCurrentDoctorView.get({
      apiToken: controller.apiToken,
    }),
    DivisionHasUserListForOtherUser.get({
      pk: Cookies.get("user_id"),
      apiToken: controller.apiToken,
      extra: {
        division: controller.data.division,
      },
    }),
  ]);

  const divisionHasUserForOtherList = divisionHasUserForOther?.[0]?.items?.map(
    (item: any) => item.division.id
  );
  const divisionList = groupDivisionFamily(null, divisions[0]?.items || []);
  const filterDivision = divisionList?.filter((item: any) =>
    divisionHasUserForOtherList?.includes(item.id)
  );

  const userProfile = { ...userEmployee[0], ...userDetail[0], };

  // Get provider and roleList ====================================================

  // นำ Screen Item inject กลับไปที่ config
  config.SCREEN_ITEM = screenRes?.[0]?.items;

  // Handle division-related data and state ========================================

  const divisionHasUserItems: any[] = divisionHasUser[0]?.items || [];

  const divisionItems = divisionHasUserItems.reduce((acc: any, item: any) => {
    if (!acc.map((a: any) => a.id).includes(item.division.id))
      acc.push({ ...item.division, useRights: JSON.parse(item.extra).useRights || [] });
    return acc;
  }, []);

  const divisionOptions = divisionItems
    .map((item: any) => ({
      ...item.division,
      text: item.name,
      value: item.id,
    }))
    .concat(
      config.CREATE_OFFICIAL_ACCOUNT
        ? {
            id: "create_oa",
            code: "create_oa",
            name: "+ สร้าง Official Account",
            text: "+ สร้าง Official Account",
            value: "create_oa",
          }
        : []
    );

  const divisionDict = Object.fromEntries(divisionItems.map((item: any) => [item.id, item]));
  console.log("divisionDict: ", divisionDict);

  // Set selectedDivision to in controller.data (from cookies) or first in the list
  const selectedDivision =
    (controller.data.division &&
      controller.data.division !== 0 &&
      divisionDict[controller.data.division]) ||
    divisionItems[0] ||
    null;

  const divisionType = divisionDict?.[selectedDivision?.id || 0]?.type_label || null;
  console.log("ประเภทหน่วยงาน:", divisionType || "ไม่ทราบ");

  const [divisionDetail, deviceList] = await Promise.all([
    DivisionDetail.retrieve({
      pk: selectedDivision?.id,
      apiToken: controller.apiToken,
    }),
    DeviceList.list({
      params: { division: selectedDivision?.id || 0 },
      apiToken: controller.apiToken,
    }),
  ]);

  const deviceItems: any[] = deviceList[0]?.items || []
  // console.log(deviceList[1] ? deviceList[1] : deviceList[0]);
  const deviceOptions = deviceItems.map((item: any) => ({
    key: item.id,
    value: item.id,
    text: item.computer_name,
  }));

  const deviceDict = Object.fromEntries(deviceItems.map((item: any) => [item.id, item]));

  // Set selectedDevice to in controller.data (from cookies) or first in the list
  const selectedDevice =
    (controller.data.device &&
      controller.data.device !== 0 &&
      deviceDict[controller.data.device]) ||
    deviceItems[0] ||
    null;

  // Set data  and state ============================================================
  controller.data = {
    ...controller.data,
    provider: provider?.[0]?.id || null,
    division: selectedDivision?.id || 0,
    divisionHasUser: divisionHasUser[0]?.items || [],
    divisionDict: divisionDict,
    allDivisionList: divisions?.[0]?.items || [],
    divisionDetail: !divisionDetail[1] ? divisionDetail[0] : {},
    device: selectedDevice?.id || 0,
    deviceDict: deviceDict,
    userProfile,
  };

  Cookies.set("division_id", controller.data.division!.toString());
  Cookies.set("device_id", controller.data.device!.toString());

  console.log("GetUserInfo CU divisionList: filterDivision", filterDivision);
  console.log("GetUserInfo HISV3 (NOT USE) divisionList: divisionItems", divisionItems);

  console.log(
    "GetUserInfo CU selectedDivision: selectedDivision",
    filterDivision?.find((div: any) => div.id === controller.data.division)
  );
  console.log("GetUserInfo HISV3 (NOT USE) selectedDivision: selectedDivision", selectedDivision);

  controller.setState({
    django: {
      ...preFlight[0],
      division: selectedDivision?.id || 0,
    },
    isDoctor: preFlight?.user?.role_types?.includes(CONSTANT.USER_ROLE_TYPE.DOCTOR) ? true : false,
    isNurse: preFlight?.user?.role_types?.includes(CONSTANT.USER_ROLE_TYPE.REGISTERED_NURSE)
      ? true
      : false,
    providerInfo: {
      ...provider[0],
      provider_id: provider[0]?.id,
      first_name: provider[0]?.doctor_info?.first_name,
      last_name: provider[0]?.doctor_info?.last_name,
    },
    providerEmployeeInfo: providerEmployee[0],
    userRoleList: roleList[0] ? roleList[0]?.items || [] : [],
    divisionType: divisionType,
    selectedTabIndex: getSideMenuIndex(
      params.defaultSideMenu ? { tab: params.defaultSideMenu } : { divisionType }
    ),
    divisionList: filterDivision, // divisionItems,
    divisionOptions: divisionOptions,
    divisionHasUserItems: divisionHasUserItems,
    selectedDivision: selectedDivision,
    deviceOptions: deviceOptions,
    selectedDevice, // selectedDevice,
    loggedin: true,
    currentDoctor: currentDoctor?.[0]?.doctor,
    userPosition: userPosition?.[0],
    myPermission: myPermission?.[0],
    userId: preFlight[0]?.user?.id?.toString(),
  });

  // Login Firebase --------------------------------------------------------

  controller.setState(
    {
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "LoginFirebase",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: params })
  );
};

export const LoginFirebaseWithCustomToken: Handler = (controller, params) => {
  console.log("LoginFirebaseWithCustomToken : signInWithCustomToken ");
  let retryWithFastAPI = false
  // FUNCTION
  if (!config.OFFLINE) {
    controller.functions
      .httpsCallable("createTokenFromUser")(params?.userId?.toString())
      .then((result: any) => {
        controller.app
          .auth()
          .signInWithCustomToken(result.data)
          .then(async (res: any) => {
            console.log(
              "LoginFirebaseWithCustomToken signInWithCustomToken res: ",
              res
            );
            console.log(
              "LoginFirebaseWithCustomToken signInWithCustomToken res.user.getIdToken();: ",
              res.user.getIdToken()
            );
          })
          .catch(function (error: any) {
            LoginFirebaseWithFastAPI(controller, {})
            console.log(error);
          });
      })
      .catch((error: any) => {
        LoginFirebaseWithFastAPI(controller, {})
        console.log(error);
      });
  }
};

export const LoginFirebaseWithFastAPI: Handler = async (controller, params) => {
  const FAST_API = `${config.FAST_API}`;
  axios.post(
    `${FAST_API}/api/login/get-firebase-token`, {},
    { headers: { "Authorization": `Token ${controller.apiToken}`}}
  ).then((result: any) => {
    console.log('result: ', result);
      controller.app
        .auth()
        .signInWithCustomToken(result.data.token)
        .then(async (res: any) => {
          console.log('LoginFirebaseWithCustomToken signInWithCustomToken res: ', res);
          console.log('LoginFirebaseWithCustomToken signInWithCustomToken res.user.getIdToken();: ', res.user.getIdToken());
          // Test login with firebase id token
          // const firebase_id_token = await res.user.getIdToken();
          // axios.post(
          //   `${FAST_API}/api/login/get-firebase-user`, {},
          //   { headers: { "Authorization": `Token ${firebase_id_token}`}}
          // ).then((res: any) => {
          //     console.log("uid", res.data);
          // })
          // .catch((err: any) => {
          //   console.log(err);
          // });
        })
        .catch(function (error: any) {
            console.error("LoginFirebaseWithCustomToken error: ", error)
            console.log(error);
        });
    })
    .catch((error: any) => {
        console.log(error);
    });
}

export const LoginFirebase: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("LoginFirebase check controller: ", controller);

  const onFirebaseAuthStateChanged = async (user: any) => {
    if (!user) {
      console.log("User is signed out");
      const apiToken = controller.apiToken;
      const userId = controller.data.user;
      if (apiToken && userId) {
        LoginFirebaseWithCustomToken(controller, { userId: userId });
      }
    } else {
      console.log("User is signed in, ", user.uid);
    }
  };

  // LoginFirebaseWithCustomToken(controller, controller.data.user );
  if (!config.OFFLINE) {
    controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
  }

  // Finishing up --------------------------------------------------------
  controller.setState(
    {
      AppInitializeSequence: {
        ...state.AppInitializeSequence,
        sequenceIndex: "Finish",
      },
    },
    () => controller.handleEvent({ message: "RunSequence", params: params })
  );
};

export const Finish: Handler = async (controller, params) => {
  const state = controller.getState();
  if (!state.AppInitializeSequence) return;

  // Finish the sequence
  controller.setState({
    AppInitializeSequence: null,
  });
};

const getBrowserName = () => {
  const nAgt = navigator.userAgent;

  // In Opera, the true version is after "Opera" or after "Version"
  if (nAgt.indexOf("Opera") !== -1) {
    return "Opera";
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if (nAgt.indexOf("MSIE") !== -1) {
    return "Microsoft Internet Explorer";
  }
  // In Chrome, the true version is after "Chrome"
  else if (nAgt.indexOf("Chrome") !== -1) {
    return "Chrome";
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if (nAgt.indexOf("Safari") !== -1) {
    return "Safari";
  }
  // In Firefox, the true version is after "Firefox"
  else if (nAgt.indexOf("Firefox") !== -1) {
    return "Firefox";
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else {
    return navigator.appName;
  }
};

const getOSName = () => {
  const nAgt = navigator.userAgent;
  let os = "Unknown OS";
  const clientStrings = [
    { s: "Windows 3.11", r: /Win16/ },
    { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
    { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
    { s: "Windows 98", r: /(Windows 98|Win98)/ },
    { s: "Windows CE", r: /Windows CE/ },
    { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
    { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
    { s: "Windows Server 2003", r: /Windows NT 5.2/ },
    { s: "Windows Vista", r: /Windows NT 6.0/ },
    { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
    { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
    { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
    { s: "Windows NT 4.0", r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
    { s: "Windows ME", r: /Windows ME/ },
    { s: "Android", r: /Android/ },
    { s: "Open BSD", r: /OpenBSD/ },
    { s: "Sun OS", r: /SunOS/ },
    { s: "Linux", r: /(Linux|X11)/ },
    { s: "iOS", r: /(iPhone|iPad|iPod)/ },
    { s: "Mac OS X", r: /Mac OS X/ },
    { s: "Mac OS", r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
    { s: "QNX", r: /QNX/ },
    { s: "UNIX", r: /UNIX/ },
    { s: "BeOS", r: /BeOS/ },
    { s: "OS/2", r: /OS\/2/ },
    {
      s: "Search Bot",
      r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/,
    },
  ];
  for (let id in clientStrings) {
    let cs = clientStrings[id];
    if (cs.r.test(nAgt)) {
      os = cs.s;
      break;
    }
  }
  return os;
};

const getFingerPrintAndSubscription = async () => {
  // let registrations = null;
  // console.log('config.BYPASS_NOTIFICATION_SUBSCRIPTION: ', config.BYPASS_NOTIFICATION_SUBSCRIPTION);
  // if (!(config.BYPASS_NOTIFICATION_SUBSCRIPTION)) {
  //   try {
  //     registrations = await navigator.serviceWorker.getRegistrations();
  //     console.log("check service worker", registrations);
  //     if (registrations.length <= 0) {
  //       console.log("Force register service worker again !!!");
  //       serviceWorker.register();
  //       registerServiceWorker();
  //     }
  //   } catch (e) {
  //     console.log('Catch (e): ', e);
  //     if (navigator.serviceWorker) {
  //       console.log("Force register service worker again !!!");
  //       serviceWorker.register();
  //       registerServiceWorker();
  //     } else {
  //       console.log("Not have ", navigator.serviceWorker);
  //     }
  //   }
  // }
  const options = {};
  let components = await Fingerprint2.getPromise(options);
  let values = components.map((component: any) => component.value);
  let device_id = Fingerprint2.x64hash128(values.join(""), 31);
  console.log("getFingerPrintAndSubscription LCB Fingerprint id:", device_id);
  // Get subscription endpoint object
  let subscription;
  try {
    console.log("getFingerPrintAndSubscription call getUserSubscription")
    subscription = await getUserSubscription();
    console.log('getFingerPrintAndSubscription return subscription: ', subscription);
    if (!subscription) {
      subscription = await createNotificationSubscription();
      console.log("LCB Success get notification subscription.");
      console.log(subscription);
    }
  } catch (err) {
    console.log("LCB error getSubScriptionObejct");
    console.error(
      "Couldn't create the notification subscription",
      err,
      "name:",
      err.name,
      "message:",
      err.message,
      "code:",
      err.code
    );
  }
  return [device_id, subscription];
};



const groupDivisionFamily: (parent: number | null, items: any[]) => any[] =
(parent, items) => {
  if(!parent){
    return items.map((item: any) => ({
      ...item,
      children: groupDivisionFamily(item.id, items)
    }))
  }
  return items.filter((item: any) => item.parent === parent)
              .map((item: any) => ({
                ...item,
                children: groupDivisionFamily(item.id, items)
              }))
}

