import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  CSSProperties,
} from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

import { useLocation, useHistory } from "react-router";

// UX
import BottomSheetSelectBox from "./BottomSheetSelectBox";
import BottomSheetFilter from "./BottomSheetFilter";
import InfiniteScroll from "./InfiniteScroll";
import CardDoctorList from "./CardDoctorList";
import { SDLocationType, formattedDoctor } from "./SearchDoctor";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";
import { DoctorAppointmentActionType, FilterDoctorType } from "./Types";

// Const.
import { DOCTOR_SORT_OPTIONS, URLS } from "./Constants";

// Types
type FilterSearchDoctorProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  centerGroupItems: any[];
} & Pick<
  State,
  | "hospitalList"
  | "textSearch"
  | "availableDoctorSearch"
  | "loadingStatus"
  | "allAvailableDoctorList"
  | "centerGroupList"
  | "appointmentCenterList"
>;

type FDLocationType = SDLocationType & {
  storedState?: { filter?: Record<string, any>; orderBy?: Record<string, any> };
};

// Images
const IMAGES = {
  filter: "/images/Appointment/filter-icon.png",
  doctor: "/images/Feed/doctor.png",
  sort: "/images/Appointment/sort-icon.png",
  sort_blue: "/images/Appointment/sort-icon-blue.png",
};

// Styles
const COLORS = {
  divider: "rgba(245, 245, 245, 1)",
  chiffon_grey: "rgba(228, 228, 228, 1)",
  primary: "var(--blue-bdms-color)",
};

const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  ellipsis: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "inline-block",
  } as CSSProperties,
};

const boxFilterStyles = {
  borderBottom: `1px solid ${COLORS.divider}`,
  display: "flex",
  padding: "16px 0",
  "& > div:nth-child(1)": {
    borderRight: `1px solid ${COLORS.chiffon_grey}`,
  },
  "& > div": {
    width: "50%",
    ...styles.center,
    "& img": {
      width: "16px",
      height: "16px",
      marginLeft: "0.75rem",
    },
  },
  "& .filtered": {
    color: COLORS.primary,
    "& .badge": {
      backgroundColor: COLORS.primary,
      color: "white",
      marginLeft: "0.75rem",
      borderRadius: "500rem",
      width: "16px",
      height: "16px",
      fontSize: ".75rem",
      padding: "0.15rem 0.05rem 0 0",
      ...styles.center,
    },
  },
};

const useStyles = makeStyles(() => ({
  screen: {
    // padding: "16px 0",
  },
  box_filter: boxFilterStyles,
}));

const BUTTON_ACTIONS = {
  filter: "_FILTER",
  more: "_MORE",
};

const FILTER_SEARCH_DOCTOR = "FilterSearchDoctor";
const ACTION_FILTER = `${FILTER_SEARCH_DOCTOR}${BUTTON_ACTIONS.filter}`;
const ACTION_MORE = `${FILTER_SEARCH_DOCTOR}${BUTTON_ACTIONS.more}`;

const FilterSearchDoctor = (props: FilterSearchDoctorProps) => {
  const classes = useStyles();
  const location = useLocation<FDLocationType>();
  const history = useHistory();

  // data
  const [filter, setFilter] = useState<any>(null);
  const [orderBy, setOrderBy] = useState<any>(null);
  // open
  const [openModSort, setOpenModSort] = useState<boolean>(false);
  const [openModFilter, setOpenModFilter] = useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Memo Effect
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  // Callback Effect
  const handleGetListAvailableDoctor = useCallback(
    (
      data: {
        card?: string;
        concat?: boolean;
        refresh?: boolean;
      } & FilterDoctorType
    ) => {
      props.onEvent({
        message: "GetListAvailableDoctor",
        params: {
          ...data,
          page: data.page || 1,
          name: props.textSearch,
          defaultCenter: locState.center?.id,
          card: data.card,
          status: data.card === ACTION_FILTER,
        },
      });
    },
    [props.textSearch]
  );

  // Effect
  useEffect(() => {
    const refresh =
      !props.availableDoctorSearch?.items.length ||
      !locState.storedState?.filterMode;

    if (refresh) {
      setIsLoading(true);

      handleGetListAvailableDoctor({
        page: 1,
        refresh: true,
        card: ACTION_FILTER,
      });
    } else if (props.textSearch || locState.center) {
      const storedState = locState?.storedState || {};

      setIsLoading(false);

      setFilter(storedState.filter || null);
      setOrderBy(storedState.orderBy || null);
    }
  }, [props.textSearch]);

  useEffect(() => {
    if (props.loadingStatus?.[ACTION_FILTER] === "SUCCESS") {
      const { [ACTION_FILTER]: f, ...loading } = props.loadingStatus || {};

      props.setProp(`loadingStatus`, { ...loading });

      setIsLoading(false);
    }
  }, [props.loadingStatus]);

  // Memo
  const doctorList = useMemo(() => {
    return props.availableDoctorSearch?.items || [];
  }, [props.availableDoctorSearch]);

  const numberOfFilters = useMemo(() => {
    const clonedFilter = structuredClone(filter || {});

    if (clonedFilter && !clonedFilter?.hospitals?.length) {
      delete clonedFilter.hospitals;
    }

    delete clonedFilter.order;

    return Object.keys(clonedFilter).length;
  }, [filter]);

  const hospitalItems = useMemo(() => {
    const key = "hospital_code";
    const arrayUniqueByKey = Array.from(
      new Map(
        (props.allAvailableDoctorList?.items || []).map((item) => [
          item[key],
          item,
        ])
      ).values()
    );

    return arrayUniqueByKey.map((item) => ({
      code: item.hospital_code,
      name: item.hospital_name,
    }));
  }, [props.allAvailableDoctorList]);

  // Handler
  const handleOpenModSort = () => {
    setOpenModSort(true);
  };

  const handleOpenModFilter = () => {
    setOpenModFilter(true);
  };

  const handleNextPage = (page: number) => {
    handleGetListAvailableDoctor({
      page: page,
      card: ACTION_MORE,
      concat: true,
    });
  };

  const handleConfirmFilterNOrder = (data: any) => {
    setOpenModFilter(false);

    setFilter(data);

    handleGetListAvailableDoctor({
      page: 1,
      card: ACTION_FILTER,
      gender: data.gender,
      ...(data.appointmentType && {
        telemed: data.appointmentType === "online",
        onsite: data.appointmentType === "on-site",
      }),
      score: data.score,
      centergroup: data.centerGroup,
      center: data.center,
      hospitals: data.hospitals,
      order: data.order,
    });
  };

  const handleSelectDoctor = (selected: Record<string, any>) => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        pathname: URLS.DOCTOR_PROFILE,
        history,
        state: {
          doctor: formattedDoctor(props.centerGroupItems, selected),
        },
        storedState: {
          filterMode: true,
          textSearch: props.textSearch,
          filter,
          orderBy,
        },
      },
    });
  };

  // Handler
  const handleAppointmentAction = (
    data: Record<string, any>,
    action: DoctorAppointmentActionType,
    loading: string
  ) => {
    props.onEvent({
      message: "HandleDoctorAppointmentAction",
      params: {
        history,
        action,
        doctor: formattedDoctor(props.centerGroupItems, data),
        card: loading,
        storedState: {
          filterMode: true,
          textSearch: props.textSearch,
          filter,
          orderBy,
        },
      },
    });
  };

  const handleConfirmOrderBy = (data: any) => {
    setOrderBy(data);
    setOpenModSort(false);

    handleConfirmFilterNOrder({ ...(filter || {}), order: data?.order });
  };

  return (
    <div className={classes.screen}>
      <div className={classes.box_filter}>
        <div
          aria-hidden="true"
          className={numberOfFilters ? "filtered" : ""}
          onClick={handleOpenModFilter}
        >
          <label>ตัวกรอง</label>
          {numberOfFilters ? (
            <div className="badge">{numberOfFilters}</div>
          ) : (
            <img src={IMAGES.filter} />
          )}
        </div>
        <div aria-hidden="true" onClick={handleOpenModSort}>
          {orderBy ? (
            <>
              <label
                style={{
                  ...styles.ellipsis,
                  color: COLORS.primary,
                  maxWidth: "65%",
                }}
              >
                {orderBy.name}
              </label>
              <img src={IMAGES.sort_blue} />
            </>
          ) : (
            <>
              <label>เรียงลำดับตาม</label>
              <img src={IMAGES.sort} />
            </>
          )}
        </div>
      </div>

      <InfiniteScroll
        totalLength={props.availableDoctorSearch?.total || 0}
        visibleLength={doctorList.length}
        currentPage={props.availableDoctorSearch?.page || 0}
        loadMore={props.loadingStatus?.[ACTION_MORE]}
        endMessage={"สิ้นสุดรายการ"}
        // style
        heightStyle="calc(100vh - 11.5rem)"
        // callback
        onNext={handleNextPage}
      >
        <CardDoctorList
          // data
          items={doctorList}
          loading={isLoading}
          // CommonInterface
          loadingStatus={props.loadingStatus}
          // callback
          onSelect={handleSelectDoctor}
          onButtonAction={handleAppointmentAction}
        />
      </InfiniteScroll>

      <BottomSheetSelectBox
        open={openModSort}
        title="เรียงลำดับ"
        options={DOCTOR_SORT_OPTIONS}
        selected={orderBy}
        // config
        clearOnChecked={true}
        // callback
        onChange={setOpenModSort}
        onSelect={handleConfirmOrderBy}
      />

      <BottomSheetFilter
        open={openModFilter}
        hospitalList={hospitalItems}
        centerGroupList={props.centerGroupItems}
        // config
        hideHospital={!!location.state?.hospital}
        hideCenterGroup={!!locState.center?.id}
        // callback
        onChange={setOpenModFilter}
        onConfirm={handleConfirmFilterNOrder}
      />
    </div>
  );
};

export default React.memo(FilterSearchDoctor);
