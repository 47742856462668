import { WasmHandler } from "react-lib/frameworks/WasmController";

import moment from "moment";
import axios from "axios";
import CONFIG from "config/config";
import firebaseConfig from "../../_manager/firebase-config";

// APIS

// Interface
import { GetClinicalSegment } from "../manage/ClinicContentInterface";
import { State as MainState } from "../ManageInterface";
import { SEGMENT_TYPE, commonListPatientSegmentList } from "../ManageInterface";
import {
  HandleSetMarketingIndex,
  EngagementData,
  CommonData,
  GetMarketing,
  CreateEngagement,
} from "./PostExInterface";

export type PromotionData = {
  image: string | null;
  minprice: string | null;
  maxprice: string | null;
  available_start_date: any;
  available_end_date: any;
  editor: string | null;
  edit_date: any;
  extra_field?: {
    banners_file?: File;
    banners?: string | null;
    image_file?: File;
    image?: string | null;
  };
  errorField?: {
    description? : boolean,
    title? : boolean,
    banners? : boolean,
  }
} & CommonData;

export type State = Partial<{
  marketingPromotionList: PromotionData[];
  marketingPromotionIndex: number;
  marketingPromotionId: string | null;
  marketingPromotionChart: EngagementData;
  loadingMainPromotion: boolean;
  userProfile: any;
}>;

type Picked = Pick<
  MainState,
  | "selectedDivisionId"
  | "errorMessage"
  | "successMessage"
  | "divisionHasUser"
  | "selectedDivision"
  // | "organizationIndexList"
>;

type FileKey = "banners_file" | "image_file";

export const StateInitial: Required<State> = {
  marketingPromotionList: [],
  marketingPromotionIndex: 0,
  marketingPromotionId: null,
  loadingMainPromotion: false,
  marketingPromotionChart: {} as any,
  userProfile: null,
};

const PROMOTION_DIVISION = "PromotionDivision";
const GS_BUCKET_NAME = `gs://${CONFIG.PROMOTION_STORAGE_NAME}`;
const PROMOTION_ENGAGEMENT = "PromotionEngagement";

export type Event =
  // GET
  | { message: "GetListMarketingPromotion"; params: {} }
  // | { message: "HandleGetHospitalOptions"; params: { search: string } }
  | { message: "GetMarketingSegmentByResult"; params: { result: string } }
  | { message: "HandleGetPromotionChart"; params: { id: string | null } }
  // SAVE
  | {
      message: "SaveMarketingPromotion";
      params: { id: string | null; callback?: () => any };
    }
  // SET
  | { message: "HandleSetMarketingPromotionIndex"; params: { index: number } }
  | {
      message: "HandleChangeMarketingPromotion";
      params: { data: Partial<PromotionData> };
    }
  | {
      message: "HandleAddNewMarketingPromotion";
      params: {};
    }
  | {
      message: "HandleSearchMarketingPromotion";
      params: { search: string };
    };

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State & Picked, Event, Data>;

/* ------------------------- GET ------------------------ */
const GetMarketingPromotion: Handler = async (controller, params) => {
  const [res, error] = await GetMarketing(controller as any, {
    collection: PROMOTION_DIVISION,
  });

  return [
    res?.map((item: PromotionData) => ({
      ...item,
      available_start_date: item.available_start_date
        ? item.available_start_date.toDate()
        : null,
      available_end_date: item.available_end_date
        ? item.available_end_date.toDate()
        : null,
      edit_date: item.edit_date
        ? item.edit_date.toDate()
        : item.created
        ? item.created.toDate()
        : null,
    })),
    error,
  ];
};

export const GetListMarketingPromotion: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingMainPromotion: true });

  controller.handleEvent({ message: "handleGetMarketingSegmentList" } as any);

  const [res, error] = await GetMarketingPromotion(controller, {});

  const list = res?.filter(
    (item: any) => item.division_id === params.division && !item.delete
  );

  if (res) {
    controller.setState({
      marketingPromotionList: list,
      marketingPromotionIndex: 0,
      marketingPromotionId: list[0]?.id || null,
    });
  } else {
    console.log(error);
  }

  controller.setState({ loadingMainPromotion: false });
};

export const HandleGetPromotionChart: Handler = async (controller, params) => {
  try {

    let firebase_project = CONFIG.FIREBASE_PROJECT;
    if (firebase_project === "") {
      console.error("Not config FIREBASE_PROJECT in base.json")
    }
    let config = firebaseConfig.find((item:any) => item.projectId === firebase_project)
  
    let result: any = await controller.storage
    .refFromURL(`${config?.gsBucketName}`)
    // .child('/ContentEngagement/promotion_engagement_1NScHJpgghLzbpoeW5eI.json')
    .child(`/feed/dbjson/promotion_engagement_${params.id}.json`)
    .getDownloadURL()
    .then( url => {
      return axios.get(url)
    })

    // const response = await controller.db
    //   .collection(PROMOTION_ENGAGEMENT)
    //   .where("id", "==", params.id)
    //   .get();


    controller.setState({
      marketingPromotionChart: result.data || {},
    });
  } catch (error) {
    console.log("Error getting post content from firebase");
    controller.setState({
      marketingPromotionChart: {},
    });
  }
};

// export const HandleGetHospitalOptions: Handler = async (controller, params) => {
//   const { divisionHasUser, selectedDivisionId, organizationIndexList } =
//     controller.getState();
//   const data =
//     (divisionHasUser || []).find(
//       (item: any) => item.division_id === selectedDivisionId
//     ) || null;
//   const organizationList = organizationIndexList || [];
//   const find = organizationIndexList?.find(
//     (item) => item.id === data.organization?.id
//   );

//   if (!find) {
//     organizationList.push({
//       id: data.organization.id,
//       name: data.organization.name,
//     });
//   }

//   const filter = organizationList.filter((item) =>
//     item.name?.toLowerCase().includes(params.search.toLowerCase())
//   );

//   return [{ items: filter }, null];
// };

/* ------------------------ SAVE ------------------------ */
export const SaveMarketingPromotion: Handler = async (controller, params) => {
  const {
    marketingPromotionList,
    marketingPromotionIndex,
    selectedDivisionId,
    divisionHasUser,
    selectedDivision,
    userProfile,
  } = controller.getState();
  
  // console.log(' !! SaveMarketingPromotion marketingPromotionList: ', marketingPromotionList);
  // console.log(' !! SaveMarketingPromotion marketingPromotionIndex: ', marketingPromotionIndex);
  // console.log(' !! SaveMarketingPromotion divisionHasUser: ', divisionHasUser);
  // console.log(' !! SaveMarketingPromotion selectedDivision: ', selectedDivision);
  // console.log(' !! SaveMarketingPromotion userProfile: ', userProfile);

  let promotionList = [...(marketingPromotionList || [])];
  const promotionData =
    promotionList.find((item) => item.id === params.id) ||
    ({} as PromotionData);
  const { id, ...data } = promotionData;

  delete data.extra_field;

  const filesKey = (["banners_file", "image_file"] as FileKey[]).filter(
    (key) => promotionData.extra_field?.[key]
    );
    
  // console.log('SaveMarketingPromotion filesKey: ', filesKey);
  let today = moment().toDate();

  data.edit_date = today;
  data.editor = `${userProfile.first_name || ""} ${
    userProfile.last_name || ""
  }`;
  
  // console.log('SaveMarketingPromotion data: ', data);
  const promiseArr = filesKey.map((fileKey) => {
    const key = fileKey.replace(/_file/g, "") as "banners" | "image";
    const params: any = {
      file: promotionData.extra_field?.[fileKey],
      bucketName: GS_BUCKET_NAME,
      key,
    };
    
    if (data[key]) {
      deleteFile(controller, { url: data[key], bucketName: GS_BUCKET_NAME });
    }
    
    return uploadFile(controller, params);
  });
  // console.log('SaveMarketingPromotion promiseArr: ', promiseArr);

  if (promiseArr?.[0]) {
    const response = await Promise.all(promiseArr);
    console.log(response);

    response.forEach((item) => {
      (data as any)[item.key] = item.url;
    });
  }

  // if (data.hospital?.id) {
  //   updateOrganizationIndex(controller as any, { organization: data.hospital });
  // }

  // console.log('SaveMarketingPromotion id: ', id);
  // console.log('SaveMarketingPromotion data: ', data);
  try {
    if (id) {
      // data.updated = today;

      // Update
      await controller.db
        .collection(PROMOTION_DIVISION)
        .doc(id)
        .set(data, { merge: true });

      if (typeof marketingPromotionIndex === "number") {
        promotionList[marketingPromotionIndex] = { id, ...data };
      }
      controller.setState({ successMessage: "Update promotion success." });
    } else {
      data.divisionId = selectedDivision?.division_id || null;
      data.divisionCode = selectedDivision?.division_code;
      data.organization =
        (divisionHasUser || []).find(
          (item: any) => item.division_code === data.divisionCode
        )?.division?.organization || null;

      data.edit_date = today;
      data.created = today;

      // New
      const newId = (
        await controller.db.collection(PROMOTION_DIVISION).add(data)
      ).id;

      CreateEngagement(controller as any, {
        collection: PROMOTION_ENGAGEMENT,
        contentId: newId,
      });

      promotionList[0].id = newId;

      controller.setState({
        successMessage: "Add promotion success.",
        marketingPromotionIndex: 0,
        marketingPromotionId: newId,
      });
    }
    GetListMarketingPromotion(controller, {});
  } catch (error) {
    console.error('error: ', error);
    controller.setState({ errorMessage: error });
  }

  if (params.callback) {
    params.callback();
  }

  controller.setState({
    marketingPromotionList: promotionList,
  });
};

/* ------------------------- SET ------------------------ */
export const HandleSetMarketingPromotionIndex: Handler = (
  controller,
  params
) => {
  HandleSetMarketingIndex(controller as any, {
    listKey: "marketingPromotionList",
    indexKey: "marketingPromotionIndex",
    idKey: "marketingPromotionId",
    index: params.index,
  });
};

export const HandleChangeMarketingPromotion: Handler = (controller, params) => {
  const { marketingPromotionList, marketingPromotionIndex } =
    controller.getState();
  const cloneArray = [...(marketingPromotionList || [])];

  if (typeof marketingPromotionIndex === "number") {
    cloneArray[marketingPromotionIndex] = {
      ...cloneArray[marketingPromotionIndex],
      ...params.data,
    };
  }

  controller.setState({ marketingPromotionList: cloneArray });
};

export const HandleAddNewMarketingPromotion: Handler = (controller, params) => {
  const {
    marketingPromotionIndex,
    marketingPromotionList,
    divisionHasUser,
    selectedDivisionId,
  } = controller.getState();

  const firstList = marketingPromotionList?.[marketingPromotionIndex || 0];

  if (firstList?.id === null) {
    return;
  }

  const division =
    (divisionHasUser || []).find(
      (item: any) => item.division_id === selectedDivisionId
    ) || {};

  const list: PromotionData[] = [
    {
      id: null,
      active: true,
      delete: false,
      description: "",
      title: "",
      banners: null,
      image: null,
      minprice: null,
      maxprice: null,
      available_start_date: null,
      available_end_date: null,
      tags: "",
      segment: [],
      hospital: {
        code: division.organization.code,
        name: division.organization.name,
        name_en: division.organization.name_en,
      },
      clinic: {
        code: division.division_code,
        name: division.division_name,
        name_en: division.division_name_en,
      },
      divisionId: null,
      divisionCode: null,
      manage_segment: [],
      organization: null,
      editor: null,
      edit_date: null,
    },
    ...(marketingPromotionList || []),
  ];

  controller.setState({
    marketingPromotionList: list,
    marketingPromotionIndex: 0,
    marketingPromotionId: null,
  });
};

export const GetMarketingSegmentByResult: Handler = async (
  controller,
  params
) => {
  const [error, response] = await commonListPatientSegmentList(
    controller as any,
    {
      result_contain: params.result,
      segment_type: SEGMENT_TYPE.MARKETING,
      have_tk_detail: "false",
    }
  );

  return [{ result: params.result, ...response }, error];
};

export const HandleSearchMarketingPromotion: Handler = (
  controller,
  params
) => {};

// Utils
export const uploadFile: Handler = async (
  controller,
  params: {
    file: File;
    fileName?: string;
    bucketName: `gs://${string}`;
    key?: string;
  }
): Promise<{ key: string; url: string }> => {
  let fileName = `${uuidv4()}${params.file.name.match(/.\w+$/g)?.[0]}`;

  const path = `/images/${fileName}`;

  const uploadTask = controller.storage
    .refFromURL(params.bucketName)
    .child(path)
    .put(params.file);

  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        controller.storage
          .refFromURL(params.bucketName)
          .child(path)
          .getDownloadURL()
          .then((url) => {
            resolve({
              key: params.key || "",
              url,
            });
          });
      }
    );
  });
};

export const deleteFile: Handler = async (
  controller,
  params: { url: string; bucketName: `gs://${string}` }
) => {
  const {
    groups: { name },
  }: any = /%2F(?<name>[\w-.]+)\?alt=/g.exec(params.url) || {
    groups: {},
  };

  if (name) {
    try {
      await controller.storage
        .refFromURL(params.bucketName)
        .child(`images/${name}`)
        .delete();
    } catch (error) {}
  }
};

const uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const HOSPITAL = [
  { id: 1, name: "Bangkok BHQ 1" },
  { id: 2, name: "Bangkok BHQ 1" },
  { id: 3, name: "โรงพยาบาลอื่นๆ" },
];
