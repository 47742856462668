import { WasmHandler } from 'react-lib/frameworks/WasmController'
import axios from 'axios'

export type State = 
        {
            locationList?: any[]
        }

export const StateInitial = 
        {
            locationList: [],
        }

export type Event = 
        { message: "GetLocation", params: {} }

export type Data = {}

export const DataInitial = {}

type Handler = WasmHandler<State, Event>

export const GetLocation: Handler = (controller, params) => {
    controller.db.collection("Location")
        .get()
        .then(res => {
            console.log(res.docs.map(doc => doc.data()))
            controller.setState({
                locationList: res.docs.map(doc => doc.data())
            })
        })
}
