import React, { useEffect } from "react";
import { Form, Icon, Input } from "semantic-ui-react";
import PropTypes from "prop-types";

type EmployeeTokenProps = {
  disabled: boolean;
  tabIndex: number,
  employeeCode: string,
  employeeName: string,
  onEnterToken: (x: string) => void,
  onClearToken: () => void,
  onChangeCodeText: (x: string) => void,
  placeholder: string,
  fluid: boolean,
  inputStyle: any,
  notWidth100: boolean
}

const EmployeeToken = (props: EmployeeTokenProps) => {
  const [employeeCode, setEmployeeCode] = React.useState("");
  const [employeeName, setEmployeeName] = React.useState("");

  useEffect(() => {
    setEmployeeCode(props.employeeCode);
  }, [props.employeeCode]);

  useEffect(() => {
    setEmployeeName(props.employeeName);
  }, [props.employeeName]);

  const changeToken = (data: any) => {
    setEmployeeCode(data.value);
  };

  const enterToken = () => {
    props.onEnterToken(employeeCode);
  };

  const clearToken = (e: any) => {
    if (props.disabled) {
      return;
    }
    props.onClearToken();
    setEmployeeCode("");
    setEmployeeName("");
    e.stopPropagation();
  };

  return (
    <Form style={{...(!props.notWidth100 && {width: "100%" })}} >
      <Form.Field error={props.error} style={{ padding: 0 }}>
        <Input
          disabled={props.disabled}
          style={{
            display: employeeName ? "none" : "",
            ...props.inputStyle,
            marginRight: 0,
          }}
          type="password"
          value={employeeCode ? employeeCode : ""}
          placeholder={props.placeholder || "ระบุ Employee code"}
          onChange={(event, data) => {
            props.onChangeCodeText(data);
            changeToken(data);
          }}
          onClick={props.onClick}
          onKeyPress={(event: any, data: any) => {
            if (props.disabled) {
              return;
            }
            if (event.key === "Enter") {
              enterToken();
            }
          }}
          loading={props.loading}
          icon={
            employeeName && employeeCode ? (
              <Icon name="delete" link onClick={clearToken} />
            ) : undefined
          }
          tabIndex={props.tabIndex}
          fluid={props.fluid}
        />
        <Input
          readOnly
          disabled={props.disabled}
          style={{ display: employeeName ? "" : "none", ...props.inputStyle }}
          value={employeeName ? employeeName : ""}
          icon={<Icon name="delete" link onClick={clearToken} />}
          f
          fluid={props.fluid}
        />
      </Form.Field>
    </Form>
  );
};

EmployeeToken.defaultProps = {
  disabled: false,
  tabIndex: 0,
  employeeCode: "",
  employeeName: "",
  onEnterToken: () => {},
  onClearToken: () => {},
  onChangeCodeText: () => {},
  error: false,
  loading: false,
  placeholder: "",
  fluid: false,
  inputStyle: {},
  notWidth100: false
};


export default React.memo(EmployeeToken);
