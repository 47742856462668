import React, { ChangeEvent, useMemo, useRef, useState } from "react";
import { Form, Icon, Input, TextArea } from "semantic-ui-react";

// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import { useLocation, useHistory } from "react-router";
import moment from "moment";

// UX
import BoxTitle from "./BoxTitle";
import { SDLocationType } from "./SelectDatetime";
// medication
import DoctorBanner from "./DoctorBanner";

// Interface
import { Event } from "bplus-lib/MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type HealthProblemProps = {
  onEvent: (e: Event) => any;
};

export type HPLocationType = SDLocationType &
  SDLocationType["storedState"] & {};

// Images
const IMAGES = {
  attach_file: "/images/Appointment/attach-file-outline.png",
  calendar: "/images/Appointment/calendar-outline.png",
  clock: "/images/Appointment/clock.png",
  fast_time: "/images/Appointment/fast-time-outline.png",
  pdf: "/images/Appointment/pdf-outline.png",
};

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  light_grey: "rgba(121, 120, 120, 1)",
  border: "rgba(196, 196, 196, 0.7)",
  shadow: "1px 8px 18px 0px rgba(0, 102, 255, 0.1)",
  primary: "var(--blue-bdms-color)",
  bg: "rgba(1, 71, 163, 0.05)",
  divider: "rgba(204, 218, 237, 1)",
  grey_04: "rgba(57, 57, 57, 0.4)",
};

const datetimeStyles = {
  position: "absolute",
  top: "-95px",
  zIndex: 1,
  width: "100%",
  left: 0,
  padding: "0 16px",
  "& > div:nth-child(1)": {
    display: "flex",
    alignItems: "center",
    marginBottom: "0.9rem",
    justifyContent: "space-between",
    "& [class*=title]": {
      marginBottom: 0,
      marginTop: "-2px",
    },
    "& label": {
      color: "white",
    },
  },
  "& .box-card": {
    padding: "16px",
    boxShadow: COLORS.shadow,
    borderRadius: "8px",
    backgroundColor: "white",
    "& .header": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: ".875rem",
      "& > div:nth-child(1)": {
        color: COLORS.grey,
      },
      "& > div:nth-child(2)": {
        color: COLORS.primary,
      },
    },
  },
} as CSSProperties;

const boxAttachFileStyles = {
  gap: "11px",
  marginTop: "1.25rem",
  display: "grid",
  gridTemplateColumns: `repeat(auto-fit, minmax(70px, 70px))`,
  "& > div,button": {
    // width: "70px",
    height: "76px",
    borderRadius: "8px",
    border: `1px solid ${COLORS.border}`,
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& img": {
      width: "100%",
      padding: "0 1rem",
    },
    "& .delete": {
      backgroundColor: COLORS.grey_04,
      borderRadius: "8px",
      position: "absolute",
      padding: "3px 3.75px 1px",
      top: "5px",
      right: "5px",
      "& i": {
        color: "white",
        margin: 0,
      },
    },
    "& label": {
      position: "absolute",
      bottom: "-11px",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: COLORS.grey_04,
      fontSize: ".875rem",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      display: "inline-block",
      width: "80%",
    },
  },
  "& .pdf img": {
    width: "55px",
    marginTop: "-0.25rem",
    padding: "0 1rem",
  },
};

const useStyles = makeStyles(() => ({
  screen: {
    borderRadius: 0,
    "& ~ div > .button-submit-bottom": {
      padding: "4rem 0 3rem",
    },
  },
  title: {
    fontWeight: "bold",
    color: COLORS.grey,
    marginBottom: "0.85rem",
    fontSize: "1.125rem",
  },
  sub_title: {
    color: COLORS.grey,
    fontSize: "1rem",
    margin: "1.25rem 0 0.75rem",
  },
  hint: {
    fontSize: ".875rem",
    color: COLORS.light_grey,
  },
  textarea: {
    borderRadius: "8px !important",
    padding: "12.5px 16px !important",
  },
  input: {
    "& input": {
      borderRadius: "8px !important",
      padding: "12.5px 16px !important",
    },
  },

  datetime: datetimeStyles,
  header: { paddingTop: "120px" },
  box_attach_file: boxAttachFileStyles,
  attach_file: {
    borderStyle: "dashed !important",
    "& img": {
      width: "23px",
      marginTop: "-0.25rem",
    },
  },
}));

const HealthProblem = (props: HealthProblemProps) => {
  const classes = useStyles();
  const location = useLocation<HPLocationType>();
  const history = useHistory();

  const [allFiles, setAllFiles] = useState<File[]>([]);

  const inputRef = useRef<any>();

  // Memo
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  const base64List = useMemo(() => {
    if (allFiles?.length) {
      return allFiles.map((file, index) => ({
        name: file.name,
        url: URL.createObjectURL(file),
        id: index + 1,
        pdf: file.type.includes("pdf"),
      }));
    } else {
      return [];
    }
  }, [allFiles]);

  const appointmentDatetime = useMemo(() => {
    const slot = locState.slot;
    const times = locState.slot?.times || [];
    const startTime = times[0]?.start_time || "";
    const endTime = times.slice(-1)[0]?.end_time || "";

    return slot?.date
      ? `${moment(slot.date).format("DD MMM YYYY")}|${startTime}-${endTime}`
      : "";
  }, [locState.slot]);

  const isTelemed = useMemo(() => {
    return locState.appointmentLocation === "online";
  }, [locState.appointmentLocation]);

  // Handler
  const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target?.files;
    if (files?.length) {
      setAllFiles(Array.from(files).slice(0, 3));
    }
  };

  const handleAttachFile = () => {
    inputRef.current.click();
  };

  const handleChangeDatetime = () => {
    history.goBack();
  };

  const handleConfirm = () => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: URLS.CONFIRM_APPOINTMENT,
      },
    });
  };

  return (
    <DoctorBanner
      headerName={isTelemed ? "" : "อาการหรือปัญหาสุขภาพ"}
      // config
      hideDoctor={true}
      barOnly={!isTelemed}
      barWhite={!isTelemed}
      // style
      contentClassName={classes.screen}
      headerClassName={classes.header}
      // Element
      bottom={
        <div className="button-submit-bottom">
          <MuiButton
            variant="contained"
            color="primary"
            onClick={handleConfirm}
          >
            ดำเนินการต่อ
          </MuiButton>
        </div>
      }
    >
      <>
        {isTelemed && (
          <div className={classes.datetime}>
            <div>
              <div className={classes.title} style={{ color: "white" }}>
                วันเวลานัดหมายของท่าน
              </div>
              {/* <div>
                  <BoxTitle type="label" labelStyle={{ fontWeight: "bold" }}>
                    <img className="icon" src={IMAGES.fast_time}></img>
                    <label style={{ textDecorationLine: "underline" }}>
                      เลือกช่วงเวลาที่เร็วที่สุด
                    </label>
                  </BoxTitle>
                </div> */}
            </div>
            <div className="box-card">
              <div className="header">
                <div>ช่วงเวลาที่เร็วที่สุด</div>
                <div></div>

                <BoxTitle
                  type="label"
                  labelStyle={{ fontWeight: "bold", color: COLORS.primary }}
                  // callback
                  onClick={handleChangeDatetime}
                >
                  <img className="icon" src={IMAGES.calendar}></img>
                  <label style={{ textDecorationLine: "underline" }}>
                    เปลี่ยนวันเวลา
                  </label>
                </BoxTitle>
              </div>

              <BoxTitle
                type="datetime"
                datetime={appointmentDatetime}
                style={{
                  minHeight: "44px",
                  backgroundColor: COLORS.bg,
                  marginTop: "0.5rem",
                  color: COLORS.primary,
                }}
              />
            </div>
          </div>
        )}

        <Form
          style={{
            marginTop: isTelemed ? "3.5rem" : 0, // 3.5rem
          }}
        >
          <div className={classes.title}>ระบุอาการหรือปัญหาสุขภาพ</div>

          <TextArea
            className={classes.textarea}
            rows={4}
            placeholder="ระบุอาการ"
          />

          <div className={classes.sub_title}>ระยะเวลาที่มีอาการ?</div>
          <Input
            className={classes.input}
            placeholder="ระบุระยะเวลา"
            fluid={true}
          />

          <div className={classes.title} style={{ marginTop: "2rem" }}>
            แนบเอกสารหรือ ประวัติการรักษา (ถ้ามี)
          </div>
          <div className={classes.hint} style={{ marginTop: "-0.55rem" }}>
            แนบสูงสุดได้ 3 รูป ไฟล์JPG, PNG, PDF ขนาดไฟล์ไม่เกิน 5MB
          </div>

          <div className={classes.box_attach_file}>
            {base64List.map((item) => (
              <div key={"file" + item.id} className={item.pdf ? "pdf" : ""}>
                <img src={item.pdf ? IMAGES.pdf : item.url} />
                <div className="delete">
                  <Icon name="trash alternate outline" />
                </div>
                {item.pdf && <label>{item.name}</label>}
              </div>
            ))}
            <MuiButton
              className={classes.attach_file}
              variant="text"
              disabled={(allFiles?.length || 0) >= 3}
              onClick={handleAttachFile}
            >
              <img src={IMAGES.attach_file} />
            </MuiButton>
          </div>

          <form id="fileUploadForm">
            <input
              ref={inputRef}
              type="file"
              id="fileInput"
              accept=".jpg, .png, .pdf"
              multiple
              hidden
              onChange={handleFileInputChange}
            />
          </form>
        </Form>
      </>
    </DoctorBanner>
  );
};

export default React.memo(HealthProblem);
