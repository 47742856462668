import React from "react";
// Mui
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

// I.Styles
import { cardModStyles } from "./CardKYC";
import { FormattedMessage } from "react-intl";

// Types
type ModConsentSuccessProps = {
  // data
  organizationName: string;
  // callback
  onApprove: () => any;
  onDeny: () => any;
};

// Images
const IMAGES = {
  consent: "/images/register/consent-success.png",
  check: "/images/register/check-green.png",
};

// Styles
const COLORS = {
  blue: "var(--blue-bdms-color)",
};

const useStyles = makeStyles((theme) => ({
  card: {
    ...cardModStyles,
    padding: "1rem 1rem .75rem",
  },
}));

const ModConsentSuccess = (props: ModConsentSuccessProps) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog open={true} className="smart-dialog-container">
        <DialogContent>
          <div className="content" style={{ marginTop: "2rem" }}>
            <img src={IMAGES.consent} alt="profile" />
            <div
              className="title"
              style={{ margin: "1rem 0 1rem", lineHeight: 1.35 }}
            >
              <div style={{ whiteSpace: "pre-line" }}>
                <FormattedMessage id="bplusClinicKey1087" />
              </div>
            </div>
            <div>
              <FormattedMessage id="bplusClinicKey1088" />
            </div>
          </div>

          <div className={classes.card}>
            <div style={{ display: "flex" }}>
              <div>{props.organizationName}</div>
              <div style={{ flex: 1 }}></div>
              <div>
                <img
                  src={IMAGES.check}
                  alt="check green"
                  style={{ marginTop: "-1px", width: "1.5rem" }}
                />
              </div>
            </div>
          </div>

          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              style={{ width: "87.5%" }}
              onClick={props.onApprove}
            >
              <FormattedMessage id="common.done" />
            </MuiButton>
          </div>

          <div
            aria-hidden="true"
            style={{
              textAlign: "center",
              color: COLORS.blue,
              marginBottom: "1rem",
            }}
            onClick={props.onDeny}
          >
            <FormattedMessage id="common.back" />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default React.memo(ModConsentSuccess);
