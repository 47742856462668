import React, { useEffect, useMemo, useCallback, useState } from "react";
import { Form } from "semantic-ui-react";
// Mui
import MuiRadio from "@mui/material/Radio";
import CircularProgress from "@mui/material/CircularProgress";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import moment from "moment";
import { History } from "history";
import { FormattedMessage, useIntl } from "react-intl";

import "react-phone-input-2/lib/semantic-ui.css";

// UX
import ModIdentityVerified from "./ModIdentityVerified";
import BottomSheetOption from "./BottomSheetOption";
import DateScrollPicker from "./DateScrollPicker";
import PhoneNumberInput from "./PhoneNumberInput";
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// CSS
import "./RegisterCSS.scss";

// I.Styles
import { stepeKYC } from "./CardKYCStep";

// Type
import {
  dropDownOption,
  profileInfo,
  registerInfo,
} from "bplus-lib/register/TypeModal";

// Interface
import { compareUpperCase } from "bplus-lib/MobSmartRegisterInterface";

type EditUserProfileProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  history: History<{
    ocrCardResult?: Record<string, any>;
    idCardBase64?: string | undefined;
  }>;
  registerInfo?: registerInfo;
  profileInfo?: profileInfo;
  consentStatus?: boolean;
  // options
  dropDownOption?: dropDownOption;
  // CommonInterface
  errorMessage?: Record<string, any>;
  loadingStatus?: Record<string, any>;
};

// Images
const IMAGES = {
  dismiss: "/images/register/dismiss.png",
};

// Styles
const styles = {
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

const useStyles = makeStyles((theme) => ({
  description: {
    padding: "1.55rem 20px 3.5rem",
    marginBottom: "3.5rem",
    lineHeight: 1.5,
    "& > div:nth-child(1)": {
      fontWeight: "bold",
      fontSize: "1.3rem",
      marginBottom: "-0.25rem",
    },
    "& ui": {
      paddingLeft: "1.5rem",
    },
  },
  ...stepeKYC,
}));

const GENDERS = {
  M: {
    th: "ชาย",
    en: "Male",
  },
  F: {
    th: "หญิง",
    en: "Female",
  },
};

const gender = [
  {
    key: 1,
    text: "ชาย",
    value: 1,
    language: "TH",
  },
  {
    key: 1,
    text: "Male",
    value: 1,
    language: "EN",
  },
  {
    key: 2,
    text: "หญิง",
    value: 2,
    language: "TH",
  },
  {
    key: 2,
    text: "Female",
    value: 2,
    language: "EN",
  }
]

const BUTTON_ACTIONS = {
  hospital: "_HOSPITAL",
};

const EDIT_USER_PROFILE = "EditUserProfile";
const ACTION_HOSPITAL = `${EDIT_USER_PROFILE}${BUTTON_ACTIONS.hospital}`;

const EditUserProfile: React.FunctionComponent<EditUserProfileProps> = (
  props
) => {
  const classes = useStyles();
  const intl = useIntl();

  const [useAddressAccord, setUseAddressAccord] = useState<
    "current" | "id-card"
  >("id-card");
  const [prevAddress, setPrevAddress] = useState<any>({});

  // Effect
  useEffect(() => {
    console.log("saika ~ useEffect: HandleGetUserProfile");
    props.onEvent({
      message: "HandleGetUserProfile",
      params: { history: props.history, loading: true, lang: intl.locale },
    });
  }, []);

  useEffect(() => {
    if (props.consentStatus === true) {
      props.onEvent({
        message: "HandleGetUserProfile",
        params: { history: props.history, lang: intl.locale },
      });
    }
  }, [props.consentStatus]);

  // Callback memo
  const mapItemsToOptions = useCallback(
    (items) => {
      return items
        .map((item: any) => ({
          key: item.id,
          text: item.name,
          value: item.name,
          language: item.language,
        }))
        .filter((option: any) => option.language === intl.locale.toUpperCase());
    },
    [intl.locale]
  );

   const mapItemsToGenderOptions = useCallback(() => {
      return gender.filter((option: any) => option.language === intl.locale.toUpperCase());
    },
    [intl.locale]
  );

  // Memo
  const allowConfirm = useMemo(() => {
    return props.onEvent({
      message: "CheckUserProfileRequiredField",
      params: props.profileInfo,
    });
  }, [props.profileInfo, props.registerInfo]);

  const prenameOptions = useMemo(() => {
    return mapItemsToOptions(props.dropDownOption?.prenames || []);
  }, [props.dropDownOption?.prenames, intl.locale]);

  const genderOptions = useMemo(() => {
    // return mapItemsToOptions(props.dropDownOption?.genders || []);
    return mapItemsToGenderOptions();
  }, [props.dropDownOption?.genders, intl.locale]);

  const nationalityOptions = useMemo(() => {
    return mapItemsToOptions(props.dropDownOption?.nationalities || []);
  }, [props.dropDownOption?.nationalities, intl.locale]);

  const isPassport = useMemo(() => {
    return props.profileInfo?.cid?.length !== 13;
  }, [props.profileInfo]);

  const cidLabel = useMemo(() => {
    let label = "\u00a0";

    if (props.profileInfo?.patient) {
      if (isPassport) {
        label = intl.formatMessage({ id: "bplusClinicKey962" });
      } else {
        label = intl.formatMessage({ id: "bplusClinicKey961" });
      }
    }

    return label;
  }, [props.profileInfo, isPassport]);

  useEffect(() => {
    const ocrCardResult = props.history.location.state?.ocrCardResult;
    const dpo = props.dropDownOption;
    const profile = props.profileInfo;

    const isProfileOption =
      dpo?.genders && dpo?.nationalities && dpo?.prenames && profile;

    if (ocrCardResult && isProfileOption) {
      const idcard: any = getProfileFromCardOCR(ocrCardResult);
      const row: any = structuredClone(props.profileInfo);

      for (const key in idcard) {
        const value = row[key];

        const unknown =
          value?.toUpperCase() === "UNKNOWN" || value === "ไม่รู้";
        // fill เฉพาะที่ยังไม่ได้กรอกข้อมูล
        if (!value || unknown) {
          (profile as any)[key] = idcard[key];
        }
      }

      const address = profile?.address ? profile?.address : row.address;

      props.setProp("registerInfo", {
        ...profile,
        address,
      });

      setPrevAddress({ "id-card": address });
    } else if (isProfileOption) {
      setPrevAddress({ "id-card": profile?.address || "" });
    }
  }, [
    props.history.location.state?.ocrCardResult,
    props.dropDownOption?.genders,
    props.dropDownOption?.nationalities,
    props.dropDownOption?.prenames,
  ]);

  const findNationalityName = (nation: string) => {
    const options = props.dropDownOption?.nationalities || [];

    return (
      options.find(
        (item: any) =>
          item.country_code[0] === nation[0] &&
          (item.country_code[1] === nation[1] ||
            item.country_code[1] === nation[2]) &&
          item.language === intl.locale.toUpperCase()
      )?.name || ""
    );
  };

  const pickText = (text: string, index: number) => {
    return text?.split(" ")?.map((text: string) => text.trim())?.[index] || "";
  };

  const getPrename = (nameTh: string, nameEn: string) => {
    const prenameTh = pickText(nameTh, 0);
    const prenameEn = pickText(nameEn, 0);

    const prename = props.dropDownOption?.prenames?.find(
      (option) =>
        (compareUpperCase(option.name, prenameTh) ||
          compareUpperCase(option.name, prenameEn)) &&
        compareUpperCase(option.language, intl.locale)
    );

    return prename;
  };

  const getProfileFromCardOCR = (ocrCardResult: Record<string, any>) => {
    const nameTh: string = ocrCardResult.NAME || "";
    const nameEn: string = ocrCardResult.FIRST_NAME_EN || "";
    const birthdate = ocrCardResult.BIRTH_DATE_EN || "";
    const docType = ocrCardResult.DOC_TYPE || "";

    const isNa = nameTh?.includes(" ณ ");
    const isMiddlename = nameTh?.split(" ")?.length === 4 && !isNa;

    const middlenameTh = isMiddlename ? pickText(nameTh, 2) : "";
    const middlenameEn = isMiddlename ? pickText(nameEn, 2) : "";

    const firstnameTh = pickText(nameTh, 1);
    let lastnameTh = pickText(nameTh, isMiddlename ? 3 : 2);

    const prename = getPrename(nameTh, nameEn);
    const prenameValue = prename?.name || "";

    if (isNa) {
      lastnameTh = nameTh.replace(/.*(?=ณ)/g, "");
    }

    const firstnameEn =
      docType === "PASSPORT" ? nameEn.trim() : pickText(nameEn, 1);
    const lastnameEn = ocrCardResult.LAST_NAME_EN || "";

    const addressTh = ocrCardResult.ADDRESS || "";

    const nationality = ocrCardResult.NATION
      ? findNationalityName(ocrCardResult.NATION)
      : "";

    const sex = ocrCardResult.SEX ? ocrCardResult.SEX : prename?.gender || "";
    const gender = (GENDERS as any)[sex]?.[intl.locale] || "";

    const dob = birthdate ? moment(birthdate).format("YYYYMMDD") : "";

    return {
      pre_name: prenameValue,
      first_name: firstnameTh,
      middle_name: middlenameTh,
      last_name: lastnameTh,
      first_name_en: firstnameEn,
      middle_name_en: middlenameEn,
      last_name_en: lastnameEn,
      dob,
      gender,
      nationality: nationality,
      address: addressTh,
    };
  };

  // Handler
  const handleUpdateProfile = () => {
    props.onEvent({
      message: "HandleUpdateProfile",
      params: { history: props.history, card: EDIT_USER_PROFILE, isPassport },
    });
  };

  const handleIdentityVerified = async () => {
    props.onEvent({
      message: "HandleCheckPatientHasHospitial",
      params: {
        card: EDIT_USER_PROFILE,
        history: props.history,
        cardHospital: ACTION_HOSPITAL,
      },
    });
  };

  const handleChangeProfileInfo = (e: any, v: any) => {
    props.onEvent({
      message: "HandleSetProfileInfo",
      params: { key: v.name, value: v.value },
    });
  };

  const handleDateChange = (date: Date) => {
    props.onEvent({
      message: "HandleSetProfileInfo",
      params: {
        key: "dob",
        value: moment(date).format("YYYYMMDD"),
      },
    });
  };

  const handleCloseError = () => {
    const { [ACTION_HOSPITAL]: key, ...error } = props.errorMessage || {};

    props.setProp(`errorMessage`, { ...error });
  };

  const handleChangeCheckbox = (value: any) => () => {
    const cAddress = prevAddress[value] || "";
    const pAddress = prevAddress[useAddressAccord] || "";

    handleUpdateAddress(pAddress, cAddress);

    setUseAddressAccord(value);
  };

  const handleChangeAddress = (e: any, v: any) => {
    const value = v.value;

    handleUpdateAddress(value, value);
  };

  const handleUpdateAddress = (pAddress: string, cAddress: string) => {
    setPrevAddress({
      ...prevAddress,
      [useAddressAccord]: pAddress,
    });

    handleChangeProfileInfo(null, {
      value: cAddress,
      name: "address",
    });
  };

  return (
    <div>
      <div className={classes.step_ekyc}>
        <div style={styles.center}>
          <div className="number">{3}</div>
          <div className="title">
            <FormattedMessage id={"bplusClinicKey1107"} />
          </div>
        </div>
        <div style={styles.center}>
          <div className="divider">
            <div></div>
            <div></div>
            <div className="step-active"></div>
          </div>
        </div>
      </div>
      <div
        className="main-screen"
        style={{ padding: "0px", margin: "15px 0px" }}
      >
        <div style={{ width: "100%" }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            {/* Form EditProfile */}
            <div>
              <Form>
                <Form.Field>
                  <div className="edit-header-section" style={{ marginTop: 0 }}>
                    {<FormattedMessage id="bplusClinicKey373" />}
                  </div>
                </Form.Field>

                <div className="form-margin">
                  <Form.Input
                    required={true}
                    value={props.profileInfo?.cid}
                    label={cidLabel}
                    disabled={true}
                  />

                  <BottomSheetOption
                    required={true}
                    value={props.profileInfo?.pre_name}
                    name="pre_name"
                    options={prenameOptions}
                    label={intl.formatMessage({ id: "profile.prename" })}
                    onChange={handleChangeProfileInfo}
                  />

                  {!isPassport && !!props.profileInfo?.patient && (
                    <>
                      <Form.Input
                        value={props.profileInfo?.middle_name || ""}
                        name="middle_name"
                        label={intl.formatMessage({
                          id: "bplusClinicKey1108",
                        })}
                        // disabled={true}
                        onChange={handleChangeProfileInfo}
                      />

                      <Form.Input
                        required
                        value={props.profileInfo?.first_name}
                        name="first_name"
                        label={intl.formatMessage({
                          id: "bplusClinicKey1109",
                        })}
                        onChange={handleChangeProfileInfo}
                      />

                      <Form.Input
                        required={true}
                        value={props.profileInfo?.last_name}
                        name="last_name"
                        label={intl.formatMessage({
                          id: "bplusClinicKey1110",
                        })}
                        onChange={handleChangeProfileInfo}
                      />
                    </>
                  )}

                  <Form.Input
                    value={props.profileInfo?.middle_name_en || ""}
                    name="middle_name_en"
                    label={intl.formatMessage({
                      id: "bplusClinicKey1111",
                    })}
                    // disabled={true}
                    onChange={handleChangeProfileInfo}
                  />

                  <Form.Input
                    value={props.profileInfo?.first_name_en || ""}
                    name="first_name_en"
                    label={intl.formatMessage({
                      id: "bplusClinicKey1112",
                    })}
                    // disabled={true}
                    onChange={handleChangeProfileInfo}
                  />

                  <Form.Input
                    value={props.profileInfo?.last_name_en || ""}
                    name="last_name_en"
                    label={intl.formatMessage({
                      id: "bplusClinicKey1113",
                    })}
                    // disabled={true}
                    onChange={handleChangeProfileInfo}
                  />

                  <DateScrollPicker
                    label={intl.formatMessage({
                      id: "profile.day_month_year_birth",
                    })}
                    value={moment(props.profileInfo?.dob) || moment()}
                    required={true}
                    dateFormat="DD/MM/YYYY"
                    maxDate={moment()}
                    onChange={handleDateChange}
                  />

                  <BottomSheetOption
                    required={true}
                    value={props.profileInfo?.gender}
                    name="gender"
                    options={genderOptions}
                    label={intl.formatMessage({ id: "profile.gender" })}
                    onChange={handleChangeProfileInfo}
                  />

                  <BottomSheetOption
                    required
                    value={props.profileInfo?.nationality}
                    name="nationality"
                    options={nationalityOptions}
                    label={intl.formatMessage({ id: "profile.nationality" })}
                    onChange={handleChangeProfileInfo}
                  />
                </div>

                {/* Section address contact */}

                <Form.Field>
                  <div className="edit-header-section">
                    <FormattedMessage id="profile.address" />
                  </div>
                </Form.Field>
                <div className="form-margin">
                  {!isPassport && (
                    <Form.Group inline>
                      <div
                        aria-hidden="true"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr",
                          alignItems: "center",
                          marginRight: "1rem",
                        }}
                        onClick={handleChangeCheckbox("id-card")}
                      >
                        <div>
                          <MuiRadio
                            className="smart-radio-button"
                            checked={useAddressAccord === "id-card"}
                          />
                        </div>
                        <div>
                          <FormattedMessage id="bplusClinicKey1062" />
                        </div>
                      </div>
                      <div
                        aria-hidden="true"
                        style={{
                          display: "grid",
                          gridTemplateColumns: "auto 1fr",
                          alignItems: "center",
                        }}
                        onClick={handleChangeCheckbox("current")}
                      >
                        <div>
                          <MuiRadio
                            className="smart-radio-button"
                            checked={useAddressAccord === "current"}
                          />
                        </div>
                        <div>
                          <FormattedMessage id="bplusClinicKey1063" />
                        </div>
                      </div>
                    </Form.Group>
                  )}

                  <Form.TextArea
                    value={prevAddress[useAddressAccord] || ""}
                    name="address"
                    rows={2}
                    style={{ marginTop: isPassport ? "" : "-0.25rem" }}
                    onChange={handleChangeAddress}
                  />
                </div>
                {/* Section hospital contact */}

                <Form.Field>
                  <div className="edit-header-section">
                    <FormattedMessage id="bplusClinicKey1061" />
                  </div>
                </Form.Field>

                <div className="form-margin">
                  <PhoneNumberInput
                    value={props.profileInfo?.phone_no || ""}
                    name="phone_no"
                    label={<FormattedMessage id="bplusClinicKey968" />}
                    onChange={handleChangeProfileInfo}
                  />

                  <Form.Input
                    name="email"
                    value={props.profileInfo?.email}
                    label={<FormattedMessage id="profile.email" />}
                    onChange={handleChangeProfileInfo}
                  />
                  <div>{<FormattedMessage id="bplusClinicKey1066" />}</div>
                </div>

                {/* Section Emergency contact */}

                <Form.Field>
                  <div className="edit-header-section">
                    <FormattedMessage id="bplusClinicKey1067" />
                  </div>
                </Form.Field>

                <div className="form-margin">
                  <Form.Input
                    value={props.profileInfo?.ecp_full_name}
                    name="ecp_full_name"
                    label={<FormattedMessage id="bplusClinicKey596" />}
                    onChange={handleChangeProfileInfo}
                  />

                  <PhoneNumberInput
                    value={props.profileInfo?.ecp_phone_no || ""}
                    name="ecp_phone_no"
                    label={<FormattedMessage id="bplusClinicKey968" />}
                    onChange={handleChangeProfileInfo}
                  />

                  <Form.Input
                    value={props.profileInfo?.ecp_relationship || ""}
                    name="ecp_relationship"
                    label={<FormattedMessage id="bplusClinicKey595" />}
                    onChange={handleChangeProfileInfo}
                  />
                </div>
              </Form>

              <div
                className="button-submit-bottom"
                style={{ marginTop: "2rem" }}
              >
                <MuiButton
                  variant="contained"
                  color="primary"
                  disabled={
                    !allowConfirm ||
                    props.loadingStatus?.[`${EDIT_USER_PROFILE}_CONFIRM`]
                  }
                  onClick={handleUpdateProfile}
                >
                  <FormattedMessage id="common.confirm" />
                  {props.loadingStatus?.[`${EDIT_USER_PROFILE}_CONFIRM`] && (
                    <CircularProgress
                      style={{
                        marginLeft: "0.5rem",
                      }}
                      size={20}
                    />
                  )}
                </MuiButton>
              </div>
            </div>
          </LocalizationProvider>
        </div>
      </div>

      {props.errorMessage?.[EDIT_USER_PROFILE] && (
        <ModIdentityVerified
          type={props.errorMessage?.[EDIT_USER_PROFILE].status}
          buttonText={<FormattedMessage id="bplusClinicKey966" />}
          loading={props.loadingStatus?.[EDIT_USER_PROFILE]}
          // callback
          onApprove={handleIdentityVerified}
        />
      )}

      <DialogConfirm
        open={props.errorMessage?.[ACTION_HOSPITAL]}
        title={<FormattedMessage id="bplusClinicKey1034" />}
        description={props.errorMessage?.[ACTION_HOSPITAL]}
        img={IMAGES.dismiss}
        imgStyle={{ width: "4rem", margin: "-1rem 0px 1.5rem" }}
        hideDeny={true}
        // element
        approveText={<FormattedMessage id="common.ok" />}
        // callback
        onApprove={handleCloseError}
      />
    </div>
  );
};

export default React.memo(EditUserProfile);
