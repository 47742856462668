import React, { useMemo, useState, useEffect } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

// Icon
import SearchIcon from "@mui/icons-material/Search";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useHistory, useLocation } from "react-router";
import { useIntl } from "react-intl";

// UX
import CardCenterGroupList from "./CardCenterGroupList";
import TopServices, { TOP_SERVICES } from "./TopServices";
// appointment
import SearchBox from "../appointment/SearchBox";
// patient-lib
import BottomSheetSelectHospital from "patient-lib/BottomSheetSelectHospital";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";

// Interface
import { Event, State } from "../MobSmartAppointmentInterface";

// Const.
import { URLS } from "./Constants";

// Types
type DoctorAppointmentProps = {
  onEvent: (e: Event) => any;
  setProp: (key: string, value: any, callback?: Function) => any;
  // data
  hospitalList?: any[];
  // config
  simulator?: boolean;
} & Pick<State, "topServiceList" | "loadingStatus" | "centerGroupList">;

type DALocationType = {
  storedState?: {
    hospital?: Record<string, any> | null;
  };
};

// Styles
const COLORS = {
  btn_gradient: "linear-gradient(180deg, #0147A3 0%, #0B87CD 100%)",
  bg_gradient: "linear-gradient(147.46deg, #0669EB 0%, #00357A 71.53%)",
  bg_opacity: "rgba(255, 255, 255, 0.12)",
};

const selectHospitalStyles = {
  "&.MuiButton-root": {
    backgroundColor: COLORS.bg_opacity,
    boxShadow: "none",
    borderRadius: "8px",
    color: "white",
    padding: "0.85rem .5rem 0.85rem 1rem",
    width: "100%",
    justifyContent: "flex-start",
    textTransform: "unset",
    margin: "14px 0 0",
    "& svg": {
      fontSize: "2rem",
    },
    "&:hover": {
      backgroundColor: COLORS.bg_opacity,
      boxShadow: "none",
    },
  },
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  screen: {
    background: "white",
    minHeight: "100vh",
    width: "100%",
    position: "relative",
  },
  search_box: {
    "& svg": {
      color: "white",
    },
    "& input": {
      paddingLeft: "1em !important",
      background: "white !important",
    },
    "& .search.icon": {
      display: "none",
    },
  },
  header_inner: {
    padding: "15px 15px 37px",
    background: COLORS.bg_gradient,
    marginTop: "-50px",
    paddingTop: "50px",
  },
  select_hospital: selectHospitalStyles,
  content: {
    borderTopLeftRadius: "16px",
    borderTopRightRadius: "16px",
    marginTop: "-16px",
    background: "white",
    width: "100%",
    height: "17px",
    padding: "22px 16px",
  },
  title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    marginBottom: "0.25rem",
  },
}));

const BUTTON_ACTIONS = {
  centergroup: "_CENTER_GROUP",
};

const DOCTOR_APPOINTMENT = "DoctorAppointment";
const ACTION_CENTERGROUP = `${DOCTOR_APPOINTMENT}${BUTTON_ACTIONS.centergroup}`;

const DoctorAppointment = (props: DoctorAppointmentProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<DALocationType>();
  const intl = useIntl();

  const [openModSelectHospital, setOpenModSelectHospital] =
    useState<boolean>(false);
  const [selectedHospital, setSelectedHospital] = useState<Record<
    string,
    any
  > | null>(null);
  const [showTopService, setShowTopService] = useState<boolean>(false);

  // Memo Effect
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  const hospitalItems = useMemo(() => {
    const list = props.hospitalList || [];

    return [{ code: "", name: "เครือข่ายโรงพยาบาลกรุงเทพทั้งหมด" }, ...list];
  }, [props.hospitalList]);

  // Effect
  useEffect(() => {
    setSelectedHospital(locState.storedState?.hospital || hospitalItems[0]);
  }, [locState.storedState]);

  useEffect(() => {
    if (!props.centerGroupList?.length) {
      props.onEvent({
        message: "GetListCenterGroup",
        params: { lang: intl.locale, card: ACTION_CENTERGROUP },
      });
    }
  }, []);

  // Memo
  const filterCenterGroup = useMemo(() => {
    const lists = props.centerGroupList || [];

    return !selectedHospital?.code
      ? lists
      : lists.filter((item) => selectedHospital.code === item.hospital_code);
  }, [props.centerGroupList, selectedHospital]);

  // Handler
  const handleOpenModSelectHospital = () => {
    setOpenModSelectHospital(true);
  };

  const handleSearchDoctor = () => {
    handleHistoryPushState({
      pathname: URLS.SEARCH_DOCTOR,
    });
  };

  const handleSelectCenterGroup = (selected: Record<string, any>) => {
    handleHistoryPushState({
      pathname: URLS.SELECT_CENTER,
      centergroup: selected,
    });
  };

  const handleSelectTopService = (selected: Record<string, any>) => {
    if (selected.type === "med_program") {
      handleHistoryPushState({
        pathname: URLS.SELECT_CHECKUP_TYPE,
        centergroup: { ...selected, appointment_type: "check_up" },
      });
    }
  };

  const handleHistoryPushState = async (data: any) => {
    await props.setProp("textSearch", "");

    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        pathname: data.pathname,
        history: history,
        state: {
          centergroup: data?.centergroup,
          hospital: selectedHospital?.code ? selectedHospital : null,
        },
      },
    });
  };

  console.log("DoctorAppointment render !!", props);

  return (
    <div
      className="remember-scroll"
      style={{ height: "100vh", overflowY: "auto" }}
    >
      <HeaderBar
        setTitle="นัดหมายแพทย์"
        headerSx={{
          background: `linear-gradient(147.46deg, #0669EB 0%, #00357A ${
            showTopService ? 145 : 106
          }%) !important`,
        }}
        titleStyle={{ fontWeight: "bold", fontSize: "1.1rem" }}
        // config
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />
      <div className={classes.screen}>
        <div className={classes.header_inner}>
          <SearchBox
            className={classes.search_box}
            placeholder="ค้นหาแพทย์"
            hideSearchList={true}
            // style
            rightIconColor={COLORS.btn_gradient}
            // callback
            onInputClick={handleSearchDoctor}
            onRightIconClick={handleSearchDoctor}
            // Element
            rightIcon={<SearchIcon />}
          />

          <MuiButton
            className={classes.select_hospital}
            variant="contained"
            onClick={handleOpenModSelectHospital}
          >
            <span>{selectedHospital?.name}</span>
            <div style={{ flex: 1 }}></div>
            <ArrowDropDownIcon />
          </MuiButton>

          <TopServices
            onEvent={props.onEvent}
            // data
            hospitalCode={selectedHospital?.code}
            topServiceList={props.topServiceList}
            loading={props.loadingStatus?.[TOP_SERVICES]}
            isRefresh={
              !locState.storedState?.hospital || !props.topServiceList?.length
            }
            // config
            simulator={props.simulator}
            // callback
            onShowTopService={setShowTopService}
            onSelect={handleSelectTopService}
          />
        </div>

        <div className={classes.content}>
          <div className={classes.title}>ความชำนาญของแพทย์</div>

          <div style={{ paddingBottom: "1rem" }}>
            <CardCenterGroupList
              items={filterCenterGroup}
              loading={props.loadingStatus?.[ACTION_CENTERGROUP]}
              noResultsMessage={
                <>
                  <div>
                    ไม่พบ Center group สำหรับ
                    <div>
                      <span>“{selectedHospital?.name}”</span>
                    </div>
                  </div>
                  <div>กรุณาเลือกโรงพยาบาลใหม่</div>
                </>
              }
              // callback
              onSelect={handleSelectCenterGroup}
            />
          </div>
        </div>

        <BottomSheetSelectHospital
          open={openModSelectHospital}
          hospitalList={hospitalItems}
          selectedHospital={selectedHospital}
          onChange={setOpenModSelectHospital}
          onSelect={setSelectedHospital}
        />
      </div>
    </div>
  );
};

export default React.memo(DoctorAppointment);
