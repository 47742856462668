import AddFingerPrintConsentTreatment from "./AddFingerPrintConsentTreatment";

export default function toPDFMakeData(props: any) {
  return {
    pageSize: `A4`,
    defaultStyle: {
      font: `THSarabunNew`,
    },
    content: [
      {
        decorationStyle: ``,
        decoration: ``,
        bold: `true`,
        alignment: `center`,
        width: `auto`,
        decorationColor: ``,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        fontSize: `18`,
        color: ``,
        text: `หนังสือรับทราบ และยินยอมรับการรักษา ฟื้นฟู หรือทำหัตถการ`,
      },
      {
        decorationStyle: ``,
        decoration: ``,
        alignment: `left`,
        color: ``,
        preserveLeadingSpaces: true,
        bold: false,
        margin: [0, 0, 0, 0],
        text: ` `,
        decorationColor: ``,
        pageBreak: ``,
        width: `auto`,
        fontSize: 15,
      },
      {
        columns: [
          {
            fontSize: 15,
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            width: 300,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
          },
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            text: `เขียนที่ โรงพยาบาลเซเปี้ยนซ์	`,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            color: ``,
            decoration: ``,
            bold: false,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            text: ``,
            decoration: ``,
            pageBreak: ``,
            width: 300,
            preserveLeadingSpaces: true,
          },
          {
            bold: false,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            text: `วันที่`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            width: 5,
            text: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            text: props.items?.printDay,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `center`,
            color: ``,
          },
          {
            text: ``,
            bold: false,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: 5,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
          },
          {
            decoration: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            bold: false,
            color: ``,
            text: `เดือน `,
            alignment: `left`,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
          },
          {
            fontSize: 15,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            width: 5,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
          },
          {
            alignment: `center`,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            width: `auto`,
            color: ``,
            text: props.items?.printMonth,
          },
          {
            text: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            width: 5,
            alignment: `left`,
            decoration: ``,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            alignment: `left`,
            decorationStyle: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `พ.ศ. `,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            alignment: `left`,
            text: ``,
            decorationStyle: ``,
            width: 5,
            fontSize: 15,
            bold: false,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            decoration: ``,
            bold: false,
            text: props.items?.printYear,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `center`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
          {
            margin: [0, 0, 0, 0],
            width: 5,
            decoration: ``,
            text: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            alignment: `left`,
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            fontSize: 15,
            color: ``,
            text: `เวลา `,
            pageBreak: ``,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            bold: false,
            decorationColor: ``,
          },
          {
            bold: false,
            color: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            width: 5,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            alignment: `center`,
            fontSize: 15,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            color: ``,
            width: `auto`,
            text: props.items?.printTime,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            width: 5,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            color: ``,
          },
          {
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            width: `auto`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            text: `น.`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        decorationStyle: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        text: ` `,
        margin: [0, 0, 0, 0],
        decoration: ``,
        fontSize: 15,
        decorationColor: ``,
        color: ``,
        pageBreak: ``,
        bold: false,
        width: `auto`,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            width: 30,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            bold: false,
            text: ``,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            bold: false,
            text: `ข้าพเจ้า (นาย, นาง , นางสาว)`,
            fontSize: 15,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            alignment: `left`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
          },
          {
            decorationStyle: ``,
            text: ` `,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            width: 5,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            decoration: ``,
            alignment: `left`,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                decorationColor: ``,
                text: props.items?.formatPatient,
                fontSize: 15,
                bold: false,
                color: ``,
                margin: [0, 0, 0, -15],
                width: `auto`,
                pageBreak: ``,
                decorationStyle: ``,
                decoration: ``,
                alignment: `center`,
              },
              {
                fontSize: 15,
                alignment: `left`,
                width: `auto`,
                text: `.........................................................`,
                margin: [0, 0, 0, 0],
                pageBreak: ``,
                bold: false,
                decoration: ``,
                preserveLeadingSpaces: true,
                color: ``,
                decorationColor: ``,
                decorationStyle: ``,
              },
            ],
          },
          {
            bold: false,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            decoration: ``,
            text: `อายุ `,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            text: ` `,
            decoration: ``,
            pageBreak: ``,
            width: 5,
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
          },
          {
            stack: [
              {
                preserveLeadingSpaces: true,
                text: props.items?.age,
                bold: false,
                width: `auto`,
                pageBreak: ``,
                margin: [0, 0, 0, -15],
                fontSize: 15,
                decoration: ``,
                decorationStyle: ``,
                alignment: `center`,
                color: ``,
                decorationColor: ``,
              },
              {
                color: ``,
                margin: [0, 0, 0, 0],
                decorationColor: ``,
                decoration: ``,
                pageBreak: ``,
                text: `.........................................................`,
                bold: false,
                preserveLeadingSpaces: true,
                decorationStyle: ``,
                width: `auto`,
                fontSize: 15,
                alignment: `left`,
              },
            ],
          },
          {
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            decorationStyle: ``,
            width: `auto`,
            fontSize: 15,
            text: `ปี (ผู้รับบริการ)`,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            fontSize: 15,
            bold: false,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
            text: `หรือ (นาย, นาง , นางสาว)`,
            alignment: `left`,
          },
          {
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            text: ` `,
            width: 5,
            fontSize: 15,
            color: ``,
            bold: false,
            decoration: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
          },
          {
            stack: [
              {
                text:
                  props.items?.relative_address?.owner_name !== null &&
                  props.items?.relative_address?.owner_name !== ""
                    ? props.items?.relative_address?.owner_name
                    : " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `center`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
              },
              {
                text: `...........................................................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            width: 3,
            color: ``,
            fontSize: 15,
            decoration: ``,
            text: ` `,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            pageBreak: ``,
            decoration: ``,
            text: `อายุ `,
            width: `auto`,
          },
          {
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            color: ``,
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            width: 5,
            decorationStyle: ``,
            fontSize: 15,
            text: ` `,
            pageBreak: ``,
          },
          {
            bold: false,
            decoration: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            text: `............................................`,
            decorationStyle: ``,
            alignment: `center`,
            width: `auto`,
            margin: [0, 0, 0, 0],
          },
          {
            bold: false,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            text: `ปี (ผู้แทนโดยชอบธรรม)`,
            pageBreak: ``,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            decoration: ``,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            text: `มีความเกี่ยวข้องเป็น`,
            bold: false,
            decorationColor: ``,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            stack: [
              {
                text:
                  props.items?.relative_address?.relative !== null &&
                  props.items?.relative_address?.relative !== ""
                    ? props.items?.relative_address?.relative
                    : " ",
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [34, 0, 0, -15],
              },
              {
                text: `.............................................`,
                decoration: ``,
                decorationStyle: ``,
                decorationColor: ``,
                width: `auto`,
                color: ``,
                bold: false,
                fontSize: 15,
                pageBreak: ``,
                alignment: `left`,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, 0],
              },
            ],
          },
          {
            margin: [-114, 0, 0, 0],
            text: `ของ (ระบุชื่อผู้รับบริการ)`,
            bold: false,
            alignment: `left`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            pageBreak: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            bold: false,
            width: 5,
            fontSize: 15,
          },
          {
            stack: [
              {
                alignment: `center`,
                fontSize: 15,
                decorationColor: ``,
                bold: false,
                decoration: ``,
                width: `auto`,
                decorationStyle: ``,
                preserveLeadingSpaces: true,
                color: ``,
                pageBreak: ``,
                text: props.items?.formatPatient,
                margin: [0, 0, 0, -15],
              },
              {
                margin: [0, 0, 0, 0],
                color: ``,
                decorationColor: ``,
                pageBreak: ``,
                alignment: `left`,
                text: `............................................................................................`,
                preserveLeadingSpaces: true,
                fontSize: 15,
                width: `auto`,
                bold: false,
                decorationStyle: ``,
                decoration: ``,
              },
            ],
          },
        ],
      },
      {
        alignment: `left`,
        decorationColor: ``,
        color: ``,
        preserveLeadingSpaces: true,
        fontSize: 15,
        decoration: ``,
        bold: false,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        text: ` `,
        decorationStyle: ``,
        width: `auto`,
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            width: 30,
            color: ``,
          },
          {
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            text: `ยินยอมให้แพทย์ และเจ้าหน้าที่ทีมสุขภาพทำการรักษาพยาบาล โดยข้าพเจ้ายอมรับผลที่จะเกิดขึ้นจากการรักษา`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            width: `auto`,
            decoration: ``,
          },
        ],
      },
      {
        fontSize: 15,
        color: ``,
        width: `auto`,
        decorationColor: ``,
        pageBreak: ``,
        text: `ทั้งนี้ข้าพเจ้าได้รับทราบข้อมูล`,
        bold: false,
        alignment: `left`,
        decorationStyle: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            decorationStyle: ``,
            pageBreak: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
            width: 50,
            bold: false,
          },
          {
            bold: false,
            decorationStyle: ``,
            text: `◌`,
            alignment: `center`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
          },
          {
            fontSize: 15,
            alignment: `left`,
            width: 5,
            margin: [0, 0, 0, 0],
            text: ``,
            color: ``,
            decoration: ``,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            width: `auto`,
            decorationColor: ``,
            decoration: ``,
            text: `การวินิจฉัยโรคขั้นต้น  `,
            preserveLeadingSpaces: true,
          },
          {
            decorationStyle: ``,
            text: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: 5,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
            decorationColor: ``,
          },
          {
            stack: [
              {
                bold: false,
                decorationColor: ``,
                text: `${props.items?.formatIcd10Code}  ${props.items?.formatIcd10Term}`,
                margin: [0, 0, 0, -15],
                preserveLeadingSpaces: true,
                decoration: ``,
                width: `auto`,
                color: ``,
                pageBreak: ``,
                alignment: `left`,
                fontSize: 15,
                decorationStyle: ``,
              },
              {
                decorationStyle: ``,
                margin: [0, 0, 0, 0],
                width: `auto`,
                text: `........................................................................................................................................................`,
                decoration: ``,
                fontSize: 15,
                alignment: `left`,
                bold: false,
                preserveLeadingSpaces: true,
                decorationColor: ``,
                pageBreak: ``,
                color: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            bold: false,
            width: 50,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
          },
          {
            bold: false,
            width: `auto`,
            alignment: `center`,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            text: `◌`,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: 5,
            color: ``,
            text: `		`,
          },
          {
            text: `แพทย์ผู้ทำการรักษา `,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            fontSize: 15,
            pageBreak: ``,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
          },
          {
            fontSize: 15,
            text: ``,
            alignment: `left`,
            width: 5,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
            bold: false,
          },
          {
            stack: [
              {
                decorationColor: ``,
                preserveLeadingSpaces: true,
                margin: [0, 0, 0, -15],
                width: `auto`,
                alignment: `left`,
                decoration: ``,
                color: ``,
                text: props.items?.doctor_name.length > 0 ? props.items?.doctor_name : " ",
                pageBreak: ``,
                fontSize: 15,
                decorationStyle: ``,
                bold: false,
              },
              {
                text: `.........................................................................................................................................................`,
                fontSize: 15,
                decoration: ``,
                decorationColor: ``,
                bold: false,
                pageBreak: ``,
                preserveLeadingSpaces: true,
                width: `auto`,
                color: ``,
                alignment: `left`,
                margin: [0, 0, 0, 0],
                decorationStyle: ``,
              },
            ],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            color: ``,
            width: 50,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
          },
          {
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decoration: ``,
            fontSize: 15,
            text: `◌`,
            alignment: `center`,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
          },
          {
            bold: false,
            text: `		`,
            pageBreak: ``,
            fontSize: 15,
            width: 5,
            preserveLeadingSpaces: true,
            color: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            decoration: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            width: `auto`,
            decoration: ``,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            text: `เหตุผล/ ความจำเป็น ในการเข้ารับการรักษา ..................................................................................................................`,
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            bold: false,
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            alignment: `left`,
            width: 50,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            alignment: `center`,
            bold: false,
            fontSize: 15,
            width: `auto`,
            text: `◌`,
            pageBreak: ``,
          },
          {
            decoration: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: `		`,
            margin: [0, 0, 0, 0],
            width: 5,
            alignment: `left`,
            color: ``,
            decorationColor: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            fontSize: 15,
            bold: false,
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationColor: ``,
            text: `วิธีการรักษา/ หัตถการ .....................................................................................................................................................`,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            text: ``,
            alignment: `left`,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            width: 50,
            pageBreak: ``,
            decoration: ``,
            decorationStyle: ``,
          },
          {
            fontSize: 15,
            alignment: `center`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            text: `◌`,
            width: `auto`,
            bold: false,
          },
          {
            margin: [0, 0, 0, 0],
            alignment: `left`,
            pageBreak: ``,
            width: 5,
            text: `		`,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
          {
            alignment: `left`,
            text: `ทางเลือก หากผู้รับบริการมีการตอบสนองกับผลการรักษาดี อาการหายเป็นปกติ/ ดีขึ้น/ ทุเลา สามารถกลับไป`,
            width: `auto`,
            color: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            decoration: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            color: ``,
            decorationColor: ``,
            decoration: ``,
            width: 88,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            alignment: `left`,
            text: ``,
          },
          {
            margin: [0, 0, 0, 0],
            text: `พักรักษาตัวต่อที่บ้านได้ แต่หากมีอาการเปลี่ยนแปลงอาการแย่ลง มีภาวะแทรกซ้อน หรือ เกิดภาวะฉุกเฉินอื่น`,
            color: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
          },
          {
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            fontSize: 15,
            decorationStyle: ``,
            color: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            text: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            text: ``,
            pageBreak: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
            bold: false,
            fontSize: 15,
            width: 88,
            preserveLeadingSpaces: true,
          },
          {
            decoration: ``,
            decorationColor: ``,
            bold: false,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: `แพทย์จะพิจารณาเปลี่ยนแปลงแผนการรักษา หรือ พิจารณาส่งตัวไปรับการรักษาต่อในโรงพยาบาลที่มี`,
          },
        ],
      },
      {
        columns: [
          {
            width: 88,
            decorationStyle: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            text: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
          },
          {
            pageBreak: ``,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            width: `auto`,
            fontSize: 15,
            color: ``,
            text: `ศักยภาพสูงขึ้น หากเกินขีดความสามารถของโรงพยาบาล`,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decoration: ``,
            color: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
            text: ``,
            preserveLeadingSpaces: true,
            width: 50,
          },
          {
            text: `◌`,
            color: ``,
            decorationStyle: ``,
            alignment: `center`,
            bold: false,
            width: `auto`,
            pageBreak: ``,
            decoration: ``,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            color: ``,
            bold: false,
            margin: [0, 0, 0, 0],
            width: 5,
            decorationStyle: ``,
            decorationColor: ``,
            text: `		`,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            text: `ข้อดีของการรักษา ............................................................................................................................................................`,
            bold: false,
            fontSize: 15,
            decoration: ``,
            width: `auto`,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decoration: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            width: 50,
            bold: false,
            color: ``,
            text: ``,
          },
          {
            pageBreak: ``,
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            text: `◌`,
            decoration: ``,
            alignment: `center`,
          },
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
            text: `		`,
            color: ``,
            pageBreak: ``,
            width: 5,
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            color: ``,
            decoration: ``,
            alignment: `left`,
            text: `ข้อเสีย หากไม่ได้รับการรักษา ..........................................................................................................................................`,
            pageBreak: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
            fontSize: 15,
            width: 50,
          },
          {
            decorationStyle: ``,
            color: ``,
            width: `auto`,
            alignment: `center`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            text: `◌`,
            decoration: ``,
            bold: false,
          },
          {
            text: `		`,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            bold: false,
            fontSize: 15,
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: 5,
          },
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
            decorationStyle: ``,
            text: `ผลการรักษา .....................................................................................................................................................................`,
            alignment: `left`,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decoration: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            width: 50,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
          },
          {
            decorationStyle: ``,
            width: `auto`,
            text: `◌`,
            decoration: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            alignment: `center`,
            bold: false,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
          },
          {
            color: ``,
            text: `		`,
            alignment: `left`,
            width: 5,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationColor: ``,
            text: `ความเสี่ยงและภาวะแทรกซ้อน ........................................................................................................................................`,
            margin: [0, 0, 0, 0],
            bold: false,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            width: `auto`,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            width: 50,
            preserveLeadingSpaces: true,
            color: ``,
            fontSize: 15,
            text: ``,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
          },
          {
            decorationColor: ``,
            alignment: `center`,
            text: `◌`,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            width: `auto`,
          },
          {
            width: 5,
            color: ``,
            text: `		`,
            preserveLeadingSpaces: true,
            bold: false,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            decoration: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
          },
          {
            fontSize: 15,
            color: ``,
            preserveLeadingSpaces: true,
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            text: `โรงพยาบาลเซเปี้ยน มีการทำบันทึกความเข้าใจ (MOU; Memorandum of Understanding) `,
            bold: false,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            width: 90,
            color: ``,
            text: ``,
          },
          {
            text: `1) `,
            bold: false,
            decorationStyle: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            decoration: ``,
            fontSize: 15,
            alignment: `left`,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
          },
          {
            decoration: ``,
            width: 5,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            pageBreak: ``,
            fontSize: 15,
            bold: false,
          },
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            bold: false,
            alignment: `center`,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            text: `◌`,
          },
          {
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            width: 5,
            decorationStyle: ``,
            alignment: `left`,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            pageBreak: ``,
            fontSize: 15,
          },
          {
            text: `ในการส่งต่อผู้ป่วยที่เจ็บป่วยฉุกเฉินวิกฤต ที่ ……………................................................................................`,
            color: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            alignment: `left`,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            text: ``,
            alignment: `left`,
            pageBreak: ``,
            width: 90,
          },
          {
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            width: `auto`,
            bold: false,
            alignment: `left`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `2) `,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            bold: false,
            width: 5,
            color: ``,
            text: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
          },
          {
            fontSize: 15,
            alignment: `center`,
            pageBreak: ``,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            text: `◌`,
          },
          {
            alignment: `left`,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            decorationStyle: ``,
            pageBreak: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            preserveLeadingSpaces: true,
            text: ``,
            color: ``,
            width: 5,
          },
          {
            decoration: ``,
            bold: false,
            fontSize: 15,
            color: ``,
            text: `หรือส่งต่อโรงพยาบาลที่ใกล้ที่สุด โดยไม่ต้องเสียค่าใช้จ่าย (ไม่ต้องสำรองจ่าย) จนพ้นวิกฤต`,
            alignment: `left`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decorationStyle: ``,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            fontSize: 15,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            width: 145,
            bold: false,
            preserveLeadingSpaces: true,
            text: ``,
            decoration: ``,
          },
          {
            decorationColor: ``,
            bold: false,
            text: `และสามารถเคลื่อนย้ายผู้ป่วยได้อย่างปลอดภัย แต่ไม่เกิน 72 ชั่วโมง ตามนโยบายรัฐ`,
            width: `auto`,
            color: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decoration: ``,
            alignment: `left`,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            color: ``,
            width: 145,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            alignment: `left`,
            decorationStyle: ``,
            decoration: ``,
            text: ``,
          },
          {
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            text: `* เจ็บป่วยฉุกเฉินวิกฤตมีสิทธิทุกที่ (UCEP; Universal Coverage for Emergency Patients)`,
            width: `auto`,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decoration: ``,
            text: ``,
            color: ``,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationStyle: ``,
            width: 145,
            alignment: `left`,
            bold: false,
          },
          {
            color: ``,
            text: `พ้นวิกฤตแล้ว ย้ายไปรักษาตัวต่อที่โรงพยาบาลตามสิทธิ์ของผู้ป่วย หรือตามความต้องการ`,
            width: `auto`,
            margin: [0, 0, 0, 0],
            bold: false,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationStyle: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            alignment: `left`,
            width: 145,
          },
          {
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            width: `auto`,
            alignment: `left`,
            fontSize: 15,
            text: `ของผู้ป่วย และ/หรือญาติ`,
            pageBreak: props.items?.haveHeader ? `after` : ``,
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            text: `			    `,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 50,
            bold: false,
            pageBreak: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            fontSize: 15,
            decorationStyle: ``,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            width: `auto`,
            fontSize: 15,
            color: ``,
            bold: false,
            decorationColor: ``,
            decoration: ``,
            text: `◌`,
            pageBreak: ``,
            decorationStyle: ``,
            alignment: `center`,
          },
          {
            fontSize: 15,
            preserveLeadingSpaces: true,
            text: ``,
            width: 5,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            decorationStyle: ``,
            alignment: `left`,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            text: `อื่นๆ แนะนำสถานที่ ………………………………………………....................................................................................................`,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            text: ``,
            alignment: `left`,
            decoration: ``,
            width: 50,
            margin: [0, 0, 0, 0],
            bold: false,
          },
          {
            margin: [0, 0, 0, 0],
            text: `◌`,
            width: `auto`,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationStyle: ``,
            alignment: `center`,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            color: ``,
            pageBreak: ``,
          },
          {
            alignment: `left`,
            pageBreak: ``,
            text: `		`,
            decoration: ``,
            margin: [0, 0, 0, 0],
            width: 5,
            fontSize: 15,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            color: ``,
            bold: false,
            decorationColor: ``,
          },
          {
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            pageBreak: ``,
            text: `โรงพยาบาลเซเปี้ยน มีนโยบายอนุญาติให้ผู้ป่วยสามารถนำยาที่รักษาอยู่ในปัจจุบัน มาใช้ในโรงพยาบาลได้`,
            decoration: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: `auto`,
            fontSize: 15,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            bold: false,
            preserveLeadingSpaces: true,
            color: ``,
            decorationStyle: ``,
            fontSize: 15,
            text: ``,
            decoration: ``,
            width: 88,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
          },
          {
            bold: false,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
            color: ``,
            text: `เพื่อให้ผู้ป่วยได้รับผลประโยชน์สูงสุด และเพื่อให้ได้รับยาอย่างต่อเนื่อง ญาติผู้ป่วยจะต้องนำยามาทดแทน`,
            alignment: `left`,
            fontSize: 15,
            width: `auto`,
            pageBreak: ``,
          },
        ],
      },
      {
        columns: [
          {
            bold: false,
            alignment: `left`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            text: ``,
            fontSize: 15,
            width: 88,
            color: ``,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
          },
          {
            margin: [0, 0, 0, 0],
            text: `ก่อนที่ยาเดิมจะหมดอย่างน้อย 1 สัปดาห์ กรณีที่ไม่สามารถนำยามาทดแทนได้ตามวันที่กำหนด`,
            alignment: `left`,
            color: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            width: `auto`,
            decorationStyle: ``,
            decoration: ``,
            decorationColor: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 88,
            pageBreak: ``,
            text: ``,
            decoration: ``,
            color: ``,
            decorationStyle: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            bold: false,
            fontSize: 15,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
          },
          {
            decorationStyle: ``,
            text: `ทางโรงพยาบาลฯ จะต้องดำเนินการจัดหายามาให้ผู้ป่วยตามคำสั่งแพทย์ โดยคิดค่าใช้จ่ายตามราคา`,
            decoration: ``,
            pageBreak: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
            width: `auto`,
            bold: false,
            margin: [0, 0, 0, 0],
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            width: 88,
            decoration: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            decorationColor: ``,
            decorationStyle: ``,
            color: ``,
            fontSize: 15,
            text: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
          },
          {
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            color: ``,
            decoration: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            text: `บัญชียาของโรงพยาบาล และไม่อนุญาตให้ญาตินำยาภายนอกมาเปลี่ยน ทดแทนยาของโรงพยาบาลที่ใช้ไปได้`,
            bold: false,
            width: `auto`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        decorationColor: ``,
        color: ``,
        text: ` `,
        decorationStyle: ``,
        margin: [0, 0, 0, 0],
        fontSize: 15,
        pageBreak: props.items?.haveHeader ? `` : `after`,
        decoration: ``,
        width: `auto`,
        bold: false,
        alignment: `left`,
        preserveLeadingSpaces: true,
      },
      {
        columns: [
          {
            text: ``,
            fontSize: 15,
            decoration: ``,
            decorationStyle: ``,
            width: 40,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            decorationColor: ``,
            bold: false,
          },
          {
            text: `ทั้งนี้ข้าพเจ้า เข้าใจถึงความจำเป็นอันเป็นเหตุให้ต้องได้รับการรักษา ฟื้นฟู ในโรงพยาบาลฯ และการปฏิบัติตัวขณะพักรักษา`,
            decorationStyle: ``,
            pageBreak: ``,
            preserveLeadingSpaces: true,
            bold: false,
            color: ``,
            alignment: `left`,
            decorationColor: ``,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        decorationStyle: ``,
        bold: false,
        color: ``,
        pageBreak: ``,
        text: `อยู่ในโรงพยาบาลเซเปี้ยนซ์ ข้าพเจ้ายินยอมให้แพทย์และผู้ที่ได้รับมอบหมาย สามารถกระทำการดังกล่าวข้างต้น โดยคำนึงถึงมาตรฐาน`,
        preserveLeadingSpaces: true,
        width: `auto`,
        alignment: `left`,
        fontSize: 15,
        decorationColor: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
      },
      {
        pageBreak: ``,
        fontSize: 15,
        width: `auto`,
        decoration: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        text: `วิชาชีพ ในกรณีที่มีการเปลี่ยนแปลงของการตรวจวินิจฉัย หรือแผนการรักษา ข้าพเจ้ามีสิทธิ์ที่จะได้รับอธิบายเพิ่มเติม และข้าพเจ้ามี`,
        alignment: `left`,
        decorationStyle: ``,
        decorationColor: ``,
        color: ``,
        bold: false,
      },
      {
        alignment: `left`,
        fontSize: 15,
        text: `สิทธิ์จะถอนตัวจากการรักษาพยาบาล โดยไม่ส่งผลกระทบต่อสิทธิ์การรักษาในครั้งต่อไปของข้าพเจ้า จึงลงลายมือ หรือพิมพ์ลายนิ้วมือ`,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        decorationColor: ``,
        decoration: ``,
        bold: false,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
      },
      {
        decoration: ``,
        color: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        decorationStyle: ``,
        fontSize: 15,
        bold: false,
        text: `เป็นหลักฐาน`,
        alignment: `left`,
        pageBreak: ``,
        width: `auto`,
      },
      {
        pageBreak: ``,
        decoration: ``,
        decorationColor: ``,
        fontSize: 15,
        bold: false,
        preserveLeadingSpaces: true,
        width: `auto`,
        margin: [0, 0, 0, 0],
        color: ``,
        alignment: `left`,
        text: ` `,
        decorationStyle: ``,
      },
      AddFingerPrintConsentTreatment,
      {
        text: ` `,
        decoration: ``,
        decorationStyle: ``,
        decorationColor: ``,
        width: `auto`,
        color: ``,
        bold: false,
        fontSize: 15,
        pageBreak: ``,
        alignment: `left`,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            fontSize: 15,
            width: 250,
            decorationStyle: ``,
            decorationColor: ``,
            text: `ลงชื่อ.......................................................ผู้รับบริการหรือผู้แทน`,
            decoration: ``,
            bold: false,
            pageBreak: ``,
            alignment: `center`,
            color: ``,
            margin: [0, 0, 0, 0],
          },
        ],
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            text: `(.............................................................)`,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            width: 160,
            bold: false,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            alignment: `center`,
          },
          {
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            pageBreak: ``,
            decoration: ``,
            text: ``,
            fontSize: 15,
            color: ``,
            width: 5,
            alignment: `left`,
            decorationColor: ``,
            bold: false,
            decorationStyle: ``,
          },
          {
            pageBreak: ``,
            alignment: `center`,
            width: `auto`,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            decorationColor: ``,
            text: `◌`,
          },
          {
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            color: ``,
            decoration: ``,
            alignment: `left`,
            fontSize: 15,
            width: 5,
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            text: ``,
          },
          {
            decorationStyle: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            decorationColor: ``,
            color: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
            alignment: `left`,
            width: `auto`,
            bold: false,
            pageBreak: ``,
            text: `มาคนเดียว **`,
          },
        ],
      },
      {
        columns: [
          {
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            width: 10,
            alignment: `center`,
            decoration: ``,
            decorationColor: ``,
            text: ``,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
          {
            decorationStyle: ``,
            bold: false,
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            decoration: ``,
            decorationColor: ``,
            text: `วันที่...............................................................`,
            color: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            fontSize: 15,
          },
        ]
      },
      {
        decorationStyle: ``,
        bold: false,
        pageBreak: ``,
        width: `auto`,
        alignment: `left`,
        decoration: ``,
        decorationColor: ``,
        text: ` `,
        color: ``,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        fontSize: 15,
      },
      {
        columns: [
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            pageBreak: ``,
            margin: [0, 0, 0, 0],
            text: `ลงชื่อ.......................................................พยานฝ่ายผู้รับบริการ`,
            width: 250,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            bold: false,
            alignment: `center`,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            fontSize: 15,
            alignment: `center`,
            text: `(....................................................................)`,
            width: 250,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            decorationColor: ``,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            bold: false,
          },
        ],
      },
      {
        columns: [
          {
            color: ``,
            text: `เกี่ยวข้องเป็น บิดา, มารดา, บุตร, ญาติ (ระบุ) ………………………………. ของผู้รับบริการ`,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            bold: false,
            width: 350,
            decorationStyle: ``,
            pageBreak: ``,
            fontSize: 15,
            alignment: `center`,
            margin: [0, 0, 0, 0],
            decoration: ``,
          },
        ],
      },
      {
        alignment: `left`,
        text: ` `,
        fontSize: 15,
        preserveLeadingSpaces: true,
        margin: [0, 0, 0, 0],
        bold: false,
        decorationColor: ``,
        decorationStyle: ``,
        decoration: ``,
        pageBreak: props.items?.haveHeader ? `after` : ``,
        width: `auto`,
        color: ``,
      },
      {
        columns: [
          {
            text: ``,
            decorationStyle: ``,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            width: 20,
            pageBreak: ``,
            fontSize: 15,
            decoration: ``,
            decorationColor: ``,
            alignment: `left`,
            color: ``,
          },
          {
            width: `auto`,
            pageBreak: ``,
            fontSize: 15,
            decorationColor: ``,
            alignment: `left`,
            decorationStyle: ``,
            text: `หมายเหตุ`,
            color: ``,
            margin: [0, 0, 0, 0],
            preserveLeadingSpaces: true,
            decoration: `underline`,
            bold: false,
          },
          {
            width: 5,
            preserveLeadingSpaces: true,
            decoration: ``,
            decorationColor: ``,
            text: ` `,
            pageBreak: ``,
            color: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            fontSize: 15,
          },
          {
            alignment: `left`,
            width: `auto`,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            color: ``,
            fontSize: 15,
            decoration: ``,
            bold: false,
            text: `* ** *** ****`,
            decorationColor: ``,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
          },
        ],
      },
      {
        text: `1. * กรณีผู้รับบริการ/ ผู้ให้ความยินยอม ลงลายมือชื่อไม่ได้ ให้พิมพ์ลายมือลงในกรอบสี่เหลี่ยม โดยต้องระบุว่าใช้นิ้วใดข้างไหน`,
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        bold: false,
        fontSize: 15,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        decoration: ``,
        decorationStyle: ``,
        color: ``,
        width: `auto`,
      },
      {
        color: ``,
        width: `auto`,
        fontSize: 15,
        bold: false,
        pageBreak: ``,
        decoration: ``,
        margin: [0, 0, 0, 0],
        decorationStyle: ``,
        preserveLeadingSpaces: true,
        text: `2. ** กรณีผู้ป่วยไม่มีญาติ หรือผู้นำส่ง ให้ระบุเพิ่มเติมไว้ว่า “มาคนเดียว”`,
        decorationColor: ``,
        alignment: `left`,
      },
      {
        text: `3. *** บุคคลผู้มีอำนาจกระทำการแทนผู้รับบริการ ในกรณีที่ผู้ป่วย ไม่อยู่ในฐานะรับทราบข้อมูลได้ ได้แก่`,
        bold: false,
        width: `auto`,
        color: ``,
        decoration: ``,
        decorationColor: ``,
        margin: [0, 0, 0, 0],
        preserveLeadingSpaces: true,
        alignment: `left`,
        pageBreak: ``,
        decorationStyle: ``,
        fontSize: 15,
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            text: ``,
            decorationStyle: ``,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            decoration: ``,
            pageBreak: ``,
            color: ``,
            bold: false,
            alignment: `left`,
            width: 30,
          },
          {
            pageBreak: ``,
            width: `auto`,
            alignment: `left`,
            text: `3.1 ทายาทโดยชอบธรรมของผู้รับบริการ คือ บิดา มารดา บุตร พี่น้องร่วมบิดามารดา พี่น้องร่วมบิดา หรือพี่น้องร่วมมารดา`,
            margin: [0, 0, 0, 0],
            decorationColor: ``,
            fontSize: 15,
            decorationStyle: ``,
            decoration: ``,
            color: ``,
            bold: false,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            pageBreak: ``,
            text: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            bold: false,
            width: 48,
            decoration: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            fontSize: 15,
          },
          {
            margin: [0, 0, 0, 0],
            width: `auto`,
            decorationStyle: ``,
            bold: false,
            fontSize: 15,
            text: `ปู่ ย่า ตา ยาย ลุง ป้า น้า อา สามี หรือภรรยาของผู้ป่วย`,
            decoration: ``,
            decorationColor: ``,
            pageBreak: ``,
            alignment: `left`,
            preserveLeadingSpaces: true,
            color: ``,
          },
        ],
      },
      {
        columns: [
          {
            text: ``,
            width: 30,
            fontSize: 15,
            decorationStyle: ``,
            decorationColor: ``,
            alignment: `left`,
            margin: [0, 0, 0, 0],
            bold: false,
            decoration: ``,
            color: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
          },
          {
            preserveLeadingSpaces: true,
            decorationColor: ``,
            text: `3.2 ผู้ปกครอง (ผู้ที่ศาลตั้งให้เป็นผู้ปกครองผู้ป่วย)`,
            alignment: `left`,
            pageBreak: ``,
            fontSize: 15,
            margin: [0, 0, 0, 0],
            decorationStyle: ``,
            width: `auto`,
            bold: false,
            color: ``,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            margin: [0, 0, 0, 0],
            color: ``,
            alignment: `left`,
            width: 30,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            pageBreak: ``,
            text: ``,
            fontSize: 15,
            bold: false,
            decorationStyle: ``,
            decoration: ``,
          },
          {
            fontSize: 15,
            text: `3.3 ผู้ปกครองดูแล (ผู้ที่ดูแลผู้ป่วยแทนบิดา มารดา ช่วงระยะเวลาหนึ่ง หรือตลอดมา)`,
            decorationColor: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            margin: [0, 0, 0, 0],
            bold: false,
            pageBreak: ``,
            decorationStyle: ``,
            color: ``,
            alignment: `left`,
            decoration: ``,
          },
        ],
      },
      {
        columns: [
          {
            width: 30,
            text: ``,
            margin: [0, 0, 0, 0],
            decoration: ``,
            color: ``,
            pageBreak: ``,
            alignment: `left`,
            decorationStyle: ``,
            decorationColor: ``,
            bold: false,
            fontSize: 15,
            preserveLeadingSpaces: true,
          },
          {
            text: `3.4 ผู้อนุบาล (บุคคลที่ศาลแต่งตั้งให้เป็นผู้อนุบาลบุคคลไร้ความสามารถ)`,
            decorationStyle: ``,
            pageBreak: ``,
            decoration: ``,
            width: `auto`,
            fontSize: 15,
            color: ``,
            margin: [0, 0, 0, 0],
            alignment: `left`,
            bold: false,
            decorationColor: ``,
            preserveLeadingSpaces: true,
          },
        ],
      },
      {
        columns: [
          {
            decorationColor: ``,
            decorationStyle: ``,
            bold: false,
            alignment: `left`,
            preserveLeadingSpaces: true,
            width: 30,
            fontSize: 15,
            pageBreak: ``,
            color: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            text: ``,
          },
          {
            color: ``,
            width: `auto`,
            preserveLeadingSpaces: true,
            decorationStyle: ``,
            decorationColor: ``,
            fontSize: 15,
            bold: false,
            decoration: ``,
            margin: [0, 0, 0, 0],
            pageBreak: ``,
            alignment: `left`,
            text: `3.5 ผู้พิทักษ์ (บุคคลที่ศาลแต่งตั้งให้เป็นผู้พิทักษ์บุคคลเสมือนไร้ความสามารถ) `,
          },
        ],
      },
      {
        columns: [
          {
            alignment: `left`,
            fontSize: 15,
            decorationColor: ``,
            preserveLeadingSpaces: true,
            text: `4.**** ผู้ให้ข้อมูล แพทย์เจ้าของไข้ทำหน้าที่ให้ข้อมูลแก่ผู้ป่วยโดยตรง เว้นแต่ในกรณีมีเหตุจำเป็นที่แพทย์เจ้าของไข้ไม่สามารถให้ข้อมูล`,
            decorationStyle: ``,
            decoration: ``,
            margin: [0, 0, 0, 0],
            color: ``,
            width: `auto`,
            pageBreak: ``,
            bold: false,
          },
        ],
      },
      {
        text: `แก่ผู้ป่วยด้วยตนเองได้ อาจให้แพทย์ท่านอื่นเป็นผู้ให้ข้อมูลแทนตามความเหมาะสม หากยังไม่อาจจัดหาแพทย์เป็นผู้ให้ข้อมูลได้`,
        color: ``,
        decorationColor: ``,
        pageBreak: ``,
        bold: false,
        decorationStyle: ``,
        alignment: `left`,
        fontSize: 15,
        preserveLeadingSpaces: true,
        decoration: ``,
        width: `auto`,
        margin: [0, 0, 0, 0],
      },
      {
        fontSize: 15,
        width: `auto`,
        alignment: `left`,
        decoration: ``,
        decorationStyle: ``,
        text: `ก็อาจจะมอบหมายให้พยาบาลเป็นผู้ให้ข้อมูลแทนก็ได้`,
        color: ``,
        preserveLeadingSpaces: true,
        pageBreak: ``,
        margin: [0, 0, 0, 0],
        bold: false,
        decorationColor: ``,
      },
    ],
    pageOrientation: `portrait`,
  };
}
