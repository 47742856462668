import React, { useState, useMemo, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
// MUI
import Checkbox from "@mui/material/Checkbox";
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

// Icon
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";

// UX
import BottomSheetCenterGroup from "./BottomSheetCenterGroup";
import BottomSheetListItem from "./BottomSheetListItem";
import AirbnbSlider from "./AirbnbSlider";
import ChipListItem from "./ChipListItem";

// register
import DialogReadMore from "bplus-lib/register/DialogReadMore";
import CardListItem from "./CardListItem";

// Interface
import { State } from "../MobSmartAppointmentInterface";

// Const
import { DOCTOR_SCORE_OPTIONS } from "./Constants";

// Types
type BottomSheetFilterProps = {
  open: boolean;
  hideHospital?: boolean;
  hideCenterGroup?: boolean;
  centerGroupList?: (CenterType & { items: CenterType[] })[];
  // callback
  onChange: (state: boolean) => any;
  onConfirm?: (filter: FilterType) => any;
} & Pick<State, "hospitalList">;

type FilterType = {
  hospitals: string[];
  centerGroup?: number | null;
  center?: number;
  appointmentType?: "online" | "on-site";
  experience?: {
    start: number;
    end: number;
  };
  gender?: "M" | "F";
  score?: number;
};

type CenterType = {
  id: number;
  name: string;
  slug: string;
  image: string;
};

// Images
const IMAGES = {
  neurosurgery: "/images/Appointment/neurosurgery.png",
  video_call: "/images/Appointment/video-call-outline-grey.png",
  hospital: "/images/Appointment/hospital-outline-grey.png",
  video_call_active: "/images/Appointment/video-call-outline-blue.png",
  hospital_active: "/images/Appointment/hospital-outline-blue.png",
  female_active: "/images/Appointment/female-outline-blue.png",
  female: "/images/Appointment/female-outline-grey.png",
  male_active: "/images/Appointment/male-outline-blue.png",
  male: "/images/Appointment/male-outline-grey.png",
};

// Styles
const COLORS = {
  primary: "var(--blue-bdms-color)",
  bg: "rgba(1, 71, 163, 0.05)",
  font: "rgba(57, 57, 57, 1)",
  light_blue_02: "rgba(1, 71, 163, 0.2)",
  light_blue_005: "rgba(1, 71, 163, 0.05)",
  half_blue: "rgba(1, 71, 163, 0.7)",
  light_grey: "rgba(121, 120, 120, 1)",
  divider: "rgba(233, 233, 233, 1)",
  light_blue_01: "rgba(1, 71, 163, 0.1)",
  yellow: "rgba(219, 182, 35, 1)",
  chip_bg: "#ECF4FF",
};

const headerStyles = {
  display: "grid",
  gridTemplateColumns: "1fr auto",
  alignItems: "center",
  margin: "16px 0 .875rem",
  "& div[class*=title]": {
    margin: 0,
  },
  "& div:nth-child(2)": {
    textDecorationLine: "underline",
    color: COLORS.primary,
    paddingLeft: "3rem",
    marginBottom: "-3px",
    fontSize: "1rem",
  },
};

const bottomStyles = {
  display: "grid",
  gridTemplateColumns: "50% 50%",
  alignItems: "center",
  borderTop: `1px solid ${COLORS.divider}`,
  margin: "2.5rem -16px 0",
  padding: "1.5rem 16px 1.5rem",
  "& > div:nth-child(1)": {
    textDecorationLine: "underline",
    color: COLORS.primary,
    fontSize: "1rem",
  },
  "& .button-submit-bottom": {
    padding: 0,
    "& button": {
      padding: ".5625rem",
      width: "100%",
    },
  },
};

const seeAllStyles = {
  color: COLORS.primary,
  fontSize: ".875rem",
  fontWeight: "bold",
  marginTop: "0.65rem",
  display: "flex",
  alignItems: "center",
  textDecorationLine: "underline",
  "& svg": {
    margin: "0 0 0 5px",
  },
} as CSSProperties;

const dropdownStyles = {
  "&.ui.selection.dropdown": {
    padding: "14px 16px 14px 16px",
    borderRadius: "8px",
    border: `1px solid ${COLORS.light_blue_02}`,
    backgroundColor: COLORS.light_blue_005,
    color: COLORS.half_blue,
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1rem",
    opacity: 1,
    pointerEvents: "none",
    "& .default": {
      color: COLORS.half_blue,
    },
    "& i": {
      color: COLORS.light_grey,
    },
  },
};

const hospitalStyles = {
  backgroundColor: COLORS.bg,
  borderRadius: "8px",
  padding: "14px 14px 14px",
  "& .label-checkbox": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "-4px",
    "& svg": {
      color: COLORS.primary,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    maxHeight: "87.5vh",
    overflow: "auto",
    margin: "0 -20px",
    padding: "0 16px 16px",
    color: COLORS.font,
  },
  title: {
    fontSize: "1.25rem",
    fontWeight: "bold",
    margin: "16px 0 0.875rem",
  },
  header: headerStyles,
  sub_title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    marginBottom: "0.15rem",
  },
  hospital: hospitalStyles,
  dropdown: dropdownStyles,
  see_all: seeAllStyles,
  divider: {
    borderBottom: `1px solid ${COLORS.divider}`,
    width: "100%",
    margin: "1.25rem 0",
  },
  star: { color: COLORS.yellow, marginLeft: "0.35rem", marginTop: "-2px" },
  bottom: bottomStyles,
}));

const MAX_LENGTH = 4;

const BottomSheetFilter = (props: BottomSheetFilterProps) => {
  const classes = useStyles();

  const [openModCenterGroup, setOpenModCenterGroup] = useState<boolean>(false);
  const [openModCenter, setOpenModCenter] = useState<boolean>(false);
  const [isSeeAll, setIsSeeAll] = useState<boolean>(false);

  const [filter, setFilter] = useState<FilterType>({
    hospitals: [],
    experience: { start: 1, end: 30 },
  });

  // Memo Effect
  const hospitalCodeList = useMemo(() => {
    return (props.hospitalList || []).map((item) => item.code);
  }, [props.hospitalList]);

  // Effect
  useEffect(() => {
    if (!props.hideHospital) {
      setFilter({
        ...filter,
        hospitals: hospitalCodeList,
      });
    }
  }, [hospitalCodeList]);

  // Memo
  const hospitals = useMemo(() => {
    const list = [...(props.hospitalList || [])];

    return isSeeAll ? list : list.slice(0, 4);
  }, [isSeeAll, props.hospitalList]);

  const scoreOptions = useMemo(() => {
    return DOCTOR_SCORE_OPTIONS.map((option) => ({
      ...option,
      icon: <StarRoundedIcon className={classes.star} />,
    }));
  }, []);

  const selectedCenterGroup = useMemo(() => {
    return (props.centerGroupList || []).find(
      (item) => item.id === filter.centerGroup
    );
  }, [filter.centerGroup]);

  const centerGroupOptions = useMemo(() => {
    return (props.centerGroupList || []).map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, [props.centerGroupList]);

  // Handler
  const handleChange = (state: boolean) => {
    props.onChange?.(state);
  };

  const handleOpenModCenterGroup = (e: any) => {
    setOpenModCenterGroup(true);
  };

  const handleCloseModCenterGroup = () => {
    setOpenModCenterGroup(false);
  };

  const handleToggle = () => {
    setIsSeeAll(!isSeeAll);
  };

  const handleCloseModCenter = () => {
    handleSetFilter("centerGroup", null);

    setOpenModCenter(false);
  };

  const handleSelectCenterGroup = (selected: Record<string, any>) => {
    handleSetFilter("centerGroup", selected.id);

    setOpenModCenter(true);
    setOpenModCenterGroup(false);
  };

  const handleSelectCenter = (selected: Record<string, any>) => {
    handleSetFilter("center", selected.id);

    setOpenModCenter(false);
  };

  const handleSetFilter = (key: keyof FilterType, value: any) => {
    handleChangeFilter({ key, value })();
  };

  const handleChangeFilter =
    (data: { key: keyof FilterType; value: any }) => () => {
      const prevValue = filter[data.key];
      let value = data.value;

      if (Array.isArray(prevValue)) {
        value = prevValue.includes(value)
          ? prevValue.filter((prev) => prev !== value)
          : [...prevValue, value];
      }

      setFilter({
        ...filter,
        [data.key]: value,
      });
    };

  const handleToggleHospital = () => {
    setFilter({
      ...filter,
      hospitals: !filter.hospitals.length ? hospitalCodeList : [],
    });
  };

  const handleChangeSelect =
    (key: keyof FilterType) => (selected: Record<string, any>) => {
      handleSetFilter(key, filter[key] === selected.id ? null : selected.id);
    };

  const handleChangeExpirience = (data: any) => {
    handleSetFilter("experience", data);
  };

  const handleClearAll = () => {
    setFilter({
      hospitals: [],
    });
  };

  const handleConfirm = () => {
    props.onConfirm?.(filter);
  };

  console.log("BottomSheetFilter", props, filter);

  return (
    <>
      <DialogReadMore
        open={props.open}
        title="ตัวเลือกค้นหา"
        centerTitle={true}
        // config
        marginTop={0}
        // callback
        onChange={handleChange}
      >
        <div className={classes.screen}>
          {!props.hideHospital && (
            <>
              <div className={classes.header}>
                <div className={classes.title}>
                  โรงพยาบาลและความเชี่ยวชาญของแพทย์
                </div>
                <div aria-hidden="true" onClick={handleToggleHospital}>
                  {!filter.hospitals.length ? "เลือกทั้งหมด" : "ล้าง"}
                </div>
              </div>

              <div className={classes.hospital}>
                <div className={classes.sub_title}>โรงพยาบาล</div>
                {hospitals.map((hospital) => (
                  <div
                    key={"hospital-" + hospital.code}
                    className="label-checkbox"
                  >
                    <label>{hospital.name}</label>
                    <Checkbox
                      checked={filter.hospitals.includes(hospital.code)}
                      onChange={handleChangeFilter({
                        key: "hospitals",
                        value: hospital.code,
                      })}
                    />
                  </div>
                ))}

                {(props.hospitalList || []).length > MAX_LENGTH && (
                  <div
                    aria-hidden="true"
                    className={classes.see_all}
                    onClick={handleToggle}
                  >
                    <label>
                      {isSeeAll ? "แสดงน้อยลง" : "ดูโรงพยาบาลทั้งหมด"}
                    </label>
                    <KeyboardArrowDownRoundedIcon />
                  </div>
                )}

                {!props.hideCenterGroup && (
                  <>
                    <div
                      aria-hidden="true"
                      className={classes.sub_title}
                      style={{ marginTop: "1.25rem" }}
                    >
                      ความเชี่ยวชาญของแพทย์
                    </div>

                    <div
                      aria-hidden="true"
                      onClick={handleOpenModCenterGroup}
                      style={{
                        paddingBottom: filter.centerGroup ? "" : "0.2rem",
                      }}
                    >
                      <Dropdown
                        className={classes.dropdown}
                        selection={true}
                        fluid={true}
                        icon="chevron down"
                        placeholder="เลือกความเชี่ยวชาญของแพทย์"
                        options={centerGroupOptions}
                        value={filter.centerGroup || ""}
                        disabled={true}
                      />
                    </div>

                    {filter.center && (
                      <div style={{ margin: "12px 0 -10px" }}>
                        <CardListItem
                          items={selectedCenterGroup?.items || []}
                          selected={filter.center}
                          hideRightIcon={true}
                          maxContent={3}
                          maxPlaceholder="ดูความเชี่ยวชาญของแพทย์ทั้งหมด"
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </>
          )}

          <div className={classes.title}>ประเภทการนัด</div>
          <CardListItem
            items={APPOINTMENT_TYPES}
            selected={filter.appointmentType}
            // config
            hideRightIcon={true}
            isIcon={true}
            onSelect={handleChangeSelect("appointmentType")}
          />
          <div className={classes.divider}></div>

          <div className={classes.title}>ประสบการณ์แพทย์</div>
          <AirbnbSlider
            start={filter.experience?.start}
            end={filter.experience?.end}
            onChange={handleChangeExpirience}
          />
          <div className={classes.divider}></div>

          {/* <div className={classes.title}>พร้อมให้บริการ</div>
          <ChipListItem items={SERIVICE_TIMES} selected={1} minWidth={110} />
          <div className={classes.divider}></div> */}

          <div className={classes.title}>เพศของแพทย์</div>
          <ChipListItem
            items={GENDERS}
            selected={filter.gender}
            minWidth={150}
            // config
            rightIcon={true}
            // callback
            onSelect={handleChangeSelect("gender")}
          />
          <div className={classes.divider}></div>

          <div className={classes.title}>คะแนนความนิยม</div>
          <ChipListItem
            items={scoreOptions}
            selected={filter.score}
            minWidth={75}
            // callback
            onSelect={handleChangeSelect("score")}
          />

          <div className={classes.bottom}>
            <div aria-hidden="true" onClick={handleClearAll}>
              ล้างทั้งหมด
            </div>
            <div>
              <div className="button-submit-bottom">
                <MuiButton
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                >
                  ยืนยันค้นหา
                </MuiButton>
              </div>
            </div>
          </div>
        </div>
      </DialogReadMore>

      <BottomSheetCenterGroup
        open={openModCenterGroup}
        items={props.centerGroupList || []}
        // callnack
        onChange={handleCloseModCenterGroup}
        onSelect={handleSelectCenterGroup}
      />

      <BottomSheetListItem
        open={openModCenter}
        title={selectedCenterGroup?.name || ""}
        centerTitle={true}
        options={selectedCenterGroup?.items || []}
        // callnack
        onChange={handleCloseModCenter}
        onSelect={handleSelectCenter}
      />
    </>
  );
};

const APPOINTMENT_TYPES = [
  {
    id: "online",
    name: "พบแพทย์ออนไลน์ ",
    image: IMAGES.video_call,
    image_active: IMAGES.video_call_active,
  },
  {
    id: "on-site",
    name: "ใช้บริการที่โรงพยาบาล",
    image: IMAGES.hospital,
    image_active: IMAGES.hospital_active,
  },
];

// -const SERIVICE_TIMES = [
//   { id: 1, value: [30, 60], unit: "นาที", unit_code: "mm" },
//   { id: 2, value: [1, 2], unit: "ชั่วโมง", unit_code: "hh" },
//   { id: 3, value: [2, 4], unit: "ชั่วโมง", unit_code: "hh" },
//   { id: 4, value: [4, 6], unit: "ชั่วโมง", unit_code: "hh" },
// ];

const GENDERS = [
  { id: "M", value: "ชาย", icon: IMAGES.male, icon_active: IMAGES.male_active },
  {
    id: "F",
    value: "หญิง",
    icon: IMAGES.female,
    icon_active: IMAGES.female_active,
  },
];

export default React.memo(BottomSheetFilter);
