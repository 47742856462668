import WasmController from "react-lib/frameworks/WasmController";

import Cookies from "js-cookie";
import axios from "axios";
import { History } from "history";
import moment from "moment";

// APIs
import FastAPIRequestView from "issara-sdk/apis/FastAPIRequestViewM";
// PRX
import SmartUserState from "issara-sdk/apis/SmartUserState_apps_PRX";
// Profile

// Interface
import { State as MainState } from "../MobSmartRegisterInterface";

// Config
import CONFIG from "../../config/config";

export type State = Partial<{
  eKYCStatus?: Record<string, any>;
}>;

type Picked = Pick<
  MainState,
  "loadingStatus" | "errorMessage" | "selectedHospital" | "myProfileDetail"
>;

type OCRType = "ID_CARD" | "PASSPORT";

type SideType = "front" | "back";

type Base64DataType = { front: string; back?: string };

export const StateInitial: State = {};

export type Event =
  | {
    message: "HandleCreateTaskIdCard";
    params: { history: History };
  }
  | {
    message: "HandleFaceResult";
    params: {
      detail: Record<string, any>;
      spoofingcheckImages: Record<string, Blob[]>;
      clearImage: Blob[];
      card: string;
    };
  }
  | {
    message: "HandleCanceleKYC";
    params: { history: any; forceGotoFeed?: boolean };
  }
  | { message: "HandleCompleteScan"; params: {} }
  | {
    message: "HandleThaiIdOCR";
    params: {
      base64: Base64DataType;
      card: string;
      history: any;
      type: OCRType;
      dataUrl: Base64DataType;
      side: SideType;
    };
  };

export type Data = {};

export const DataInitial = {};

/**
 * Manages the configuration settings.
 */
const config = {
  enableStrictMode: false,
  /**
   * @type {string}
   * Setting of authorize.
   */
  /**
   * @type {boolean}
   * enable quality checking
   * Setting of fauth features.
   */
  hasQCCheck: false,
  //debug
  debugmode: false,
};

const KYC_HOST = `/ekyc`; //"https://3.1.96.175:9500";
const ID_CARD_URL = `/rpa/v1/thailand-id-card`;
const PASSPORT_URL = `/rpa/v1`;
// const X_API_KEY = "h5pLmcvDiO7BovdYthahN27fi7H18su97u1LQm7o"

const APIS = {
  GETTOKEN: KYC_HOST + "/api/v1/api_key/auth",
  DELETE: KYC_HOST + "/api/v1/person/delete",
  LIST_PERSONES: KYC_HOST + "/api/v1/person/list",
  //API: Face Auth Web
  CREATE_TASK_FAUTH_REGISTER: KYC_HOST + "/api/v1/fauth-web/task/register/add",
  CREATE_TASK_FAUTH_AUTHENTICATION:
    KYC_HOST + "/api/v1/fauth-web/task/auth/add",
  GET_TASK_FAUTH_INFO: KYC_HOST + "/api/v1/fauth-web/task/info",
  DELETE_TASK_FAUTH: KYC_HOST + "/api/v1/fauth-web/task/delete",
  REGISTER_TASK_FAUTH: KYC_HOST + "/api/v1/fauth-web/task/register",
  SPOOFINGCHECK_ONE_STAGE:
    KYC_HOST + "/api/v1/anti_spoofing/spoofingcheck_one_stage",
  ADD_PERSON: KYC_HOST + "/api/v1/person/add",
  IMAGE_SEARCH_PERSON: KYC_HOST + "/api/v1/person/search/image",
  LIST_GROUPS: KYC_HOST + "/api/v1/group/list",
  ADD_GROUP: KYC_HOST + "/api/v1/group/add",
  DELETE_PERSON: KYC_HOST + "/api/v1/person/delete",
  SPOOFINGCHECK_TWO_STAGE:
    KYC_HOST + "/api/v1/anti_spoofing/spoofingcheck_two_stage",
  OCR_IDCARD: KYC_HOST + "/api/v1/idcard/ocr",
};

const OCR_KEYS = {
  id_number: "NUMBER",
  mrz_passport_number: "NUMBER",
  full_name_th: "NAME",
  title_first_name_en: "FIRST_NAME_EN",
  mrz_given_name: "FIRST_NAME_EN",
  middle_last_name_en: "LAST_NAME_EN",
  mrz_surname: "LAST_NAME_EN",
  date_of_birth_en: "BIRTH_DATE_EN",
  mrz_date_of_birth: "BIRTH_DATE_EN", // dd mmm yyy
  address_th: "ADDRESS",
  mrz_nationality: "NATION",
  mrz_sex: "SEX",
};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

type Params<A extends Event["message"]> = Extract<
  Event,
  { message: A }
>["params"];

export const HandleCreateTaskIdCard: Handler<
  Params<"HandleCreateTaskIdCard">
> = async (controller, params) => {
  const _taskID = localStorage.getItem("ekyc_taskUuid") || "";

  if (_taskID) {
    await removeTask(_taskID);

    localStorage.removeItem("personName");
    localStorage.removeItem("personEmail");
  }

  await signin();

  // -const personEmail = `${Date.now()}@thevcgroup.com`;
  // -const personName = "name";

  // -await listPerson({ email: personEmail });

  // -createTask({ email: personEmail, name: personName, history: params.history });
};

export const HandleFaceResult: Handler<Params<"HandleFaceResult">> = async (
  controller,
  params
) => {
  const state = controller.getState();
  const { detail, spoofingcheckImages, clearImage } = params;

  if (state.loadingStatus?.[params.card]) {
    return;
  }

  const tooMany = state.eKYCStatus?.ekyc_too_many;

  if (tooMany) {
    return controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: { status: "exceeded" },
      },
    });
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  // -let frontFace: Blob | null = clearImage[clearImage.length - 1];
  // -passImage(frontFace, 0);
  // -localStorage.setItem("facedetect_state", "antispoofing_progressing");
  // -passImage(frontFace, 0);
  // -localStorage.setItem("facedetect_state", "antispoofing_progressing");

  try {
    const response = await PostAntiSpoofing(
      detail,
      spoofingcheckImages,
      clearImage[0]
    );

    const [status] = await PatchStatuseKYC(controller, {
      is_verified: response.status.includes("success"),
      data: {
        similarity: response.similarity,
      },
      status: response.status,
    });

    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: response,
      },
      eKYCStatus: status,
    });
  } catch (e) {
    console.log("error", e);
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });
};

export const HandleThaiIdOCR: Handler<Params<"HandleThaiIdOCR">> = async (
  controller,
  params
) => {
  const state = controller.getState();

  if (state.loadingStatus?.[params.card]) {
    return;
  }

  const tooMany = state.eKYCStatus?.ekyc_too_many;

  if (tooMany) {
    return controller.setState({
      errorMessage: { ...state.errorMessage, [params.card]: "exceeded" },
    });
  }

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: true },
  });

  const ocrForm = await formatedOCRForm(controller, params);

  const { front: object } = ocrForm;

  const icd = object.NUMBER?.replace(/ /g, "");
  const profileCid = state.myProfileDetail?.cid || "";
  // -const laserCode = back?.laser_code || "";
  // -const isLaserCode = laserCode.search(/\w{2}\d-\d{7}-\d{2}/) >= 0;
  // -const toCompare = params.type === "ID_CARD" ? isLaserCode : true;

  let message = "failed";

  if (icd) {
    message = icd === profileCid ? "success" : "failed";
  }

  const label = Object.entries(object).map(
    ([key, value]) => `\n${key}: ${value}`
  );

  // -const laserCodeMsg =
  //   params.type === "ID_CARD" ? `\nLaser code: ${laserCode}` : "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [params.card]: false },
  });

  if (message === "failed") {
    // -uploadImage(controller, { base64: params.base64.front, profileCid });

    controller.setState({
      errorMessage: {
        ...state.errorMessage,
        [params.card]: `\nfailed\nCID: ${profileCid}${label}\nStatus: ${ocrForm.message?.status ?? "-"
          }`,
      },
    });

    const [status] = await PatchStatuseKYC(controller, {
      status: "failed",
    });

    controller.setState({
      eKYCStatus: status,
    });
  }

  if (message === "success") {
    controller.handleEvent({
      message: "HandleHistoryPushState",
      params: {
        history: params.history,
        pathname: `/kyc-step/face-init/`,
        replace: false,
        state: {
          idCardBase64: params.base64.front,
          ocrCardResult: { ...object, DOC_TYPE: params.type },
        },
      },
    } as any);
  }
};

const capitalizedText = (text: string) => {
  return text
    ? " " + text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    : "";
};

const formatedOCRForm: Handler<{
  base64: Base64DataType;
  type: OCRType;
  dataUrl: Base64DataType;
}> = async (controller, params) => {
  if (params.type === "PASSPORT") {
    const rotatedBase64 = await rotateImageAsync(params.dataUrl.front, -90);
    // const croppedBase64 = await cropImageToBase64(rotatedBase64)
    params.base64.front = rotatedBase64?.replace(
      /data:image\/jpeg;base64,/g,
      ""
    );
  }

  // -const token = await RequestTokenForm(params.type);
  const promiseArr = Object.entries(params.base64).map(([key, value]) => {
    if (value) {
      return PostOCRForm(controller, {
        base64: value,
        type: params.type === "PASSPORT" ? "passport" : (key as SideType),
      });
    }

    return Promise.resolve(null);
  });

  const [front, back] = await Promise.all(promiseArr);

  const object = Object.entries(front?.result || {}).reduce(
    (result, [key, value]: any) => {
      const mapKey = (OCR_KEYS as any)[key];

      if (mapKey) {
        result[mapKey] = value;
      }

      return result;
    },
    {} as any
  );

  if (params.type === "ID_CARD") {
    object.NATION = "THA";
    object.FIRST_NAME_EN = object.FIRST_NAME_EN?.toUpperCase();
    object.LAST_NAME_EN = object.LAST_NAME_EN?.toUpperCase();
  } else if (params.type === "PASSPORT") {
    let formattedBirthDate = "";

    if (object.BIRTH_DATE_EN) {
      formattedBirthDate = moment(
        object.BIRTH_DATE_EN,
        object.BIRTH_DATE_EN.length === 6 ? "YYMMDD" : ""
      )
        .locale("en")
        .format("DD MMM YYYY");
    }

    object.BIRTH_DATE_EN = formattedBirthDate;
    object.FIRST_NAME_EN = capitalizedText(object.FIRST_NAME_EN);
    object.LAST_NAME_EN = capitalizedText(object.LAST_NAME_EN).trim();
  }

  return {
    front: object || {},
    back: back?.result || {},
    message: front,
  };
};

const PostAntiSpoofing = async (
  detail: any,
  spoofingcheckImages: any,
  faceImage: any
) => {
  const checkOneStage = await PostSpoofingCheckStage(
    detail,
    spoofingcheckImages,
    "ONE"
  );
  const livenessMessage = getLivenessMessage(checkOneStage);

  if (livenessMessage) {
    return {
      status: "failed",
      similarity: 0,
      message: livenessMessage,
    };
  }

  const groupUuid = await createOrGetGroupUuid();
  const addPersonRes = await PostAddPerson(faceImage, groupUuid);
  const { personUuid, errorDetail } = getPersonUuid(addPersonRes);

  const response = await searchAndDeletePerson(groupUuid, personUuid);
  const personUuidList = (response?.results || []).flatMap((item: any) =>
    item.results.filter((acc: any) => acc.similarity >= 0)
  );
  const person = personUuidList?.find(
    (item: any) => item.personUuid === personUuid
  );
  const similarity = person?.similarity || 0;

  const status = calculateStatus(checkOneStage, similarity);

  return {
    status,
    similarity: status === "failed" ? 0 : similarity,
    message: `Similarity: ${similarity}\nLiveness score: ${checkOneStage.livenessScore}\n${errorDetail}`,
  };
};

const signin = async () => {
  const response = await login();

  if (response && !response.statusCode) {
    cacheToken(response.token);
  }
};

const getLivenessMessage = (checkOneStage: any) => {
  if (!checkOneStage?.livenessScore || checkOneStage?.result === "spoofing") {
    const message = checkOneStage.errorMessage
      ? `Error: ${checkOneStage.errorMessage}`
      : `result: ${checkOneStage.result}`;

    return `Liveness score: ${checkOneStage?.livenessScore || 0
      }\n${message}\nStatus code: ${checkOneStage.status}`;
  }

  return null;
};

const getGroupUuid = async () => {
  const listGroups = await GetListGroups();
  const group = (listGroups.results || []).find(
    (item: any) => item.name === "Bplus"
  );

  return group?.groupUuid;
};

const createOrGetGroupUuid = async () => {
  let groupUuid = await getGroupUuid();

  if (!groupUuid) {
    const response = await PostAddGroup();
    groupUuid = response.groupUuid;
  }

  return groupUuid;
};

const getPersonUuid = (addPersonRes: any) => {
  if (!addPersonRes?.personUuid) {
    const mapKey = ([key, value]: any) =>
      typeof value === "boolean" && value ? [`${key}: ${value}`] : [];

    const detail = Object.entries(addPersonRes.detail || {}).flatMap(mapKey);
    const occlusionDetail = Object.entries(
      addPersonRes.detail?.occlusionDetail || {}
    ).flatMap(mapKey);
    const errorDetail = [...detail, ...occlusionDetail].join("\n");

    return { errorDetail };
  }

  return { personUuid: addPersonRes.personUuid, errorDetail: "" };
};

const searchAndDeletePerson = async (
  groupUuid: string,
  personUuid: string | undefined
) => {
  const response = await ImageSearchPerson(groupUuid);

  if (personUuid) {
    PostDeletePerson(personUuid);
  }

  return response;
};

const calculateStatus = (
  checkOneStage: any,
  similarity: number | undefined
) => {
  if (
    checkOneStage.livenessScore >= 0.4 &&
    !!similarity &&
    similarity >= 0.75
  ) {
    return similarity >= 0.9 ? "success" : "partial_success";
  }

  return "failed";
};

const login = async () => {
  const token =
    Cookies.get("apiToken") || "1091e585ee719dc0fb2482198b77323cf551bcf5";

  try {
    const result = await axios({
      method: "get",
      url: `${CONFIG.API_HOST}/users/apis/ekyc/get-token/`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": `application/x-www-form-urlencoded`,
      },
    });

    return result.data;
  } catch (e: any) {
    console.log("[API error] login", e.response.data);

    if (e.response?.data?.statusCode === 401) {
      onUseInvalidToken();

      if (!e.response.data.statusCode)
        return {
          statusCode: e.response.status,
          errorMessage: e.response.statusText,
        };
    }

    return e.response ? e.response.data : null;
  }
};

const createTask = async (params: any) => {
  const antiSpoofingType = localStorage.getItem("antiSpoofingType");
  const isQCCheck = hasQCCheck();
  let antiSpoofing = "";

  if (antiSpoofingType === "TWO_STAGES_WITH_RANDOM_ACTIONS") {
    antiSpoofing = "TURN_HEAD_2D";
  } else {
    antiSpoofing = "PASSIVE_2D";
  }

  const taskParams = new URLSearchParams();
  const url = window.location.href;

  taskParams.append("personName", params.name);
  taskParams.append(
    "personNote",
    `{"faceme_websample":{"email":"${params.email}"}}`
  );
  taskParams.append("antiSpoofing", antiSpoofing);
  taskParams.append("idDocOption", "GENERAL_ID");

  taskParams.append("isQualityCheck", `${isQCCheck}`);
  taskParams.append("isEnrollFace", "true");
  taskParams.append("isSkipImage", "false");
  taskParams.append("redirectUrl", url);

  const result = await createFAuthTask("ekyc", taskParams);

  if (!result || result.statusCode) {
    // -callback(result);
    return;
  }

  const debugmode = localStorage.getItem("debugEmbedRedirect");

  if (debugmode === "true") {
    console.log(result.taskUuid);
    console.log(result.token);
  }

  localStorage.setItem("personName", params.name);
  localStorage.setItem("personEmail", params.email);
  localStorage.setItem("ekyc_taskUuid", result.taskUuid);
  localStorage.setItem("backURL", url);

  // -params.history.replace(
  //   "/kyc-step/id-card/?app=MobRegister&" +
  //     result.pageUrl.replace(/\/fauth-page\?/, "")
  // );

  initTask();
};

const initTask = async () => {
  const _taskID = localStorage.getItem("ekyc_taskUuid"); //keep the information before the task running

  checkAntispoofingSetting();
  checkIDDocument();

  if (_taskID) {
    const taskParams = new URLSearchParams();
    taskParams.append("taskUuid", _taskID);

    const initParams = new URLSearchParams();
    initParams.append("client", "CONSOLE");
    initParams.append("versionCode", "1");

    const params = new URLSearchParams(window.location.search);
    const t = params.get("t") || "";

    await axios({
      method: "post",
      url: "/api/v1/init",
      data: initParams,
      headers: {
        "Content-Type": `application/x-www-form-urlencoded`,
      },
    });

    await axios({
      method: "post",
      url: "/api/v1/fauth-web/task/init",
      headers: {
        Authorization: t,
        "Content-Type": `application/x-www-form-urlencoded`,
      },
    });

    // -getFAuthTaskInfo("ekyc", taskParams).then(
    //   (response: {
    //     taskConfig: any;
    //     statusCode: any;
    //     errorMessage: any;
    //     result: any;
    //   }) => { }
    // );
  } else {
    localStorage.removeItem("personName");
    localStorage.removeItem("personEmail");
  }
};

export const HandleCanceleKYC: Handler = (controller, params) => {
  const locState = params.history.location.state || {};
  const appointment = locState?.appointment;

  if (appointment && !params.forceGotoFeed) {
    const routes: string[] = locState.routes || [];

    const index = routes
      .reverse()
      .findIndex((route) => route === "/confirm-appointment");

    if (index !== -1) {
      params.history.go(-(index + 1));
    } else {
      params.history.push({
        ...appointment,
        state: JSON.parse(appointment.state).locationState,
      });
    }

    setTimeout(() => globalThis.history.go(), 10);
  } else if (window.mobile?.goToMainScreen) {
    window.mobile?.goToMainScreen();
  } else {
    globalThis.window.location.href = "/?app=MobFeed";
  }
};

export const HandleCompleteScan: Handler = async (controller, params) => {
  const state = controller.getState();
  const appointment = params.history.location.state?.appointment;

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [`${params.card}_SUCCESS`]: true,
    },
  });

  // -await PatchStatuseKYC(controller, {
  //   is_verified: true,
  //   status: "success",
  //   data: { similarity: 0.95 },
  // });

  if (appointment) {
    const [userState] = await SmartUserState.retrieve({
      apiToken: controller.cookies.get("apiToken"),
    });

    if (!userState.has_profile) {
      controller.handleEvent({
        message: "HandleHistoryPushState",
        params: {
          history: params.history,
          pathname: `/kyc-step/edit-profile/`,
          replace: false,
        },
      } as any);
    } else {
      controller.handleEvent({
        message: "HandleCheckMakeAppointment" as any,
        params,
      });
    }
  }
  //  else if (false) {
  //   // บันทึก profile เรียบร้อย
  //   const hasHn = userState.hospital_profiles.find(
  //     (profile: any) => profile.hospital_code === state.selectedHospital?.code
  //   )?.has_hn;

  //   // ผูก HN แล้ว
  //   if (hasHn) {
  //     controller.handleEvent({
  //       message: "HandleCheckConsentHospitalForm" as any,
  //       params: { code: state.selectedHospital.code },
  //     });
  //   } else {
  //     // ยังไม่ผูก HN
  //     params.history.push("/register-hn/?app=MobRegister");
  //   }
  // }
  else {
    const { idCardBase64, ...state } = params.history.location.state;

    // สมัครใหม่
    params.history.push({
      pathname: `/kyc-step/edit-profile/`,
      search: `app=MobRegister`,
      state,
    });
  }

  const { [params.card]: key, ...error } = state.errorMessage || {};

  controller.setState({
    loadingStatus: {
      ...state.loadingStatus,
      [`${params.card}_SUCCESS`]: false,
    },
    errorMessage: { ...error },
  });
};

/* ------------------------------------------------------ */

/*                           APIs                          */

/* ------------------------------------------------------ */

/*                           KYC                          */
const deleteFAuthTask = async (data: any) => {
  const [res] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS.DELETE_TASK_FAUTH,
    data: data,
  });

  if (res?.statusCode === 401) onUseInvalidToken();

  return res;
};

//API: Face Auth Web
async function createFAuthTask(type: string, data: any) {
  const [res] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint:
      type === "ekyc"
        ? APIS.CREATE_TASK_FAUTH_REGISTER
        : APIS.CREATE_TASK_FAUTH_AUTHENTICATION,
    data: data,
  });

  if (res?.statusCode === 401) {
    onUseInvalidToken();
  }

  return res;
}

// -async function getFAuthTaskInfo(type: string, data: any) {
//   const [res] = await FastAPIRequestView.post({
//     token: getToken(),
//     endpoint: APIS.GET_TASK_FAUTH_INFO,
//     data: data,
//   });
//   if (res?.statusCode === 401) {
//     onUseInvalidToken();
//   }
//   return res;
// }

// -const listPerson = async (params: any) => {
//   const taskParams = new URLSearchParams();
//   taskParams.append(
//     "search",
//     `{"faceme_websample":{"email":"${params.email}"}}`
//   );
//   taskParams.append("searchScope", `NOTE`);
//   taskParams.append("isExactMatch", `${true}`);
//   try {
//     return await axios({
//       method: "post",
//       url: APIS.LIST_PERSONES,
//       data: taskParams,
//       headers: {
//         Authorization: getToken(),
//         "Content-Type": `application/x-www-form-urlencoded`,
//       },
//     });
//   } catch (error) {
//     return null;
//   }
// };

const PostSpoofingCheckStage = async (
  detail: any,
  spoofingcheckImages: any,
  stage: "ONE" | "TWO"
) => {
  let formData = new FormData();

  for (const key in spoofingcheckImages) {
    for (const blob of spoofingcheckImages[key]) {
      const file = new File([blob], `image.jpg`, {
        type: blob.type,
      });
      formData.append(key, file);
    }
  }

  formData.append("features", JSON.stringify({ enableStrictMode: false }));
  formData.append("detail", JSON.stringify(detail));

  const [res, _, net] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS[`SPOOFINGCHECK_${stage}_STAGE`],
    data: formData,
  });

  return { ...(res || {}), status: net.status };
};

const PostAddPerson = async (faceImage: any, groupUuid: string) => {
  let formData = new FormData();
  const email = `mail.${Date.now()}@hotmail.com`;

  formData.append("name", "name");
  formData.append("note", JSON.stringify({ faceme_websample: { email } }));
  formData.append("skipQC", `false`);
  formData.append("groupUuid", groupUuid);

  const file = new File([faceImage], "image.jpg", {
    type: faceImage.type,
  });

  formData.append("image", file);

  const [res, err] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS.ADD_PERSON,
    data: formData,
  });

  return !res ? err?.detail : res;
};

const ImageSearchPerson = async (groupUuid: string) => {
  let formData = new FormData();

  formData.append(
    "image",
    base64ToFile(window.history.state.state.idCardBase64)
  );
  formData.append("returnCount", `10`);
  formData.append("threshold", `1e-6`);
  formData.append("groupUuid", groupUuid);

  const [res] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS.IMAGE_SEARCH_PERSON,
    data: formData,
  });

  return res;
};

const PostDeletePerson = async (personUuid: string) => {
  const searchParams = new URLSearchParams();
  searchParams.append("personUuid", personUuid);

  const [res] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS.DELETE_PERSON,
    data: searchParams,
  });

  return res;
};

const GetListGroups = async () => {
  const searchParams = new URLSearchParams();
  searchParams.append("listAll", "true");

  const [res] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS.LIST_GROUPS,
    data: searchParams,
  });

  return res;
};

const PostAddGroup = async () => {
  const searchParams = new URLSearchParams();
  searchParams.append("name", "Bplus");

  const [res] = await FastAPIRequestView.post({
    token: getToken(),
    endpoint: APIS.ADD_GROUP,
    data: searchParams,
  });

  return res;
};

export const PatchStatuseKYC: Handler = async (controller, params) => {
  let status = { data: null };
  let userStatus = null;

  try {
    status = await axios({
      method: "patch",
      url: `${CONFIG.API_HOST}/users/apis/ekyc/status/`,
      data: params,
      headers: {
        Authorization: `Token ${Cookies.get("apiToken")}`,
      },
    });

    [userStatus] = await SmartUserState.retrieve({
      apiToken: controller.cookies.get("apiToken"),
    });
  } catch (error) { }

  return [{ ...(status.data || {}), ekyc_too_many: userStatus?.ekyc_too_many }];
};
const uploadImage: Handler<{ base64: string; profileCid: string }> = async (
  controller,
  params
) => {
  let formData = new FormData();

  const name = `kyc-fail/${params.profileCid}-${moment().toISOString()}.jpg`;

  formData.append("file", base64ToFile(params.base64, name));

  return FastAPIRequestView.post({
    bearer: controller.cookies.get("bearer"),
    endpoint: `/api/digito/submit/`,
    data: formData,
  });
};

/*                           OCR                          */

const PostOCRForm: Handler<{
  base64: string;
  type: "passport" | SideType;
}> = async (controller, params) => {
  let formData = new FormData();

  formData.append("image", base64ToFile(params.base64));

  const [res, _, net] = await FastAPIRequestView.post({
    bearer: controller.cookies.get("bearer"),
    endpoint: `${params.type === "passport" ? PASSPORT_URL : ID_CARD_URL}/${params.type
      }`,
    data: formData,
  });

  return { ...(res || {}), status: net.status };
};

const RequestTokenForm = async (type: OCRType) => {
  try {
    const response = await axios({
      method: "post",
      url: `${type === "ID_CARD" ? ID_CARD_URL : PASSPORT_URL}/requestToken`,
      params: { apiPsw: "" },
    });

    return response.data;
  } catch (error: any) {
    return error.response?.data;
  }
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const onUseInvalidToken = () => {
  clearToken();

  localStorage.setItem("reload_apikey", "true");
};

const cacheToken = (token: any) => {
  localStorage.setItem("facemedemosystem_token", encode(token));
};

const encode = (s: string) => {
  try {
    return btoa("" + s).replace(/(.{5})/g, "$1-");
  } catch (e) {
    return s;
  }
};

const clearToken = () => {
  localStorage.removeItem("facemedemosystem_token");
};

const hasQCCheck = () => {
  return config.hasQCCheck;
};

export const getToken = () => {
  return decode(localStorage.getItem("facemedemosystem_token"));
};

const decode = (k: any) => {
  if (!k) {
    return "";
  }
  try {
    return atob(k.replace(/-/g, ""));
  } catch (e) {
    return k;
  }
};

function checkAntispoofingSetting() {
  let antiSpoofingType = localStorage.getItem("antiSpoofingType");
  let precisionLevel = localStorage.getItem("precisionLevel");
  let raNum = localStorage.getItem("raNum");
  let raNodEnable = localStorage.getItem("raNodEnable");
  let raSmileEnable = localStorage.getItem("raSmileEnable");

  if (antiSpoofingType === null) {
    localStorage.setItem("antiSpoofingType", "SINGLE_STAGE");
  }
  if (precisionLevel === null) {
    localStorage.setItem("precisionLevel", "standard");
  }
  if (raNum === null) {
    localStorage.setItem("raNum", "1");
  }
  if (raNodEnable === null) {
    localStorage.setItem("raNodEnable", "false");
  }
  if (raSmileEnable === null) {
    localStorage.setItem("raSmileEnable", "false");
  }
}

//ID Card
function checkIDDocument() {
  let idDocType = localStorage.getItem("idDocType");
  let raOCREnable = localStorage.getItem("raOCREnable");
  let raIDCardWithASEnable = localStorage.getItem("raIDCardWithASEnable");

  if (idDocType === null) {
    localStorage.setItem("idDocType", "GENERALID");
  }
  if (raOCREnable === null) {
    localStorage.setItem("raOCREnable", "true");
  }
  if (raIDCardWithASEnable === null) {
    localStorage.setItem("raIDCardWithASEnable", "true");
  }
}

const base64ToFile = (base64String: string, name?: string) => {
  // Decode the Base64 string to binary data
  const binaryData = atob(base64String);

  // Convert the binary data to a Uint8Array
  const uint8Array = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob from the Uint8Array
  const blob = new Blob([uint8Array]);

  // Create a new File object from the Blob
  return new File([blob], name || "image.jpg", { type: blob.type });
};

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = (reader.result as any).split(",")[1];
      resolve(base64String);
    };

    reader.onerror = (error) => {
      reject(error);
    };

    reader.readAsDataURL(blob);
  });
};

const passImage = (data: any, i: any) => {
  let urlCreator = window.URL || window.webkitURL;
  const img = document.querySelector("#capture_face") as HTMLImageElement;

  if (img) {
    img.src = urlCreator.createObjectURL(data);
  }

  localStorage.setItem("cameraoriginsrc", urlCreator.createObjectURL(data));
};

const removeTask = async (taskUuID: string) => {
  localStorage.removeItem("auth_taskUuid");
  localStorage.removeItem("auth_token");

  const taskParams = new URLSearchParams();
  taskParams.append("taskUuid", taskUuID);

  await deleteFAuthTask(taskParams);
};

async function rotateImageAsync(base64Image: string, degrees: number) {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // Set the canvas dimensions to fit the rotated image
      canvas.width = img.height;
      canvas.height = img.width;

      // Rotate the image on the canvas
      ctx?.translate(canvas.width / 2, canvas.height / 2);
      ctx?.rotate((degrees * Math.PI) / 180);
      ctx?.drawImage(img, -img.width / 2, -img.height / 2);

      // Convert the rotated canvas to Base64
      const rotatedBase64 = canvas.toDataURL("image/jpeg");

      resolve(rotatedBase64);
    };

    img.onerror = (error) => {
      reject(error);
    };

    // Set the source of the image to your Base64 data
    img.src = base64Image;
  });
}

async function cropImageToBase64(imageUrl: string) {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      const sourceX = 10; // Left crop of 5px
      const sourceY = img.height * 0.15; // Top crop of 15%
      const sourceWidth = img.width - 20; // Width after cropping 5px from each side
      const sourceHeight = img.height * 0.7; // Height after cropping 15% from top and bottom

      canvas.width = sourceWidth;
      canvas.height = sourceHeight;

      context?.drawImage(
        img,
        sourceX,
        sourceY,
        sourceWidth,
        sourceHeight,
        0,
        0,
        sourceWidth,
        sourceHeight
      );

      // Convert the cropped image on the canvas to a base64 string
      const base64Image = canvas.toDataURL("image/jpeg"); // You can use other formats like "image/png" if needed

      resolve(base64Image);
    };

    img.onerror = (error) => {
      reject(error);
    };

    img.src = imageUrl;
  });
}
