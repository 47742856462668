import React, { useState, useMemo } from "react";
import { Dropdown } from "semantic-ui-react";

// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";

import { useHistory } from "react-router";

// UX
import BottomSheetSelectBox from "./BottomSheetSelectBox";
import { titleStyles, dropdownStyles } from "./CancelAppointment";

// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";
// telephar
import DialogConfirm from "bplus-lib/telephar/DialogConfirm";

// Const.
import { CANCEL_REASON_OPTIONS } from "./Constants";

// Images
const IMAGES = {
  check: "/images/register/check-green.png",
};

// Styles
const COLORS = {
  normal: "rgba(121, 120, 120, 1)",
  grey: "rgba(57, 57, 57, 1)",
};

const useStyles = makeStyles((theme) => ({
  screen: {
    padding: "24px 16px 16px",
    display: "flex",
    flexDirection: "column",
    color: COLORS.normal,
    minHeight: "calc(100vh - 50px)",
  },
  dropdown: dropdownStyles,
  title: titleStyles,
}));

const SubmitRequestRefund = () => {
  const classes = useStyles();
  const history = useHistory();

  const [selectedReason, setSelectedReason] = useState<any>(null);
  // open mod
  const [openModRefundReason, setOpenModRefundReason] =
    useState<boolean>(false);
  const [openModSuccess, setOpenModSuccess] = useState<boolean>(false);

  // Memo
  const reasonOptions = useMemo(() => {
    return CANCEL_REASON_OPTIONS.map((item) => ({
      ...item,
      key: item.id,
      value: item.id,
      text: item.name,
    }));
  }, []);

  // Handler
  const handleOpenModCancelReason = () => {
    setOpenModRefundReason(true);
  };

  const handleGoback = () => {
    history.goBack();
  };

  const handleSelectReason = (selected: Record<string, any>) => {
    setSelectedReason(selected);
    setOpenModRefundReason(false);
  };

  const handleConfirm = () => {
    setOpenModSuccess(true);
  };

  const handleCloseModSuccess = () => {
    setOpenModSuccess(false);

    // -history.go(-2);
  };

  return (
    <>
      <HeaderBar
        onEvent={() => {}}
        // data
        // history={this.props.history}
        setTitle="ส่งคำร้องเพื่อติดต่อเจ้าหน้าที่"
        whiteTheme={true}
        // config
        hiddenLeft={true}
        // callback
        rightIconClick={handleGoback}
      />

      <div className={classes.screen}>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.title}>คำขอคืนเงิน</div>

          <div>
            คำขอคืนเงินจะถูกพิจารณาจากทางโรงพยาบาลถ้าอนุมัติ
            นัดหมายของท่านจะถูกยกเลิกอัตโนมัติ ระบบจะคืนเงิน ให้ท่านเต็มจำนวน
            ถ้าปฏิเสธนัดหมายของท่านถูกยกเลิก อัตโนมัติและไม่สามารถคืนเงินได้
          </div>

          <div className={classes.dropdown}>
            <label className="required">เหตุผลในการขอคืนเงิน</label>

            <div aria-hidden="true" onClick={handleOpenModCancelReason}>
              <Dropdown
                selection={true}
                fluid={true}
                icon="chevron down"
                placeholder="ระบุเหตุผล"
                value={selectedReason?.id || ""}
                options={reasonOptions}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!selectedReason}
              onClick={handleConfirm}
            >
              ส่งคำขอคืนเงิน
            </MuiButton>
          </div>
        </div>
      </div>

      <BottomSheetSelectBox
        open={openModRefundReason}
        title="เหตุผลในการขอคืนเงิน"
        options={CANCEL_REASON_OPTIONS}
        selected={selectedReason}
        // config
        confirmSelect={true}
        // callback
        onChange={setOpenModRefundReason}
        onSelect={handleSelectReason}
      />

      <DialogConfirm
        open={openModSuccess}
        title={"ส่งคำขอคืนเงินสำเร็จ"}
        description={"ท่านทำการส่งคำขอคืนเงิน\nเสร็จเรียบร้อยแล้ว"}
        approveText="เสร็จสิ้น"
        img={IMAGES.check}
        imgStyle={{ width: "4rem", margin: "-0.75rem 0 1.25rem" }}
        // config
        hideDeny={true}
        onApprove={handleCloseModSuccess}
      />
    </>
  );
};

export default React.memo(SubmitRequestRefund);
