import React, { useEffect, useMemo } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Avatar from "@mui/material/Avatar";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import SeeMoreMedication from "./SeeMoreMedication";
import SeeMoreCurrentMedication from "./SeeMoreCurrentMedication";
import SeeMoreLab from "./SeeMoreLab";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import { Button, Grid, Icon, Image } from "semantic-ui-react";
import moment from "moment";

const COLOR = {
  primary: "var(--primary-theme-color)",
  font_white: "white",
  superligtblue: "#CCDAED",
  backgroundbutton: "#F5F5F5",
  font_black: "#393939",
  DarkGray_Line: "#9F9F9F",
  BlueBDMS: "#0147A3",
  superdarkgray: "#393939",
  darkgrayHos: "#797878",
};

const styles = {
  card: {
    width: "100%",
    height: "120px",
    borderRadius: "10px",
    padding: "15px",
  } as CSSProperties,
  tabs: {
    width: "50%",
    display: "flex",
    justifyContent: "center",
    fontWeight: "bold",
  } as CSSProperties,
  rateus: {
    display: "flex",
    background: `linear-gradient(180deg, #0147A3 20%, #0B87CD 100%)`,
    padding: "5px",
    borderRadius: "10px",
    alignItems: "center",
  } as CSSProperties,
  text_DarkGray_14: {
    minHeight: "20px",
    width: "95%",
    fontSize: "14px",
    fontWeight: "bold",
    color: COLOR.DarkGray_Line,
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_whire_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.font_white,
    textOverflow: "ellipsis",
  } as CSSProperties,
  text_bluebdms_16: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    wordBreak: "break-word",
    // textOverflow: "ellipsis",
    // overflow: "hidden",
    // display: "-webkit-box",
    // "-webkit-line-clamp": "1",
    // WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_bluebdms_16_noellipsis: {
    minHeight: "20px",
    fontSize: "16px",
    fontWeight: "bold",
    color: COLOR.BlueBDMS,
    textOverflow: "ellipsis",
  } as CSSProperties,
  text_superdarkgray_14: {
    fontSize: "14px",
    color: COLOR.superdarkgray,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    WebkitBoxOrient: "vertical",
  } as CSSProperties,
  text_superdarkgray_Indent50: {
    fontSize: "14px",
    color: COLOR.superdarkgray,
    textIndent: "50px",
  } as CSSProperties,
};

type CardDoctorProps = {
  medicalRecord?: any;
  language?: "en-US" | "th-TH";
  selectedHospital?: any;
  handleOpenDrugDetail: (item: any) => any;
  currentMedication: any[];
  isCurrentMed: boolean;
  onEvent: (e: any) => any;
};

const CardDoctor: React.FunctionComponent<CardDoctorProps> = (props) => {
  const [expanded, setExpanded] = React.useState<string>("");
  const [medicalRecord, setMedicalRecord] = React.useState<any>(null);
  let history = useHistory();

  useEffect(() => {
    if (props.medicalRecord.length > 0) {
      /////////////////////////////////
      // group CurrentMed to array  //
      ///////////////////////////////

      const groupCurrentMed = props.currentMedication.reduce((group, item) => {
        const drug = item[0];
        group[drug] = group[drug] ?? [];
        group[drug].push(item);
        return group;
      }, {});

      const groupArrays = Object.keys(groupCurrentMed).map((drug) => {
        const drugName = drug;
        return {
          drugName,
          item: groupCurrentMed[drug],
        };
      });

      /////////////////////////////////
      // insert state to medication //
      ///////////////////////////////

      const medicalRecord = props.medicalRecord.map(
        (medicalRecordItems: any) => {
          let newDrug_order: any = [];
          let drug_state: "";
          if (medicalRecordItems.drug_order.length > 0) {
            newDrug_order = medicalRecordItems.drug_order.map(
              (drugOrderItems: any) => {
                drug_state = "";
                groupArrays.forEach((groupArraysItem: any) => {
                  if (groupArraysItem.drugName === drugOrderItems.id) {
                    const stateDrug = groupArraysItem.item
                      .map((item: any) => {
                        if (
                          item.includes(drugOrderItems.encounter_number) &&
                          item.includes(drugOrderItems.id)
                        ) {
                          return { state: item[2] };
                        }
                      })
                      .filter((item: any) => item !== undefined);
                    if (stateDrug.length > 0 && stateDrug[0] !== undefined) {
                      drug_state = stateDrug[0].state;
                    }
                  }
                });
                return { ...drugOrderItems, state: drug_state };
              }
            );
          }
          return { ...medicalRecordItems, drug_order: newDrug_order || [] };
        }
      );

      const medicalRecordByDoctor = medicalRecord.map(
        (medicalRecordItem: any) => {
          const consultDoctors = medicalRecordItem.consult_doctors;
          if (
            consultDoctors.filter(
              (item: any) =>
                item?.doctor?.code === medicalRecordItem?.doctor_details?.code
            ).length < 1
          ) {
            consultDoctors.push({
              doctor: medicalRecordItem.doctor_details,
              division_name: medicalRecordItem.division_name,
              arrived_dt: medicalRecordItem.started,
              main: true,
            });
          }

          consultDoctors.sort((a: any, b: any) => (a.main ? -1 : 1));
          const consultDoctorAlldata = consultDoctors.map((doctors: any) => {
            const drugOrder: any[] = medicalRecordItem.drug_order
              .map((drugItem: any) => {
                if (doctors.doctor?.code === drugItem.doctor_code) {
                  return drugItem;
                } else if (
                  doctors.doctor?.code !== drugItem.doctor_code &&
                  doctors.doctor?.main
                ) {
                  return drugItem;
                }
              })
              .filter((item: any) => item != null);

            let labOrder: any[] = medicalRecordItem?.lab_order
              ?.map((labItem: any) => {
                if (
                  doctors?.doctor?.code === labItem.doctor_code &&
                  labItem.history_results == null
                ) {
                  return labItem;
                } else if (
                  labItem?.history_results?.some(
                    (item: any) => doctors?.doctor?.code === item.doctor_code
                  )
                ) {
                  return labItem;
                } else if (
                  doctors?.doctor?.code !== labItem.doctor_code &&
                  doctors.main
                ) {
                  return labItem;
                }
              })
              .filter((item: any) => item != null);
              if (!doctors.doctor && 0 <  consultDoctors.length && consultDoctors.length < 2) {
                labOrder = medicalRecordItem?.lab_order
              }
            return { ...doctors, drug_order: drugOrder, lab_order: labOrder };
          });

          return {
            ...medicalRecordItem,
            consult_doctors: consultDoctorAlldata.sort((a: any, b: any) =>
              moment(a.arrived_dt).format("YYYYMMDD") <
              moment(b.arrived_dt).format("YYYYMMDD")
                ? 1
                : -1
            ),
          };
        }
      );
      // console.log("saika medicalRecordByDoctor ", medicalRecordByDoctor);
      setMedicalRecord(
        medicalRecordByDoctor.sort((a: any, b: any) =>
          moment(a.started).format("YYYYMMDD") <
          moment(b.started).format("YYYYMMDD")
            ? 1
            : -1
        ) || null
      );
    } else {
      setMedicalRecord(null);
    }
  }, [props.medicalRecord, props.currentMedication]);

  const locale = useMemo(() => {
    return props.language?.split("-")?.[0] || "en";
  }, [props.language]);

  const handleChange = (panel: any) => (event: any, newExpanded: any) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleClose = () => {
    setExpanded("");
  };

  const handleOffAllMedication = (state: any, drugOrderItems: any) => {
    let allState: any[] = [];

    drugOrderItems.map((item: any) => {
      allState.push([item.id, item.encounter_number, state]);
    });

    props.onEvent({
      message: "handleUpdateCurrentMed",
      params: { multi: allState },
    });
  };

  const handleRateUs = (indexMR: number, index: number) => {
    const historySelect = medicalRecord[indexMR];
    const doctorCode = historySelect.consult_doctors[index].doctor?.code;
    const en = historySelect.encounter;
    const hospitalCode =
      historySelect.hospital_code || props.selectedHospital?.code;
    const url = `/doctor-review/${doctorCode}/${en}/${hospitalCode}/?app=MobAppointment`;
    globalThis.location.href = url;
  };

  console.log(props.language);

  return (
    <div>
      {medicalRecord
        ? medicalRecord?.map((item: any, indexMR: any) => (
            <>
              {item.consult_doctors.map((consultDoctors: any, index: any) => (
                <div style={{ margin: "10px" }}>
                  {/* normal card */}
                  <Accordion
                    key={`${indexMR}-${index}`}
                    style={{
                      borderRadius: "10px",
                      boxShadow: " 0px 1px 2px rgba(0, 0, 0, 0.25)",
                    }}
                    expanded={expanded === `panel${indexMR}-${index}`}
                    onChange={handleChange(`panel${indexMR}-${index}`)}
                  >
                    <AccordionSummary
                      aria-controls={`panel${indexMR}-${index}a-content`}
                      id={`panel${indexMR}-${index}a-header`}
                      style={{
                        width: "100%",
                        padding: "0px 10px 0px 0px",
                      }}
                    >
                      <div style={{ width: "100%", marginBottom: "-6px" }}>
                        <Grid style={{ margin: "0px" }}>
                          <Grid.Column
                            width={5}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              padding: "0",
                            }}
                          >
                            <Avatar
                              src={consultDoctors?.doctor?.image}
                              style={{ width: "60px", height: "60px" }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                bottom: "0px",
                              }}
                            >
                              <div
                                style={styles.rateus}
                                onClick={() => handleRateUs(indexMR, index)}
                              >
                                <Image
                                  src={"/images/star_rateus.png"}
                                  style={{
                                    width: "13px",
                                    height: "13px",
                                    margin: "0 3px 1px 0",
                                  }}
                                />
                                <div
                                  style={{
                                    ...styles.text_whire_16,
                                    fontSize: "12px",
                                  }}
                                >
                                  {"Rate Us"}
                                </div>
                              </div>
                            </div>
                          </Grid.Column>
                          <Grid.Column width={11} style={{ padding: "0" }}>
                            {/* full name */}
                            <Grid.Row>
                              <div style={styles.text_bluebdms_16}>
                                {consultDoctors?.doctor?.full_name || ""}
                              </div>
                            </Grid.Row>
                            {/* hospital name */}
                            <Grid.Row>
                              <div style={styles.text_DarkGray_14}>
                                {item.hospital_name ||
                                  props.selectedHospital?.name ||
                                  ""}
                              </div>
                            </Grid.Row>
                            {/* date time */}
                            <Grid.Row>
                              <div
                                style={{
                                  ...styles.text_DarkGray_14,
                                  fontWeight: "normal",
                                }}
                              >
                                {moment(consultDoctors.arrived_dt)
                                  .clone()
                                  .locale(locale)
                                  .format(
                                    `DD ${
                                      locale === "th" ? "MMM" : "MMM."
                                    } YYYY, HH:mm`
                                  ) || ""}
                              </div>
                            </Grid.Row>
                            {/* division name  */}
                            <Grid.Row>
                              <div
                                style={{
                                  ...styles.text_DarkGray_14,
                                  fontWeight: "normal",
                                }}
                              >
                                {consultDoctors.division_name}
                              </div>
                            </Grid.Row>
                          </Grid.Column>
                        </Grid>
                        {expanded === `panel${index}` ? (
                          ""
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "0 10px",
                            }}
                          >
                            <div style={{ minWidth: "10px" }}></div>
                            <div
                              style={{
                                ...styles.text_bluebdms_16,
                                fontSize: "11px",
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              {"see more"}
                            </div>
                          </div>
                        )}
                      </div>
                    </AccordionSummary>

                    {/* Full card detail  */}

                    <AccordionDetails>
                      <div>
                        <div>
                          <div style={styles.text_bluebdms_16_noellipsis}>
                            <FormattedMessage id={"bplusClinicKey936"} />
                          </div>
                          {item.Diagnosis ? (
                            <div
                              style={{
                                ...styles.text_superdarkgray_14,
                                margin: "10px 0",
                              }}
                            >
                              {item.Diagnosis}
                            </div>
                          ) : (
                            <div style={{ margin: "10px" }}>{"-"}</div>
                          )}
                        </div>
                        <div>
                          <div style={styles.text_bluebdms_16_noellipsis}>
                            <FormattedMessage id={"bplusClinicKey937"} />
                          </div>
                          {consultDoctors.lab_order.length > 0 ? (
                            <div style={{ margin: "10px 0px" }}>
                              <SeeMoreLab
                                labOderItems={consultDoctors.lab_order}
                                started={consultDoctors.arrived_dt}
                              />
                            </div>
                          ) : (
                            <div style={{ margin: "10px" }}>{"-"}</div>
                          )}
                        </div>
                        <div>
                          <div style={styles.text_bluebdms_16_noellipsis}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                Medications
                                {props.isCurrentMed && (
                                  <Image
                                    src={
                                      "/images/History/question-drug-off-icon.png"
                                    }
                                    style={{
                                      width: "14px",
                                      height: "14px",
                                      marginLeft: "5px",
                                      ...(!(consultDoctors.drug_order.length >
                                        0) && { display: "none" }),
                                    }}
                                  />
                                )}
                              </div>
                              {props.isCurrentMed ? (
                                <div
                                  style={{
                                    display:
                                      consultDoctors.drug_order.length > 0
                                        ? "flex"
                                        : "none",
                                    alignItems: "center",
                                  }}
                                >
                                  <div
                                    style={{
                                      fontSize: "14px",
                                      color: COLOR.darkgrayHos,
                                      fontWeight: "normal",
                                    }}
                                  >
                                    Mark all as
                                  </div>
                                  <div>
                                    <Button
                                      style={{
                                        background: "red",
                                        color: COLOR.font_white,
                                        borderRadius: "10px",
                                        padding: "8px 12px",
                                        fontSize: "12px",
                                        marginLeft: "5px",
                                      }}
                                      onClick={() =>
                                        handleOffAllMedication(
                                          "OFF",
                                          consultDoctors.drug_order
                                        )
                                      }
                                    >
                                      OFF
                                    </Button>
                                    <Button
                                      style={{
                                        background: COLOR.primary,
                                        color: COLOR.font_white,
                                        borderRadius: "10px",
                                        padding: "8px 12px",
                                        marginLeft: "5px",
                                        fontSize: "12px",
                                      }}
                                      onClick={() =>
                                        handleOffAllMedication(
                                          "ON",
                                          consultDoctors.drug_order
                                        )
                                      }
                                    >
                                      ON
                                    </Button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {consultDoctors.drug_order.length > 0 ? (
                              <div style={{ margin: "10px" }}>
                                {props.isCurrentMed ? (
                                  <SeeMoreCurrentMedication
                                    medicationItems={consultDoctors.drug_order}
                                    handleOpenDrugDetail={
                                      props.handleOpenDrugDetail
                                    }
                                    onEvent={props.onEvent}
                                  />
                                ) : (
                                  <SeeMoreMedication
                                    medicationItems={consultDoctors.drug_order}
                                  />
                                )}
                              </div>
                            ) : (
                              <div style={{ margin: "10px" }}>{"-"}</div>
                            )}
                          </div>
                        </div>
                        {/* wait API Patient */}
                        {false ? (
                          <>
                            <div style={styles.text_bluebdms_16_noellipsis}>
                              {"Patient Instruction"}
                            </div>
                            <div
                              style={{
                                ...styles.text_superdarkgray_Indent50,
                                marginTop: "10px",
                              }}
                            >
                              {
                                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam semper diam at erat pulvinar, at pulvinar felis blandit. Vestibulum volutpat tellus diam, consequat gravida libero rhoncus ut."
                              }
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ minWidth: "10px" }}></div>
                          <div
                            style={{ display: "flex", justifyContent: "end" }}
                            onClick={() => handleClose()}
                          >
                            <Icon name="angle up" color="grey" size="large" />
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </>
          ))
        : ""}
    </div>
  );
};

export default React.memo(CardDoctor);
