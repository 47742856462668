import { WasmHandler } from 'react-lib/frameworks/WasmController'

export type State = 
  {
    tagList?: any[],
    selectedTagIndex?: number | null,
    contentList?: any[],
    selectedContentIndex?: number | null
    postList?: any[],
    selectedPostIndex?: number | null
  }

export const StateInitial = 
  {
    tagList: [],
    selectedTagIndex: null
  }

export type Event = 
  { message: "GetTagList", params: {} } |
  { message: "AddTag", params: { parent: string | null } } |
  { message: "SaveTag", params: {} } |
  { message: "DeleteTag", params: { id: string } } |
  { message: "AddTagSet", params: { dataType: string } } |
  { message: "AddTagToTagSet", 
    params: { tagIndex: number, tag: any, dataType: string } } |
  { message: "RemoveTagFromTagSet", 
    params: { tagIndex: number, tag: any, dataType: string } }

export type Data = {}

export const DataInitial = {}

type Handler = WasmHandler<State, Event>

export const GetTagList: Handler = (controller, params) => {
  controller.db.collection("Tag")
    .get()
    .then((res: any) => {
      // GenerateFakeStat(controller, { docs: res.docs })
      const tagList = res.docs.map((doc: any) => ({id: doc.id, ...doc.data()}))
                         .sort((a: any, b: any) => parseInt(a.id) < parseInt(b.id) ? -1 : 1);
      controller.setState({ 
        tagList: tagList,
      })
    })
}

export const AddTag: Handler = async (controller, params) => {
  const tagList = controller.getState().tagList || [];
  const last = (
    tagList.length === 0 ? 
    0 : Math.max(...tagList.map((tag: any) => parseInt(tag.id as string)))
  ) + 1;
  console.log(last)
  await controller.db.collection("Tag")
    .doc(last.toString())
    .set({
      name: "New tag",
      parent: params.parent
    })
  GetTagList(controller, {});
}

export const SaveTag: Handler = async (controller, params) => {
  const state = controller.getState();
  const tag = state?.tagList?.[state?.selectedTagIndex || 0]
  const { row, id, level, ...data } = tag;
  await controller.db.collection("Tag")
    .doc(tag.id)
    .set(data)
  GetTagList(controller, {});
}

export const DeleteTag: Handler = async (controller, params) => {
  if (params.id && params.id !== "") {
    let children = controller.getState().tagList
                    ?.filter((tag: any) => tag.parent === params.id);
    if (children && children.length === 0) {
      await controller.db.collection("Tag")
        .doc(params.id)
        .delete();
      GetTagList(controller, {});
    } else {
      console.log("Cannot delete node with children: ");
      console.log(children);
    }
  }
}

const getData: (dataType: string, state: State) => [any[], number] 
  = (dataType: string, state: State) => {
  let dataList: any[] = [];
  let selectedIndex = 0;
  if (dataType === "post") {
    dataList = state.postList || [];
    selectedIndex = state.selectedPostIndex || 0;
  } else if (dataType === "content") {
    dataList = state.contentList || [];
    selectedIndex = state.selectedContentIndex || 0;
  }
  return [dataList, selectedIndex];
}

export const AddTagSet: Handler = (controller, params) => {
  if (!["post", "content"].includes(params.dataType))
    return
  const state = controller.getState();
  let [dataList, selectedIndex] = getData(params.dataType, state);
  console.log(dataList);
  console.log(selectedIndex);
  if (Number.isInteger(selectedIndex)) {
    let tags = dataList?.[selectedIndex as number].tags || [];
    tags.push({});
    controller.setProp(`${params.dataType}List.${selectedIndex}.tags`, tags);
  }
}

export const AddTagToTagSet: Handler = (controller, params) => {
  if (!["post", "content"].includes(params.dataType))
    return
  const state = controller.getState();
  let [dataList, selectedIndex] = getData(params.dataType, state);
  if (Number.isInteger(selectedIndex)) {
    let tags = dataList?.[selectedIndex as number].tags;
    tags[params.tagIndex][params.tag.id] = params.tag;
    controller.setProp(`${params.dataType}List.${selectedIndex}.tags`, tags);
  }
}

export const RemoveTagFromTagSet: Handler = (controller, params) => {
  if (!["post", "content"].includes(params.dataType))
    return
  const state = controller.getState();
  let [dataList, selectedIndex] = getData(params.dataType, state);
  if (Number.isInteger(selectedIndex)) {
    let tags = dataList?.[selectedIndex as number].tags;
    delete tags[params.tagIndex][params.tag.id];
    controller.setProp(`${params.dataType}List.${selectedIndex}.tags`, tags);
  }
}
