import React, {
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
  ReactElement,
} from "react";
// MUI
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

// Types
type InfiniteScrollProps = {
  // data
  loadMore?: boolean;
  totalLength: number;
  visibleLength: number;
  currentPage: number;
  // style
  heightStyle?: string;
  // callback
  onNext?: (page: number) => any;
  // Element
  children: ReactElement;
  endMessage?: ReactElement | string;
};

// Styles
const useStyles = makeStyles((theme) => ({
  screen: {
    background: "rgba(250, 250, 255, 1)",
    width: "calc(100% - -32px)",
    margin: "0 -16px 0",
    padding: "12px 16px",
    height: "100%",
    overflow: "auto",
  },
  bottom: {
    display: "flex",
    justifyContent: "center",
  },
}));

function debounce(func: (this: any, ...args: any[]) => void, delay: number) {
  let timeout: any;

  return function (this: any, ...args: any[]) {
    const context = this;

    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

const InfiniteScroll = (props: InfiniteScrollProps) => {
  const classes = useStyles();

  const screenRef = useRef() as MutableRefObject<HTMLDivElement>;
  const isBottomRef = useRef<boolean>(false);

  const propsRef = useRef({ total: 0, visible: 0, page: 0, loading: false });

  const handleScroll = useCallback((event: Event) => {
    const box = event.target as HTMLDivElement;

    const toBottom = box.scrollTop + box.offsetHeight + 15 >= box.scrollHeight;

    if (toBottom) {
      const endOfList = propsRef.current.total <= propsRef.current.visible;

      if (!isBottomRef.current && !propsRef.current.loading && !endOfList) {
        isBottomRef.current = true;

        props.onNext?.(propsRef.current.page + 1);
      } else {
        isBottomRef.current = false;
      }
    } else {
      isBottomRef.current = false;
    }
  }, []);

  useEffect(() => {
    propsRef.current = {
      total: props.totalLength,
      visible: props.visibleLength,
      page: props.currentPage,
      loading: props.loadMore || false,
    };

    // Before adding a new event listener, remove any existing ones to prevent duplicates
    screenRef.current?.removeEventListener("scroll", handleScroll);

    const debouncedScroll = debounce(handleScroll, 250);

    screenRef.current?.addEventListener("scroll", debouncedScroll);

    return () => {
      screenRef.current?.removeEventListener("scroll", handleScroll);
    };
  }, [
    screenRef,
    props.visibleLength,
    props.totalLength,
    props.currentPage,
    props.loadMore,
  ]);

  return (
    <div
      ref={screenRef}
      className={`${classes.screen} remember-scroll`}
      style={{ height: props.heightStyle }}
    >
      {props.children}
      {props.loadMore && (
        <div className={classes.bottom}>
          <CircularProgress
            style={{
              marginLeft: "0.5rem",
            }}
            size={25}
          />
        </div>
      )}
      {props.totalLength === props.visibleLength && !!props.visibleLength && (
        <div className={classes.bottom}>{props.endMessage}</div>
      )}
    </div>
  );
};

export default React.memo(InfiniteScroll);
