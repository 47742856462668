import React, { useState, useMemo, useEffect } from "react";
// MUI
import MuiButton from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { CSSProperties } from "@mui/styles";

import { useLocation, useHistory } from "react-router";

// UX
import CardRadioList from "./CardRadioList";
// medication
import HeaderBar from "bplus-lib/medication/HeaderBar";

// Interface
import { State, Event } from "../MobSmartAppointmentInterface";
import { AFLocationType } from "./MakeAppointmentFor";

// Const.
import { URLS } from "./Constants";

// Types
type AppointmentReasonProps = {
  onEvent: (e: Event) => any;
} & Pick<State, "medServiceLists" | "loadingStatus">;

export type ARLocationType = AFLocationType &
  AFLocationType["storedState"] & {
    appointmentType?: string;
    storedState?: {
      reason: Record<string, any>;
    };
  };

// Styles
const COLORS = {
  grey: "rgba(57, 57, 57, 1)",
  bg: "linear-gradient(0deg, rgba(1, 71, 163, 0.03), rgba(1, 71, 163, 0.03))",
  blue: "rgba(0, 53, 122, 1)",
  blue_005: "rgba(1, 71, 163, 0.05)",
};

const screenStyles = {
  padding: "10px 16px 16px",
  display: "flex",
  minHeight: "calc(100vh - 50px)",
  flexDirection: "column",
  "& .MuiPaper-root.active": {
    background: COLORS.bg,
  },
  "&:before": {
    top: "-50px",
    minHeight: "100vh",
  },
} as CSSProperties;

const useStyles = makeStyles((theme) => ({
  screen: screenStyles,
  title: {
    fontSize: "1.125rem",
    fontWeight: "bold",
    color: COLORS.grey,
    margin: "0.75rem 0 1rem",
  },
}));

const BUTTON_ACTIONS = {
  list: "_LIST",
};

const APPOINTMENT_REASON = "AppointmentReason";
const ACTION_LIST = `${APPOINTMENT_REASON}${BUTTON_ACTIONS.list}`;

const AppointmentReason = (props: AppointmentReasonProps) => {
  const classes = useStyles();
  const location = useLocation<ARLocationType>();
  const history = useHistory();

  const [selectedReasonId, setSelectedReasonId] = useState<any>(null);
  const [note, setNote] = useState<string>("");

  // Memo Effect
  const locState = useMemo(() => {
    return location.state || {};
  }, [location.state]);

  const selectedReason = useMemo(() => {
    return (props.medServiceLists || []).find(
      (item) => item.id === selectedReasonId
    );
  }, [selectedReasonId, props.medServiceLists]);

  // Effect
  useEffect(() => {
    if (!selectedReason?.has_free_text) {
      setNote("");
    }
  }, [selectedReasonId, selectedReason]);

  useEffect(() => {
    const storedState = locState.storedState;

    if (storedState) {
      setSelectedReasonId(storedState.reason.id);
      setNote(storedState.reason.note);
    }
  }, [locState.storedState]);

  useEffect(() => {
    const refresh = !props.medServiceLists;

    if (refresh) {
      props.onEvent({
        message: "GetListMedService",
        params: { hospital: locState.hospital?.code, card: ACTION_LIST },
      });
    }
  }, [locState.hospital]);

  // Memo
  const allowConfirm = useMemo(() => {
    return (
      selectedReasonId &&
      (selectedReason?.has_free_text && selectedReason?.is_free_text_required
        ? !!note
        : true)
    );
  }, [selectedReasonId, note, selectedReason]);

  const isLoading = useMemo(() => {
    return props.loadingStatus?.[ACTION_LIST];
  }, [props.loadingStatus, locState.hospital]);

  // Handler
  const handleConfirm = () => {
    props.onEvent({
      message: "HandleHistoryPushState",
      params: {
        history,
        pathname: URLS.SELECT_DATETIME,
        state: {
          reason: { ...selectedReason, note },
        },
      },
    });
  };

  return (
    <div>
      <HeaderBar
        onEvent={() => {}}
        // data
        // history={this.props.history}
        setTitle="เลือกเหตุผลสำหรับการนัดหมาย"
        whiteTheme={true}
        bgGradient={true}
        // cscreenonfig
        hiddenRight={true}
        hiddenLeft={true}
        buttonLeftback={true}
      />

      <div className={`${classes.screen} bg-gradient-container`}>
        <div style={{ flexGrow: 1 }}>
          <div className={classes.title}>ทำนัดหมายแพยท์ให้กับ</div>

          <CardRadioList
            items={(props.medServiceLists || []) as any}
            loading={isLoading}
            selected={selectedReasonId}
            note={note}
            // callback
            onSelect={setSelectedReasonId}
            onNoteChange={setNote}
          />
        </div>

        <div>
          <div className="button-submit-bottom">
            <MuiButton
              variant="contained"
              color="primary"
              disabled={!allowConfirm}
              onClick={handleConfirm}
            >
              ถัดไป
            </MuiButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(AppointmentReason);
