import WasmController from 'react-lib/frameworks/WasmController';
import axios from "axios";
import config from '../../config/config';
import { sha256 } from "js-sha256";
const FAST_API = `${config.FAST_API}`;

export type State = 
  {
    contentSummary?: {},
    contentOk?: [],
    contentUnique?: [],
    termList?: [],
    contentList?: any[],
    editedContent?: any,
    domainList?: any[],
    domainData?: any,
    contentDomain?: { [url: string]: boolean },
    selectedContentIndex?: number | null
  }

export const StateInitial: State = 
  {
    contentSummary: {},
    contentOk: [],
    contentUnique: [],
    termList: [],
    contentList: [],
    editedContent: {},
    domainList: [],
    domainData: {
      name: "",
      items: []
    },
    contentDomain: {},
    selectedContentIndex: null
  }

export type Event = 
  { message: "SearchContent", params: {} } |
  { message: "GetDomainList", params: {} } |
  { message: "GetDomainContent", params: { domain: string } } |
  { message: "GetContent", params: { content: any } } |
  { message: "ContentOk", params: { content: any, ok: boolean } } |
  { message: "SaveSelectedContent", params: {} } |
  { message: "GetLinkTerm", params: { url: string } } | 
  { message: "AddSkipTerm", params: { term: string } }
  
  // Deprecate 
  // { message: "GetFetchedContent", params: {} } |
  // { message: "GetOkContent", params: {} } |
  // { message: "GetUniqueContent", params: {} }

export type Data = 
  {
  }

export const DataInitial = 
  {
  }

type Handler = (
  controller: WasmController<State, Event, Data>, params?: any) => any

export const SearchContent: Handler = async (controller, params) => {
}

export const GetDomainList: Handler = async (controller, params) => {
  controller.storage.refFromURL('gs://mybplus-content')
  .child("web")
  .listAll()
  .then(async (res: any) => {
    let domainList: any[] = [];
    for (const item of res.items) {
      domainList.push(item.name)
    }
    console.log(domainList)
    controller.setState({
      domainList: domainList
    })
  })
  .catch(err => {
    console.log(err)
  });
  // const res = await controller.db.collection("Content").get()
  // const docs = res.docs.map((doc: any) => ({ 
  //   id: doc.id,
  //   ...doc.data()
  // }));
  // console.log(docs);
  // if (docs.length > 0) {
  //   controller.setState({
  //     contentList: docs,
  //     selectedContentIndex: 0
  //   });
  // }
}

export const GetDomainContent: Handler = async (controller, params) => {
  if (!params.domain)
    return

  if (controller.getState().domainData.name === params.domain)
    return

  controller.setState({
    domainData: {
      name: params.domain,
      items: []
    }
  });

  controller.storage.refFromURL('gs://mybplus-content')
  .child(`web/${params.domain}`)
  .getDownloadURL()
  .then(async (url: string) => {
    const res = await axios.get(url);
    const contentDomain = await controller
                                .db
                                .collection("ContentDomain")
                                .doc(params.domain)
                                .get();
    const data = contentDomain.data() || StateInitial.contentDomain;
    console.log(data);
    controller.setState({
      domainData: {
        name: params.domain,
        items: res.data
      },
      contentDomain: data
    })
  })
}

export const GetContent: Handler = async (controller, params) => {
  const id = sha256(params.content.url);
  let content = (await controller.db
                                  .collection("Content")
                                  .doc(id)
                                  .get()).data();
  // console.log(content);
  if (!content) {
    content = StateInitial.editedContent
  }
  controller.setState({
    editedContent: content
  })
}

export const ContentOk: Handler = async (controller, params) => {
  const id = sha256(params.content.url);
  // console.log(id);
  let mergedContent = { 
    // Start with content in firestore
    ...controller.getState().editedContent,

    // Then override with content in gcs
    ...params.content, 
    
    // Then override with ok param
    ok: params.ok
  }
  
  controller.db.collection("Content")
  .doc(id)
  .set(mergedContent);

  let mergedContentDomain = {
    // Start with contentDomain in firestore
    ...controller.getState().contentDomain,

    // Then override with ok params
    [params.content.url]: params.ok
  }

  controller.db.collection("ContentDomain")
  .doc(params.content.domain)
  .set(mergedContentDomain);

  controller.setState({
    contentDomain: mergedContentDomain
  });

  // axios.post(`${FAST_API}/api/content/content-ok`, { url: params.url, ok: params.ok })
  // .then((res: any) => {
  //   console.log(res);
  //   controller.setState({
  //     contentSummary: res?.data || {}
  //   })
  // })
  // .catch((err: any) => {
  //   console.log(err);
  // })
}

export const SaveSelectedContent: Handler = (controller, params) => {
  if (Number.isInteger(controller.getState().selectedContentIndex)) {
    const state = controller.getState()
    const selectedContent = state?.contentList?.[state?.selectedContentIndex as number];
    const data = Object.keys(selectedContent).reduce((acc, cur) => (
      cur === "id" ? acc: { ...acc, [cur]: selectedContent[cur] }
    ), {});
    console.log(controller.getState().selectedContentIndex);
    console.log(data);
    controller.db.collection("Content")
      .doc(selectedContent.id)
      .set(data)
    }
}

export const GetLinkTerm: Handler = async (controller, params) => {
  axios.post(`${FAST_API}/api/content/get-link-term`, { url: params.url })
  .then((res: any) => {
    console.log(res);
    controller.setState({
      termList: res.data?.terms || []
    })
  })
  .catch((err: any) => {
    console.log(err);
  })
}

export const AddSkipTerm: Handler = async (controller, params) => {
  axios.post(`${FAST_API}/api/content/add-skip-term`, params)
  .then((res: any) => {
    console.log(res);
    controller.setState({
      termList: res.data?.terms || []
    })
  })
  .catch((err: any) => {
    console.log(err);
  })
}

// Deprecate ================================================================
export const GetFetchedContent: Handler = async (controller, params) => {
  axios.get(`${FAST_API}/api/data`)
  .then((res: any) => {
    console.log(res);
    controller.setState({
      contentSummary: res?.data || {}
    })
  })
  .catch((err: any) => {
    console.log(err);
  })
}

export const GetOkContent: Handler = async (controller, params) => {
  axios.get(`${FAST_API}/api/content/get-ok-content`)
  .then((res: any) => {
    console.log(res);
    controller.setState({
      contentOk: res?.data || []
    })
  })
  .catch((err: any) => {
    console.log(err);
  })
}

export const GetUniqueContent: Handler = async (controller, params) => {
  axios.get(`${FAST_API}/api/content/get-unique-content`)
  .then((res: any) => {
    console.log(res);
    controller.setState({
      contentUnique: res?.data || []
    })
  })
  .catch((err: any) => {
    console.log(err);
  })
}

