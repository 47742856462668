import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  forwardRef,
  Ref,
  useImperativeHandle,
  useCallback,
} from "react";

import moment, { Moment } from "moment";
import { Calendar } from "@natscale/react-calendar";
import { FormattedMessage } from "react-intl";

// Interface
import { timeSlotsType } from "./SelectDateInterface";

// CSS
import "./calendar.scss";
import { Dimmer, Loader } from "semantic-ui-react";

// Types
type AppCalendarProps = {
  // data
  value?: Moment | null;
  defaultValue?: string | Moment;
  calendarList?: {
    active: Date[];
    inactive: Date[];
  };
  timeslotList?: timeSlotsType[];
  isLoadingCalendar?: boolean;
  isLoadingNextMonth?: boolean;
  className?: string;
  // callback
  onChange?: (date: Moment | null) => any;
  onNextMonth?: (dateRange: any[]) => Promise<any>;
  onGetCalendar?: (dateRange: any[], isTelemed: boolean) => any;
  onLoad?: () => any;
};

type GetCalendarParams = {
  isTelemed: boolean;
  appointmentType?: "check_up" | "specialty";
  startAppointmentDate?: string;
};

export type AppCalendarRef = {
  getCalendar: (params: GetCalendarParams) => any;
  updateYearMonthList: (viewDate?: Moment | null) => any;
};

// Const
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const BE_FORMAT_DATE = "YYYY-MM-DD";

const AppCalendar = (props: AppCalendarProps, ref: Ref<any>) => {
  // Loading
  const [isReloadCalendar, setIsReloadCalendar] = useState(false);
  // dat
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  // Ref
  const yearMonthList = useRef<string[]>([]);
  const initialViewDate = useRef<any>(null);
  const isMounted = useRef<boolean>(false);
  const isDefaultValue = useRef<boolean>(false);

  useImperativeHandle<AppCalendarRef, AppCalendarRef>(ref, () => ({
    getCalendar: handleGetCalendar,
    updateYearMonthList: handleUpdateYearMonthList,
    // clearYearMonthList: handleClearYearMonthList,
    // clearInitialViewDate: handleClearInitialViewDate,
  }));

  // Callback Effect
  const findDate = useCallback(
    (value: Moment | null) => {
      return props.calendarList?.active?.find(
        (date: Date) =>
          moment(date).format(BE_FORMAT_DATE) === value?.format(BE_FORMAT_DATE)
      );
    },
    [props.calendarList?.active]
  );

  const getDefaultDate = useCallback(() => {
    const currentDate = moment();
    const currentMonth = currentDate.month();

    // Set the default value based on conditions
    let defaultValue = moment(props.defaultValue || undefined);

    if (defaultValue.isBefore(currentDate)) {
      defaultValue = currentDate;
    } else if (defaultValue.month() === currentMonth) {
      defaultValue = currentDate;
    } else if (defaultValue.month() > currentMonth) {
      defaultValue = moment().startOf("month");
    }

    return defaultValue;
  }, [props.defaultValue]);

  // Effect
  useEffect(() => {
    props.onLoad?.();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth - 15,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  useEffect(() => {
    const active = findDate(props.value || null);
    const defaultDate = findDate(
      props.defaultValue ? moment(props.defaultValue) : null
    );

    if (defaultDate && !isDefaultValue.current) {
      props.onChange?.(moment(defaultDate));
      isDefaultValue.current = true;
    } else if (!active && props.value) {
      props.onChange?.(null);
    }
  }, [props.calendarList?.active, props.timeslotList, props.value]);

  useEffect(() => {
    // selected date manual
    handleSetSelectedDateManual();
  }, [isReloadCalendar, props.value]);

  useEffect(() => {
    setIsReloadCalendar(true);

    setTimeout(() => {
      setIsReloadCalendar(false);
    });
  }, [props.isLoadingCalendar]);

  // Memo
  const date = useMemo(() => {
    return props.value ? props.value.toDate() : undefined;
  }, [props.value]);

  // Handler

  const handleUpdateYearMonthList = (viewDate?: Moment | null) => {
    const calendarAll = [
      ...(props.calendarList?.active || []),
      ...(props.calendarList?.inactive || []),
    ]
      .sort((a: Date, b: Date) =>
        ("" + a.toISOString()).localeCompare(b.toISOString())
      )
      .map((date) => moment(date).format("YYYY-MM"));
    const uniqueDate = Array.from(new Set(calendarAll));
    // -uniqueDate.pop();

    yearMonthList.current = uniqueDate;

    setIsReloadCalendar(true);

    viewDate = viewDate || props.value;

    if (viewDate) {
      initialViewDate.current = new Date(
        moment(viewDate).format(BE_FORMAT_DATE)
      );
    }

    setTimeout(() => {
      setIsReloadCalendar(false);
    });
  };

  const handleClearYearMonthList = () => {
    yearMonthList.current = [];
  };

  const handleClearInitialViewDate = () => {
    initialViewDate.current = null;
  };

  const getYearMonthCalendar = () => {
    const headerDate = document.querySelectorAll(
      ".rc_header_label.rc_header_label-days-of-month span"
    );
    const [elmMonth, elmYear] = Array.from(headerDate);
    let getYearMonth: string = "";
    if (elmMonth && elmYear) {
      getYearMonth = `${elmYear.textContent}-${(
        MONTHS.indexOf(elmMonth.textContent || "") + 1
      )
        .toString()
        .padStart(2, "0")}`;
    }
    return getYearMonth;
  };

  const handleGetNextMonth = async (value: string) => {
    const start_date = moment(`${value}-01`);
    const halfMonth = Math.ceil(start_date.daysInMonth() / 2);
    const date = moment(`${start_date.format("YYYY-MM")}-${halfMonth}`);

    const find = [
      ...(props.calendarList?.active || []),
      ...(props.calendarList?.inactive || []),
    ]?.find((value: Date) => value.toISOString() === date.toISOString());

    let dateRange: any[] = [];

    const endOfMonth = date.clone().endOf("month");
    const nextMonth = endOfMonth.clone().add(1, "days");
    const halfNextMonth = Math.ceil(nextMonth.daysInMonth() / 2);

    if (find) {
      dateRange = [
        {
          start_date: date.add(1, "days").format(BE_FORMAT_DATE),
          end_date: endOfMonth.format(BE_FORMAT_DATE),
        },
        {
          start_date: nextMonth.format(BE_FORMAT_DATE),
          end_date: `${nextMonth.format("YYYY-MM")}-${halfNextMonth}`,
        },
      ];
    } else {
      dateRange = [
        {
          start_date: start_date.format(BE_FORMAT_DATE),
          end_date: date.format(BE_FORMAT_DATE),
        },
        {
          start_date: date.add(1, "days").format(BE_FORMAT_DATE),
          end_date: endOfMonth.format(BE_FORMAT_DATE),
        },
        {
          start_date: nextMonth.format(BE_FORMAT_DATE),
          end_date: `${nextMonth.format("YYYY-MM")}-${halfNextMonth}`,
        },
      ];
    }

    initialViewDate.current = new Date(dateRange[0].start_date);

    await props.onNextMonth?.(dateRange);

    setIsReloadCalendar(true);
    setTimeout(() => {
      setIsReloadCalendar(false);
    });
  };

  const handleSetSelectedDateManual = () => {
    if (props.value) {
      const yearMonth = getYearMonthCalendar();
      const nextYearMonth = moment(`${yearMonth}-01`)
        .add(1, "months")
        .format("YYYY-MM");
      const selectDate = moment(props.value).format(BE_FORMAT_DATE);

      for (const elm of Array.from(
        document.querySelectorAll(".rc .rc_body-cell")
      )) {
        const btn = elm.querySelector("button");

        if (elm.className.includes("rc_selected")) {
          elm.classList.remove("rc_selected", "rc_disabled");
        }
        if (
          elm.className.includes("rc_active") &&
          `${yearMonth}-${btn?.textContent?.padStart(2, "0")}` === selectDate &&
          !elm.className.includes("rc_highlight")
        ) {
          elm.classList.add("rc_selected");
        } else if (
          (elm.className.includes("rc_lr") ||
            elm.className.includes("rc_lc")) &&
          `${nextYearMonth}-${btn?.textContent?.padStart(2, "0")}` ===
            selectDate &&
          !elm.className.includes("rc_active")
        ) {
          elm.classList.add("rc_selected", "rc_disabled");
        }
      }
    }
  };

  const handleIsDisabled = (date: Date) => {
    setTimeout(() => {
      const yearMonth = getYearMonthCalendar();
      const first = yearMonthList.current[0];

      // -const prevMonth = isMounted.current && first > yearMonth;

      if (!yearMonthList.current.includes(yearMonth) && yearMonth > first) {
        yearMonthList.current = [...yearMonthList.current, yearMonth];

        handleGetNextMonth(yearMonth);
      } else if (yearMonthList.current.includes(yearMonth)) {
        handleSetSelectedDateManual();
      }

      if (first === yearMonth && !isMounted.current) {
        isMounted.current = true;
      }
    });

    return !props.calendarList?.active.find(
      (item: Date) => item.toISOString() === date.toISOString()
    );
  };

  const handleGetCalendar = async (params: GetCalendarParams) => {
    handleClearYearMonthList();
    handleClearInitialViewDate();

    const defaultDate = getDefaultDate();

    const initDate = !isMounted.current ? defaultDate ?? undefined : undefined;
    const currentDate = moment(initDate);

    let hour = 24;

    //  วันที่นัดหมายล่วงหน้า (เมื่อไม่ไช่เป็นนัดหมายของ ecoupon)
    if (params.appointmentType === "check_up") {
      // -hour = 24;
    }

    if (params.appointmentType === "specialty") {
      if (params.isTelemed) {
        hour = 1;
      } else {
        hour = 4;
      }
    }

    // -if (props.defaultValue) {
    //   hour = 0;
    // }

    let start_date = currentDate.clone().add(hour, "hours");

    // implement diag form appointment calculated here
    if (params.startAppointmentDate) {
      start_date = moment(params.startAppointmentDate, "DD/MM/YYYY");
      console.log("getCalendars by appointment set", start_date);

      if (start_date.diff(moment(), "days") < 3) {
        start_date = moment().add(3, "days");
      }

      console.log("getCalendars by check date > 3", start_date);
    }

    const halfMonth = Math.ceil(start_date.daysInMonth() / 2);
    let dateRange: any[] = [];

    if (+start_date.format("DD") < halfMonth) {
      const end_date = `${start_date.format("YYYY-MM")}-${halfMonth}`;

      dateRange.push({
        start_date: start_date.format(BE_FORMAT_DATE),
        end_date,
      });
      yearMonthList.current = [start_date.format("YYYY-MM")];
      start_date = moment(end_date).add(1, "days");
    } else {
      yearMonthList.current = [start_date.format("YYYY-MM")];
    }

    const endOfMonth = start_date.clone().endOf("month");
    const nextMonth = endOfMonth.clone().add(1, "days");
    const halfNextMonth = Math.ceil(nextMonth.daysInMonth() / 2);

    dateRange = [
      ...dateRange,
      {
        start_date: start_date.format(BE_FORMAT_DATE),
        end_date: endOfMonth.format(BE_FORMAT_DATE),
      },
      {
        start_date: nextMonth.format(BE_FORMAT_DATE),
        end_date: `${nextMonth.format("YYYY-MM")}-${halfNextMonth}`,
      },
    ];

    props.onGetCalendar?.(dateRange, params.isTelemed);
  };

  const handleDateChange = (value: any) => {
    props.onChange?.(moment(value));
  };

  return (
    <div
      className={props.className}
      style={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      <Dimmer
        active={props.isLoadingNextMonth || props.isLoadingCalendar}
        inverted
      >
        <Loader inverted>
          <FormattedMessage id="bplusClinicKey297" />
        </Loader>
      </Dimmer>
      <div style={{ display: "flex", justifyContent: "center" }}>
        {useMemo(
          () =>
            (props.calendarList?.active[0] ||
              props.calendarList?.inactive[0]) &&
            !isReloadCalendar ? (
              <Calendar
                className="calendar-custom"
                // value={date}
                // @ts-ignore
                highlights={props.calendarList?.inactive}
                onChange={handleDateChange}
                disablePast={true}
                // disableToday={true}
                // disableFuture={true}
                isDisabled={handleIsDisabled}
                startOfWeek={0}
                size={dimensions.width}
                initialViewDate={
                  initialViewDate.current || props.calendarList?.active?.[0]
                }
                // minAllowedDate={props.calendarList.active?.[0]}
                // maxAllowedDate={maxAllowedDate}
              />
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Calendar
                  className="calendar-custom"
                  initialViewDate={initialViewDate.current}
                  startOfWeek={0}
                  size={dimensions.width}
                  isDisabled={handleIsDisabled}
                  // @ts-ignore
                  disablePast={true}
                  disableToday={true}
                  disableFuture={true}
                />
              </div>
            ),
          [props.calendarList, date, dimensions, isReloadCalendar]
        )}
      </div>
    </div>
  );
};

const ForwardedAppCalendar = forwardRef<any, AppCalendarProps>(AppCalendar);

export default React.memo(ForwardedAppCalendar);
