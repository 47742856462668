import React, { useCallback } from "react";

// UX
import CardPayment, { PaymentDataType, PaymentType } from "./CardPayment";
import CardPaymentSkeleton from "./CardPaymentSkeleton";
import CardVerifyingPayment from "./CardVerifyingPayment";
// smartappointment
import ItemNotFound from "../smartappointment/ItemNotFound";

// Types
type CardPaymentListProps = {
  loading: boolean;
  checking?: boolean;
  paymentType: PaymentType;
  episodePaymentList?: PaymentDataType[];
  noResultsMessage?: {
    title?: string;
    desc?: string;
  };
  // checkoutStatus?: CheckoutStatusType;
  // drugStatus?: DrugStatusType;
  loadingStatus?: Record<string, any>;
  // deliveryFee?: string | number;
  // config
  hideDescription?: boolean;
  // callback
  onSeeDetail?: (data: any, id: number) => any;
  onPayment?: (data: any, type: PaymentType, index: number) => any;
  onRefresh?: () => any;
};

// Images
const IMAGES = {
  consent: "/images/register/consent.png",
};

// Const
const BUTTON_ACTIONS = {
  detail: "_DETAIL",
  payment: "_PAYMENT",
};

const CARD_PAYMENT_LIST = "CardPaymentList";

export const ACTION_DETAIL = `${CARD_PAYMENT_LIST}${BUTTON_ACTIONS.detail}`;
export const ACTION_PAYMENT = `${CARD_PAYMENT_LIST}${BUTTON_ACTIONS.payment}`;

const CardPaymentList = (props: CardPaymentListProps) => {
  // -const classes = useStyles();

  const getTotalAmount = useCallback((item: PaymentDataType, index: number) => {
    const price =
      item.receipts?.[index]?.price ||
      item.receipt?.price ||
      item.invoice?.price;

    return Number(item.delivery_fee)
      ? Number(price) + Number(item.delivery_fee)
      : price;
  }, []);

  if (props.loading) {
    return (
      <>
        {[1, 2].map((id) => (
          <CardPaymentSkeleton key={id} />
        ))}
      </>
    );
  } else if (props.checking) {
    return (
      <CardVerifyingPayment
        // callback
        onRefresh={props.onRefresh}
      />
    );
  } else if (props.episodePaymentList?.length) {
    return (
      <>
        {props.paymentType === "receipt" ? (
          <>
            {props.episodePaymentList.map((item) =>
              (item.receipts || []).map((items, index) => (
                <CardPayment
                  key={items.id}
                  data={item}
                  keyId={items.id}
                  checkoutStatus={items.checkout_status}
                  drugStatus={items.drug_status}
                  type={props.paymentType}
                  // payment
                  totalAmount={getTotalAmount(item, index)}
                  // config
                  detailLoading={
                    props.loadingStatus?.[`${ACTION_DETAIL}_${items.id}`]
                  }
                  paymentLoading={
                    props.loadingStatus?.[`${ACTION_PAYMENT}_${items.id}`]
                  }
                  hideDescription={props.hideDescription}
                  // isReceipt={true}
                  // callback
                  onSeeDetail={props.onSeeDetail}
                  onPayment={props.onPayment}
                />
              ))
            )}
          </>
        ) : (
          <>
            {props.episodePaymentList.map((item) => (
              <CardPayment
                key={item.id}
                data={item}
                keyId={item.id}
                checkoutStatus={item.checkout_status}
                drugStatus={item.drug_status}
                type={props.paymentType}
                // payment
                totalAmount={getTotalAmount(item, -1)}
                // config
                detailLoading={
                  props.loadingStatus?.[`${ACTION_DETAIL}_${item.id}`]
                }
                paymentLoading={
                  props.loadingStatus?.[`${ACTION_PAYMENT}_${item.id}`]
                }
                hideDescription={props.hideDescription}
                // isReceipt={true}
                // callback
                onSeeDetail={props.onSeeDetail}
                onPayment={props.onPayment}
              />
            ))}
          </>
        )}
      </>
    );
  } else {
    return (
      <ItemNotFound
        img={IMAGES.consent}
        title={
          props.noResultsMessage ? props.noResultsMessage.title : "ไม่มีรายการ"
        }
        subTitle={
          props.noResultsMessage
            ? props.noResultsMessage.desc
            : "ไม่มีรายการรอชำระในวันนี้"
        }
      />
    );
  }
};

export default React.memo(CardPaymentList);
