import React, { CSSProperties, ReactElement, useMemo } from "react";
// MUI
import makeStyles from "@mui/styles/makeStyles";

// Icon
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

// Types
type BoxTitleProps = {
  type?: "datetime" | "label";
  mini?: boolean;
  datetime?: string; // dd mmm yyy|HH:mm - HH:mm
  // styles
  style?: CSSProperties;
  labelStyle?: CSSProperties;
  // callback
  onClick?: () => any;
  // Element
  children?: ReactElement | ReactElement[];
};

// Styles
const COLORS = {
  bg_shadow: "linear-gradient(317.15deg, #F9F8F8 -14.59%, #EFEFEF 87.87%)",
  light_grey: "rgba(121, 120, 120, 1)",
  shadow: "1px 8px 18px 0px rgba(0, 102, 255, 0.1)",
  grey: "rgba(57, 57, 57, 1)",
  light_yellow: "rgba(255, 244, 201, 1)",
  bg: "rgba(249, 249, 249, 1)",
  primary: "var(--blue-bdms-color)",
  divider: "rgba(204, 218, 237, 1)",
  normal: "rgba(121, 120, 120, 1)",
};

const labelStyles = {
  alignItems: "center",
  fontWeight: "bold",
  display: "flex",
  overflow: "hidden",
  "& label": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    display: "block",
  },
  "&.mini": {
    fontSize: ".875rem !important",
    "& label": {
      fontSize: ".875rem !important",
    },
  },
  "& svg": {
    fontSize: "1.3rem",
    marginRight: "0.25rem",
  },
};

const useStyles = makeStyles((theme) => ({
  screen: {
    backgroundColor: COLORS.bg,
    color: COLORS.grey,
    padding: "6.5px 16px",
    borderRadius: "8px",
    minHeight: "65px",
    fontWeight: "bold",
    display: "grid",
    alignItems: "center",
    "& .divider": {
      borderRight: `1px solid ${COLORS.divider}`,
    },
    "& > div:nth-child(2)": {
      paddingLeft: "1.25rem",
    },
    "& .price": {
      fontSize: "1.125rem",
      color: COLORS.primary,
    },
    "& .normal": {
      fontWeight: "normal",
      color: COLORS.normal,
    },
    "& .label": labelStyles,
    "& img.icon": {
      width: "16px",
      marginRight: "0.55rem",
    },
  },
}));

const BoxTitle = (props: BoxTitleProps) => {
  const classes = useStyles();

  const gridTemplateColumns = useMemo(() => {
    return props.type === "datetime" ? "1fr 1fr" : "1fr";
  }, [props.type]);

  return (
    <div
      aria-hidden="true"
      className={classes.screen}
      style={{
        gridTemplateColumns,
        ...(props.type === "label"
          ? { padding: 0, backgroundColor: "unset", minHeight: "auto" }
          : {}),
        ...(props.style || {}),
      }}
      onClick={props.onClick}
    >
      <RenderContent
        type={props.type}
        mini={props.mini}
        datetime={props.datetime}
        labelStyle={props.labelStyle || {}}
      >
        {props.children}
      </RenderContent>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      RenderContent                     */

/* ------------------------------------------------------ */
type RenderContentProps = {} & Pick<
  BoxTitleProps,
  "type" | "mini" | "labelStyle" | "children" | "datetime"
>;

const RenderContent = (props: RenderContentProps) => {
  const datetime = useMemo(() => {
    return props.datetime?.split("|") || [];
  }, [props.datetime]);

  if (props.type === "datetime") {
    return (
      <>
        <div className="divider">
          <div className="label">
            <CalendarMonthOutlinedIcon />
            <label style={{ marginBottom: "0", ...props.labelStyle }}>
              {datetime[0]}
            </label>
          </div>
        </div>
        <div>
          <div className="label">
            <AccessTimeOutlinedIcon />
            <label style={{ marginBottom: "0", ...props.labelStyle }}>
              {datetime[1]}
            </label>
          </div>
        </div>
      </>
    );
  } else if (props.type === "label") {
    return (
      <div
        className={`label ${props.mini ? "mini" : ""}`}
        style={{ fontWeight: "normal", ...props.labelStyle }}
      >
        {props.children}
      </div>
    );
  } else {
    return <>{props.children}</>;
  }
};

export default React.memo(BoxTitle);
