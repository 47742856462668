import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import MobFeed from './bplus-lib/MobFeed';
import MainPatient from './patient-lib/MainPatient';

// import MobClinic from './bplus-lib/MobClinic';
import WebClinic from './bplus-lib/WebClinic';
import Manage from './bplus-lib/Manage';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
// import MobAppointment from "./bplus-lib/MobAppointment";
import MobCoupon from "./bplus-lib/MobCoupon";
import MobKYC from './bplus-lib/MobKYC'
import MobPHR from "./bplus-lib/MobPHR";
import MobLAB from "./bplus-lib/MobLAB";
import Admin from "./bplus-lib/Admin";
import Analytics from "./bplus-lib/Analytics";
import MobFeedSkeleton from "./bplus-lib/feed/MobFeedSkeleton";
import MobMedication from "./bplus-lib/MobMedication";
import MobSmartRegister from "./bplus-lib/MobSmartRegister";
import MobSmartAppointment from "./bplus-lib/MobSmartAppointment";
// import MobPayment from "./bplus-lib/MobPayment";
import MobHistory from "./bplus-lib/MobHistory"
import MobFeedSimulator from "./bplus-lib/MobFeedSimulator";
import MobAppointmentSimulator from "./bplus-lib/MobAppointmentSimulator";
import TeleDesktop from "./bplus-lib/TeleDesktop";

import { IntlProvider } from 'react-intl';
import thMessage from "react-lib/localization/bplusClinic/th.json";
import CONFIG from "config/config";

const params = new URLSearchParams(window.location.search)
const app = params.get('app') 
console.log('app: ', app);

let isMobile 
if (typeof globalThis !== "undefined") {
  isMobile = !!globalThis.mobile?.getAppointmentObject || !!globalThis.iosNative || !!globalThis.MobNative
} else {
  isMobile = !!window.mobile?.getAppointmentObject || !!window.iosNative || !!window.MobNative
}
console.log('isMobile: ', isMobile);

// เฉพาะ desktop เท่านั้น ที่ redirect
if ( !app && CONFIG.NO_PATH_REDIRECT && !isMobile ) {
  window.location.replace("/?app=TeleDesktop");
}

const patientId = params.get('patientId')


console.log(" Index.tsx init !!!", patientId)

declare global {
  var mobile: any
  var setState: any
  var webkit: any;
}

const MobAppointment = React.lazy(()=> import('bplus-lib/MobAppointment'))
const MobFeed = React.lazy(() => import('bplus-lib/MobFeed'));
const MobClinic = React.lazy(() => import('bplus-lib/MobClinic'));
const MobSelectHospital = React.lazy(()=> import('bplus-lib/MobSelectHospital'))
const MobConsent = React.lazy(() => import("bplus-lib/MobConsent"));
const MobPayment = React.lazy(() => import("bplus-lib/MobPayment"));

// const MobMakeAppointmentUnsign = React.lazy(()=> import('bplus-lib/MobMakeAppointmentUnsign'))

ReactDOM.render(
  
  // Build only MobFeed
  process.env.REACT_APP_BUILD_TARGET === "mobfeed" ?
  <BrowserRouter> <Suspense fallback={<div>Loading...</div>}> <MobFeed /> </Suspense></BrowserRouter>:

  // Build only MobClinic
  process.env.REACT_APP_BUILD_TARGET === "mobclinic" ?
  <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobClinic /> </Suspense> </BrowserRouter>:

  // Build only Manage
  process.env.REACT_APP_BUILD_TARGET === "bplus-manage" ?
  <React.StrictMode>
    <BrowserRouter><Manage patientId={patientId}/></BrowserRouter>
  </React.StrictMode>:

  // Build only Admin
  process.env.REACT_APP_BUILD_TARGET === "bplus-admin" ?
  <React.StrictMode>
    <BrowserRouter><Admin /></BrowserRouter>
  </React.StrictMode>:

  // Build only Analytics
  process.env.REACT_APP_BUILD_TARGET === "bplus-analytics" ?
  <React.StrictMode>
    <BrowserRouter><IntlProvider locale="th-TH"  messages={thMessage} > <Analytics /> </IntlProvider></BrowserRouter>
  </React.StrictMode>:

  // Build all
  <React.StrictMode>
    {
    app === "MobFeed" ?
    <BrowserRouter><Suspense fallback={<MobFeedSkeleton/>}> <MobFeed /> </Suspense> </BrowserRouter>:

    app === "MobFeedSimulator" ?
    <BrowserRouter><Suspense fallback={<MobFeedSkeleton/>}> <MobFeedSimulator /> </Suspense> </BrowserRouter>:

    app === "MobAppointment" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobAppointment /> </Suspense></BrowserRouter>:

    app === "MobAppointmentSimulator" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobAppointmentSimulator /> </Suspense></BrowserRouter>:

    app === "MobMedication" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobMedication /> </Suspense></BrowserRouter>:

    app === "MobHistory" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobHistory /> </Suspense></BrowserRouter>:

    app === "MobConsent" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobConsent /> </Suspense></BrowserRouter>:

    app === "MobCoupon" ?
    <BrowserRouter><MobCoupon /></BrowserRouter>:

    app === "IH" ?
    <BrowserRouter><MainPatient /></BrowserRouter>:

    app === "MobClinic" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobClinic /> </Suspense> </BrowserRouter>:

    app === "MobKYC" ?
    <BrowserRouter><MobKYC /></BrowserRouter>:

    app === 'MobPHR'?
    <BrowserRouter><MobPHR/></BrowserRouter>:

    app === 'MobLAB'?
    <BrowserRouter><MobLAB/></BrowserRouter>:

    app === "Admin" ?
    <BrowserRouter><Admin /></BrowserRouter>:

    app === "WebClinic" ?
    <BrowserRouter><WebClinic /></BrowserRouter>:

    app === "SelectHospital" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobSelectHospital /></Suspense></BrowserRouter>:

    app === "TeleDesktop" ?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <TeleDesktop /></Suspense></BrowserRouter>:

    app === 'MobRegister'?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobSmartRegister /> </Suspense></BrowserRouter>:
   
    app === 'MobSmartAppointment'?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobSmartAppointment /> </Suspense></BrowserRouter>:

    app === 'MobPayment'?
    <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobPayment /> </Suspense></BrowserRouter>:
    // app === "MobMakeAppointmentUnsign" ?
    // <BrowserRouter><Suspense fallback={<div>Loading...</div>}> <MobMakeAppointmentUnsign /></Suspense></BrowserRouter>:
    isMobile ? <BrowserRouter><MainPatient /></BrowserRouter> : 

    <BrowserRouter><Manage patientId={patientId}/></BrowserRouter> 
    }
  </React.StrictMode>,

  document.getElementById('root')
);

reportWebVitals();

// For Python loading -- not used for now
// serviceWorker.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

// Previous switch in render
// app === "DM" ?
// <BrowserRouter><Manage  patientId={patientId}/></BrowserRouter>:

// app === "OA" ?
// <BrowserRouter><OA /></BrowserRouter>:

// app === "SA" ?
// <SA />:

// app === "PD" ?
// <BrowserRouter><PD /></BrowserRouter>:
