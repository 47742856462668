import WasmController from "../react-lib/frameworks/WasmController";

// Interface
import * as MobSmartAppointmentI from "./MobSmartAppointmentInterface";
import * as DoctorAppointmentI from "./smartappointment/DoctorAppointmentInterface";
import * as FeedInterface from "./native/FeedInterface";
import * as SelectDateI from "./appointment/SelectDateInterface";
import * as MobAppointmentI from "./MobAppointmentInterface";

console.log(" MobPaymentController !!! init ");
if (
  typeof globalThis !== "undefined" &&
  typeof globalThis.mobile === "undefined"
) {
  console.log("Web not Mobile ");
  globalThis.mobile = FeedInterface;
} else if (window && typeof window.mobile === "undefined") {
  console.log("Web not Mobile (window)");
  window.mobile = FeedInterface;
}

export default class MobSmartAppointmentController extends WasmController<
  MobSmartAppointmentI.State,
  MobSmartAppointmentI.Event,
  MobSmartAppointmentI.Data
> {
  constructor(
    getState: () => MobSmartAppointmentI.State,
    setState: (state: MobSmartAppointmentI.State, callback?: any) => void,
    window: any
  ) {
    super(getState, setState, window, MobSmartAppointmentI.DataInitial);
  }

  handleEvent = (e: MobSmartAppointmentI.Event) => {
    console.log(e.message, e.params);
    switch (e.message) {
      // Native
      case "GetLoginInfo":
        globalThis.mobile.getLoginInfo();
        return;

      case "HandleSetOpenBurger":
        globalThis.mobile.setOpenBurger();
        return;

      case "HandleBacktoNative":
        globalThis.mobile.setBackToNative();
        return;

      // Setup
      case "DidMount":
        MobSmartAppointmentI.DidMount(this, e.params);
        return;

      case "DidUpdate":
        MobSmartAppointmentI.DidUpdate(this, e.params);
        return;

      // DoctorAppointment
      case "HandleGetTopService":
        DoctorAppointmentI.HandleGetTopService(this, e.params);
        return;

      case "HandleSearchListDoctor":
        DoctorAppointmentI.HandleSearchListDoctor(this, e.params);
        return;

      case "GetListCenterGroup":
        DoctorAppointmentI.GetListCenterGroup(this, e.params);
        return;

      case "GetListAppointmentCenter":
        DoctorAppointmentI.GetListAppointmentCenter(this, e.params);
        return;

      case "HandleDoctorAppointmentAction":
        DoctorAppointmentI.HandleDoctorAppointmentAction(this, e.params);
        return;

      case "GetListAvailableDoctor":
        DoctorAppointmentI.GetListAvailableDoctor(this, e.params);
        return;

      case "GetListMedService":
        DoctorAppointmentI.GetListMedService(this, e.params);
        return;

      case "GetListMedProgram":
        DoctorAppointmentI.GetListMedProgram(this, e.params);
        return;

      case "HandleConfirmCheckupType":
        DoctorAppointmentI.HandleConfirmCheckupType(this, e.params);
        return;

      // Appointment
      case "GetAppointmentObject":
        if (globalThis.mobile.getAppointmentObject) {
          globalThis.mobile.getAppointmentObject()
        }
        return

      case "GetMyProfileDetail":
        MobSmartAppointmentI.GetMyProfileDetail(this, e.params);
        return;

      case "HandleHistoryPushState":
        MobSmartAppointmentI.HandleHistoryPushState(this, e.params);
        return;

      case "HandlePrepareMakeAppointment":
        MobSmartAppointmentI.HandlePrepareMakeAppointment(this, e.params);
        return;

      case "GetListRequestedAppointment":
        MobSmartAppointmentI.GetListRequestedAppointment(this, e.params);
        return;

      case "GetListMyAppointment":
        MobSmartAppointmentI.GetListMyAppointment(this, e.params);
        return;

      case "GetListCancelAppointment":
        MobSmartAppointmentI.GetListCancelAppointment(this, e.params);
        return;

      case "CancelAppointment":
        MobSmartAppointmentI.CancelAppointment(this, e.params);
        return;

      case "GetDoctorProfile":
        MobSmartAppointmentI.GetDoctorProfile(this, e.params);
        return;

      // ----- SelectDate
      case "GetCalendars":
        SelectDateI.GetCalendars(this, e.params);
        return;

      case "handleCalendarNextMonth":
        return SelectDateI.handleCalendarNextMonth(this, e.params);

      // MonAppointmentI
      case "GetAppointmentSlot":
        MobAppointmentI.GetAppointmentSlot(this, e.params);
        return;

      case "MakeAppointment":
        return MobAppointmentI.MakeAppointment(this, e.params);

      case "HandleMakeAppointmentUnsign":
        MobAppointmentI.HandleMakeAppointmentUnsign(this, e.params);
        return;

      case "HandleRouteChange":
        MobAppointmentI.HandleRouteChange(this, e.params);
        return;

      default:
        let testExhausitve: never = e;
    }
    console.log("-- Unhandled case");
  };
}
