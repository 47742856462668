import { WasmHandler } from "react-lib/frameworks/WasmController";
import moment from "moment";
import Cookies from "js-cookie";
import CONFIG from "../config/config";
import * as Python from "react-lib/frameworks/Python";
import axios from "axios";
import firebase from "firebase/compat/app";
// Interface
import * as FirebaseI from "react-lib/frameworks/FirebaseInterface";
import * as LoginI from "./login/LoginInterface";
import * as FeedI from "./feed/FeedI";
import * as PatientDetailI from "./manage/PatientDetailInterface";
import * as PostI from "./post/PostI";
import * as TagI from "react-lib/frameworks/TagI";

// DM Interface
import * as DMEmotionRestI from "./clinic/DMEmotionRestInterface";
import * as DMExerciseI from "./clinic/DMExerciseInterface";
import * as DMPersonalScoreI from "./clinic/DMPersonalScoreInterface";
import * as DMFoodI from "./clinic/DMFoodInterface";
import * as DMFootI from "./clinic/DMFootInterface";
import * as DMDrugI from "./clinic/DMDrugInterface";
import * as DMWeightI from "./clinic/DMWeightInterface";
import * as DMVaccineI from "./clinic/DMVaccineInterface";

// Web DM Interface
import * as WebDMWeightI from "./manage/detail/WebDMWeightInterface";
import * as WebDMBloodPressureI from "./manage/detail/WebDMBloodPressureInterface";
import * as WebDMGlucoseI from "./manage/detail/WebDMGlucoseInterface";
import * as WebDMMedicationI from "./manage/detail/WebDMMedicationInterface";
import * as WebDMVaccineI from "./manage/detail/WebDMVaccineInterface";
import * as WebDMFootI from "./manage/detail/WebDMFootDetailInterface";

import * as MarketingSegmentI from "./post/MarketingSegmentInterface";

// User Management Interface
import * as WebDMUMNutritionI from "./manage/detail/WebDMUMNutritionInterface";
import * as WebUMBabyRelationshipI from "./manage/detail/WebUMBabyRelationshipInterface";
import * as WebUMPatientSelectionI from "./manage/detail/WebUMPatientSelectionInterface";
import * as WebUMVaccineI from "./manage/detail/WebUMVaccineInterface";
import * as WebUMDevelopmentI from "./manage/detail/WebUMDevelopmentInterface";

// MOM Interface
import * as MomProfileHealthI from "./clinic/MomProfileHealthInterface";
import * as MomKickCountI from "./clinic/MomKickCountInterface";
import * as MomContractionCountI from "./clinic/MomContractionCountInterface";
import * as WebMomObstreticRecordI from "./manage/detail/WebMomObstreticRecordInterface";
import * as MomWeightGainI from "./clinic/MomWeightGainInterface";
import * as WebMomUltrasoundI from "./manage/detail/WebMomUltrasoundInterface";
import * as WebMomBreastFeedingI from "./manage/detail/WebMomBreastFeedingInterface";
import * as WebMomPrenatalAndANCRecordI from "./manage/detail/WebMomPrenatalAndANCRecordInterface";
// ClinicContent Interface
import * as ClinicContentI from "./manage/ClinicContentInterface";
import * as PromotionExI from "./post/PromotionExInterface";
import * as PostExI from "./post/PostExInterface";
import * as MarketingAnalyticsI from "./post/MarketingAnalyticsInterface";

// Child Interface
import * as ChildOverallI from "./clinic/ChildOverallInterface";
import * as ChildDevelopmentI from "./clinic/ChildDevelopmentInterface";
import * as WebChildOutsideMedicineI from "./manage/detail/WebChildOutsideMedicineInterface";
import * as WebChildDevelopmentI from "./manage/detail/WebChildDevelopmentInterface";
import * as WebChildGrowthChartI from "./manage/detail/WebChildGrowthChartInterface";
import * as WebChildVaccinationI from "./manage/detail/WebChildVaccinationInterface";

// APIs
import DivisionList from "../issara-sdk/apis/DivisionList_core";
import DoctorList from "../issara-sdk/apis/DoctorList_core";
import PatientSegmentList from "../issara-sdk/apis/PatientSegmentList_apps_PHR";
import PatientSegmentDetailM from "../issara-sdk/apis/PatientSegmentDetail_apps_PHRM";
import PatientDataList from "../issara-sdk/apis/PatientDataList_apps_PHR";
import ProxyPatientNoUserListView from "../issara-sdk/apis/ProxyPatientNoUserListView_apps_PRX";
import ProxyPatientListView from "../issara-sdk/apis/ProxyPatientListView_apps_PRX";
import StaffDirectDMLabResultView from "../issara-sdk/apis/StaffDirectDMLabResultView_apps_PRX";
import DivisionHasUserList from "issara-sdk/apis/DivisionHasUserList_apps_PRX";
import OrganizationListView from "issara-sdk/apis/OrganizationListView_users";
import TopServiceDetail from "issara-sdk/apis/TopServiceDetail_apps_PRX";
import TopServiceDetailM from "issara-sdk/apis/TopServiceDetail_apps_PRXM";

import TopServiceListCreateView from "issara-sdk/apis/TopServiceListCreateView_apps_PRX";
import MedProgramListView from "issara-sdk/apis/MedProgramListView_apps_PRX";
import ProxySubspecialtyListView from "issara-sdk/apis/ProxySubspecialtyListView_apps_PRX";

import { v4 as uuidv4 } from "uuid";
import ProxyMyProfile from "../issara-sdk/apis/ProxyMyProfile_profileM";

import {
  calculateLabBloodPressureScore,
  calculateLabWeight,
  calculateDMLabScore,
  BACKEND_DATE_FORMAT,
} from "./MobClinicInterface";

import firebaseConfig from "../_manager/firebase-config";
import { LoginFirebase1 } from "./login/LoginInterface";
import { Item } from "semantic-ui-react";

const FAST_API = `${CONFIG.FAST_API}`;

const params = new URLSearchParams(window.location.search);

export type State = {
  // Common
  loggedin?: boolean;
  oaId?: string;
  clinic?: string;
  showSegment?: boolean;

  loadingStatus?: boolean;
  loadingPatientSegment?: boolean;
  errorMessage?: any;
  successMessage?: any;
  onSearch?: boolean;

  menuName?: string;

  // Options
  routeOptions?: any;
  unitOptions?: any;
  frequencyOptions?: any;
  methodOptions?: any;
  administrationTimeOptions?: any;

  // MainDM
  patientSegmentList?: {
    next?: string | null;
    previous?: string | null;
    total: number;
    items: any[];
  };
  selectedDivision?: any;
  selectedPatient?: any;
  patientSearch?: any;
  leftMenuSelected?: string;
  dmSelectedTab?: number | null;
  showPatientDM?: boolean;
  // MainPD
  patientClinicalList?: any[];
  patientMarketingList?: any[];
  patientSegmentIndex?: number | null;
  patientIndex?: number | null;
  patientId?: string | null;
  userProfile?: any[];
  hideTab?: boolean;
} & LoginI.State &
  PatientDetailI.State &
  FirebaseI.State &
  Python.PythonState &
  PostI.State &
  TagI.State &
  FeedI.State &
  // DM
  DMEmotionRestI.State &
  DMExerciseI.State &
  DMPersonalScoreI.State &
  DMFoodI.State &
  DMFootI.State &
  DMDrugI.State &
  DMWeightI.State &
  DMVaccineI.State &
  WebDMWeightI.State &
  WebDMBloodPressureI.State &
  WebDMGlucoseI.State &
  WebDMMedicationI.State &
  WebDMVaccineI.State &
  MarketingSegmentI.State &
  WebDMFootI.State &
  // UM
  WebDMUMNutritionI.State &
  WebUMBabyRelationshipI.State &
  WebUMPatientSelectionI.State &
  WebUMVaccineI.State &
  WebUMDevelopmentI.State &
  // MOM
  MomProfileHealthI.State &
  MomKickCountI.State &
  MomContractionCountI.State &
  WebMomObstreticRecordI.State &
  MomWeightGainI.State &
  WebMomUltrasoundI.State &
  WebMomBreastFeedingI.State &
  WebMomPrenatalAndANCRecordI.State &
  // Child
  ChildOverallI.State &
  ChildDevelopmentI.State &
  WebChildOutsideMedicineI.State &
  WebChildDevelopmentI.State &
  WebChildGrowthChartI.State &
  ClinicContentI.State &
  PromotionExI.State &
  PostExI.State &
  MarketingAnalyticsI.State &
  WebChildVaccinationI.State;

export const StateInitial = {
  // Common
  oaId: params.get("oaId") || "",
  clinic: params.get("clinic") || "",
  showSegment: false,

  loadingStatus: false,
  loadingPatientSegment: false,
  errorMessage: null,
  successMessage: null,
  onSearch: false,

  menuName: "",

  // Options
  routeOptions: {
    //Todo: remove
    items: [
      { key: 1, text: "oral", value: 1 },
      { key: 2, text: "subcutaneous", value: 2 },
    ],
  },
  unitOptions: {
    //Todo: remove
    items: [
      { key: 1, text: "tablet", value: 1 },
      { key: 2, text: "unit", value: 2 },
    ],
  },
  frequencyOptions: {
    //Todo: remove
    items: [
      { key: 1, text: "1 time", value: 1 },
      { key: 2, text: "2 time", value: 2 },
      { key: 3, text: "3 time", value: 3 },
      { key: 4, text: "4 time", value: 4 },
    ],
  },
  methodOptions: {
    //Todo: remove
    items: [
      { key: 1, text: "before meal", value: 1 },
      { key: 2, text: "after meal", value: 2 },
    ],
  },
  administrationTimeOptions: {
    //Todo: remove
    items: [
      { key: 1, text: "morning", value: 1 },
      { key: 2, text: "afternoon", value: 2 },
      { key: 3, text: "evening", value: 3 },
      { key: 4, text: "bedtime", value: 4 },
    ],
  },

  // MainDM
  patientSegmentList: {},
  selectedDivision: {},
  selectedPatient: {},
  patientSearch: {},
  leftMenuSelected: "patient_segment",
  dmSelectedTab: null,
  showPatientDM: false,

  // MainPD
  patientClinicalList: [],
  patientMarketingList: [],
  patientSegmentIndex: null,
  patientIndex: null,
  patientId: null,
  hideTab: false,
  ...PatientDetailI.StateInitial,
  ...FirebaseI.StateInitial,
  ...PostI.StateInitial,
  ...TagI.StateInitial,
  ...LoginI.StateInitial,
  ...FeedI.StateInitial,
  // DM
  ...DMEmotionRestI.StateInitial,
  ...DMExerciseI.StateInitial,
  ...DMPersonalScoreI.StateInitial,
  ...DMFoodI.StateInitial,
  ...DMFootI.StateInitial,
  ...DMDrugI.StateInitial,
  ...DMWeightI.StateInitial,
  ...DMVaccineI.StateInitial,
  ...WebDMWeightI.StateInitial,
  ...WebDMBloodPressureI.StateInitial,
  ...WebDMGlucoseI.StateInitial,
  ...WebDMMedicationI.StateInitial,
  ...WebDMVaccineI.StateInitial,
  ...WebDMFootI.StateInitial,

  //Marketing
  ...MarketingSegmentI.StateInitial,
  ...MarketingAnalyticsI.StateInitial,

  // UM
  ...WebDMUMNutritionI.StateInitial,
  ...WebUMBabyRelationshipI.StateInitial,
  ...WebUMPatientSelectionI.StateInitial,
  ...WebUMVaccineI.StateInitial,
  ...WebUMDevelopmentI.StateInitial,
  // MOM
  ...MomProfileHealthI.StateInitial,
  ...MomKickCountI.StateInitial,
  ...MomContractionCountI.StateInitial,
  ...WebMomObstreticRecordI.StateInitial,
  ...MomWeightGainI.StateInitial,
  ...WebMomUltrasoundI.StateInitial,
  ...WebMomBreastFeedingI.StateInitial,
  ...WebMomPrenatalAndANCRecordI.StateInitial,
  // Child
  ...ChildOverallI.StateInitial,
  ...ChildDevelopmentI.StateInitial,
  ...WebChildOutsideMedicineI.StateInitial,
  ...WebChildDevelopmentI.StateInitial,
  ...ClinicContentI.StateInitial,
  ...PromotionExI.StateInitial,
  ...PostExI.StateInitial,
  ...WebChildGrowthChartI.StateInitial,
  ...WebChildVaccinationI.StateInitial,
};

export type Event =
  | { message: "DidMount"; params: {} }
  | { message: "LoggedIn"; params: {} }
  | { message: "handleClearMessage"; params: {} }
  | {
      message: "handleGetPatientSegmentList";
      params: {
        segment_type: string;
        result: string;
        limit: number;
        offset: number;
      };
    }
  | { message: "handleSelectedPatient"; params: {} }
  | { message: "PreferedDidMount"; params: {} }
  | { message: "handleSelectedOrganization"; params: {} }
  | { message: "GetServiceFromFirestore"; params: {} }
  | { message: "SaveServiceItemToFirebase"; params: {} }
  | { message: "DeleteIconFromFirebase"; params: {} }
  | { message: "addNewServiceToFirebase"; params: {} }
  | { message: "deleteServiceToFirebase"; params: {} }
  | { message: "GetServicePreferred"; params: {} }
  | { message: "SaveServicePreferred"; params: {} }
  | { message: "DeleteServicePreferredIcon"; params: {} }
  | { message: "AddNewServicePreferred"; params: {} }
  | { message: "DeleteServicePreferred"; params: {} }
  | { message: "GetPatientClinicalList"; params: {} }
  // | {
  //   message: "handleGetLabSummary";
  //   params: { start_date: string; end_date: string };
  // }
  | {
      message: "handleSearchPatient";
      params: { search: string };
    }
  | {
      message: "handleSelectPatientSearch";
      params: { data: any };
    }
  | {
      message: "handleSelectedDivisionId";
      params: { divisionId: number };
    }
  | {
      message: "SetPatientIndex";
      params: {};
    }
  | LoginI.Event
  | PatientDetailI.Event
  | FirebaseI.Event
  | Python.PythonEvent
  | PostI.Event
  | TagI.Event
  // | FeedI.Event
  // DM
  | DMEmotionRestI.Event
  | DMExerciseI.Event
  | DMPersonalScoreI.Event
  | DMFoodI.Event
  | DMFootI.Event
  | DMDrugI.Event
  | DMWeightI.Event
  | DMVaccineI.Event
  | WebDMWeightI.Event
  | WebDMBloodPressureI.Event
  | WebDMGlucoseI.Event
  | WebDMMedicationI.Event
  | WebDMVaccineI.Event
  | WebDMFootI.Event
  | MarketingSegmentI.Event

  // UM
  | WebDMUMNutritionI.Event
  | WebUMBabyRelationshipI.Event
  | WebUMPatientSelectionI.Event
  | WebUMVaccineI.Event
  | WebUMDevelopmentI.Event
  // MOM
  | MomProfileHealthI.Event
  | MomKickCountI.Event
  | MomContractionCountI.Event
  | WebMomObstreticRecordI.Event
  | MomWeightGainI.Event
  | WebMomUltrasoundI.Event
  | WebMomBreastFeedingI.Event
  | WebMomPrenatalAndANCRecordI.Event
  // Child
  | ChildOverallI.Event
  | ChildDevelopmentI.Event
  | WebChildOutsideMedicineI.Event
  | WebChildDevelopmentI.Event
  | WebChildGrowthChartI.Event
  | WebChildVaccinationI.Event
  | ClinicContentI.Event
  | PromotionExI.Event
  | PostExI.Event
  | MarketingAnalyticsI.Event;

export type Data = {};

export const DataInitial = {};

type Handler = WasmHandler<State, Event>;

export const CLINIC = {
  DM: "dm",
  MOM: "mom",
};

export const SEGMENT_TYPE = {
  CLINICAL: "CLINICAL",
  MARKETING: "MARKETING",
};

export const DM_RESULT = {
  DM: "DM",
  PRE_DM: "Pre-DM",
  DM_SURVEILLANCE: "DM Surveillance",
};
export const DM_SUB_RESULT = {
  NO_COMPLICATION: "(no complication)",
  RISK_TO_CVD: "* risk to CVD",
  UNCLASSIFIED: "Unclassified",
};

export const MOM_RESULT = "ผู้ป่วย Mom";
export const MOM_SUB_RESULT = {
  PREGNANCY_0_13_WEEK: "Pregnancy: 0-13 weeks",
  PREGNANCY_14_27_WEEK: "Pregnancy: 14-27 weeks",
  PREGNANCY_28_40_WEEK: "Pregnancy: 28-40 weeks",
  PREGNANCY_UNKNOW: "Unclassified",
};

export const CHILD_RESULT = "ผู้ป่วย Child";
export const CHILD_SUB_RESULT = {
  CHILD_0_2_MONTH: "Child (0-2 months)",
  CHILD_2_4_MONTH: "Child (2-4 months)",
  CHILD_4_6_MONTH: "Child (4-6 months)",
  CHILD_6_12_MONTH: "Child (6-12 months)",
  CHILD_1_3_YEAR: "Child (1-3 yrs.)",
  CHILD_3_6_YEAR: "Child (3-6 yrs.)",
  CHILD_6_10_YEAR: "Child (6-10 yrs.)",
  CHILD_MORE_10_YEAR: "Child (> 10 yrs.)",
};

const SERVICE_HOSPTIAL = "ServicesHospital";
// const SERVICE_HOSPTIAL_ITEM = "ServiceItem";

export const DidMount: Handler = async (controller, params) => {
  // const onFirebaseAuthStateChanged = (user: any) => {
  //   if (user) {
  //     // Check if logged in
  //     console.log("user: ", user.uid);
  //     controller.setState({ loggedin: true });
  //     const clinic = controller.getState().clinic;

  //     GetPatientMarketingList(controller, params);
  //   } else {
  //     console.log("no user");
  //   }
  // };
  // controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);

  const apiToken = controller.cookies.get("apiToken") || "";

  const searchParams = new URLSearchParams(window.location.search);
  const clinic = searchParams.get("clinic");

  // console.log(" DidMount ManageInterface apiToken: ", apiToken)
  if (apiToken !== "") {
    controller.apiToken = apiToken;
    controller.setState({ loggedin: true });
    GetDivisionHasUser(controller as any, { clinic });
    console.log("DidMount call FirebaseSetupLoginHandler ");
    FirebaseSetupLoginHandler(controller, params);
  }
};

export const LoggedIn: Handler = async (controller, params) => {
  const apiToken = controller.cookies.get("apiToken") || "";
  const searchParams = new URLSearchParams(window.location.search);
  const clinic = searchParams.get("clinic");

  // console.log(" DidMount ManageInterface apiToken: ", apiToken)
  if (apiToken !== "") {
    controller.apiToken = apiToken;
    controller.setState({ loggedin: true });
    GetDivisionHasUser(controller as any, { clinic });
  }
};

// Firebase -- For prototyping -----------------------------------------------------
export const FirebaseSetupLoginHandler: Handler = (controller, params) => {
  console.log("FirebaseSetupLoginHandler: ManageInterface");
  // For prototyping -- set up callback for firebase login
  const onFirebaseAuthStateChanged = async (user: any) => {
    console.log(
      "FirebaseSetupLoginHandler : onFirebaseAuthStateChanged call back",
      user
    );
    if (!user) {
      const apiToken = controller.cookies.get("apiToken");
      const userId = controller.cookies.get("userId");

      if (apiToken && userId) {
        console.log(
          "FirebaseSetupLoginHandler: ManageInterface LoginFirebase1"
        );
        LoginFirebase1(controller, { apiToken: apiToken });
      }
    }
  };
  controller.app.auth().onAuthStateChanged(onFirebaseAuthStateChanged);
};

export const GetDivisionHasUser: Handler = async (controller, params) => {
  console.log("setup !!! getDivision Has User");
  const getDivisionHasUser = DivisionHasUserList.list({
    apiToken: controller.apiToken,
  });

  const organizationList = OrganizationListView.list({
    apiToken: controller.apiToken,
  });

  const [[divisionHasUserRes], [organizationListRes]] = await Promise.all([
    getDivisionHasUser,
    organizationList,
  ]);

  const items: any[] = divisionHasUserRes?.items || [];
  let divisionList = items
    .map((item: any) => ({
      division_id: item.division.id,
      division_name: item.division.name,
      division_name_en: item.division.name_en,
      division_code: item.division.code,
      organization: item.division.organization,
      profile: item.division.profile,
      extra: item.extra
    }))
    .filter((item) => item.organization);

  const key = "division_id";
  const arrayUniqueByKey: any[] = Array.from(
    new Map(divisionList.map((item: any) => [item[key], item])).values()
  );

  let gList = [];
  let name: string = "";
  if (params.clinic === CLINIC.DM) {
    name = CONFIG.DM_FEATURE;
  } else if (params.clinic === CLINIC.MOM) {
    name = CONFIG.MOM_FEATURE;
  } else if (params.clinic === "marketing") {
    name = CONFIG.DM_FEATURE;
  } else if (params.clinic === "preferred") {
    // use grouped organization Issue 60121
    // console.log("divisionList: ", divisionList);
    gList = groupingOrganization(divisionList);
  }

  const findIndex = arrayUniqueByKey.findIndex(
    (item) => item?.profile?.feature === name
  );

  console.log("setup !!! selectedDivision selectedDivisionId");
  let showSegment = false;
  if (arrayUniqueByKey[findIndex]?.extra?.includes("show_auto_segment")){
    var obj = JSON.parse(arrayUniqueByKey[findIndex]?.extra);
    showSegment = obj?.show_auto_segment || false
  }
  let firstOListValue = "";
  let firstOListId = "";
  let oList = [];
  if (organizationListRes?.items) {
    oList = organizationListRes?.items
      ?.filter((item: any) => !isNaN(parseInt(item.code)))
      ?.sort((a: any, b: any) => parseInt(a.code) - parseInt(b.code));
    firstOListValue = oList?.find((item: any) => item)?.code;
    firstOListId = oList?.find((item: any) => item)?.id;
  }
  // console.log("setup !!! organizationList:", oList);
  controller.setState({
    divisionHasUser: arrayUniqueByKey,
    organizationList: oList,
    groupedOrganizationList: gList,
    selectedOrganizationCode: firstOListValue,
    selectedOrganizationId: firstOListId,
    selectedDivision: arrayUniqueByKey[findIndex],
    selectedDivisionId:
      arrayUniqueByKey.length > 0
        ? arrayUniqueByKey[findIndex]?.division_id
        : null,
    showSegment: showSegment
  });
  controller.handleEvent({ message: "handleGetMarketingSegmentList" });
  const [profileResp, profileError, profileNetwork] = await ProxyMyProfile.get({
    apiToken: controller.apiToken || controller.cookies.get("apiToken"),
    params: {},
  });

  if (profileError) {
    console.log("profileError : ", profileError);
  } else {
    controller.setState({
      userProfile: profileResp,
    });
  }
};

export const handleClearMessage: Handler = (controller) => {
  controller.setState({ errorMessage: null, successMessage: null });
};

// get
export const handleGetPatientSegmentList: Handler = async (
  controller,
  params
) => {
  controller.setState({ loadingPatientSegment: true });

  const state = controller.getState();

  const [error, response] = await commonListPatientSegmentList(controller, {
    ...params, //  segment_type, result, limit, offset
    segment_group: state.selectedDivision?.division_code,
    have_tk_detail: "true",
  });
  if (error) {
    controller.setState({
      loadingPatientSegment: false,
      errorMessage: error,
      successMessage: null,
      patientSegmentList: { next: null, previous: null, total: 0, items: [] },
      selectedPatient: {},
      onSearch: false,
    });
  } else {
    controller.setState({
      loadingPatientSegment: false,
      errorMessage: null,
      successMessage: null,
      patientSegmentList: response,
      selectedPatient: {},
      onSearch: false,
    });
  }
};

export const handleGetLabSummary: Handler = async (controller, params) => {
  controller.setState({ loadingStatus: true });

  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    controller.setState({
      loadingStatus: false,
      errorMessage: "No patient.",
      successMessage: null,
    });
    return;
  }

  const [response, error, network] = await StaffDirectDMLabResultView.get({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: {
      patient: state.selectedPatient?.proxy_patient,
      ...params, // start_date, end_date
    },
  });

  let weightScore: any = {};
  let systolicScore: any = {};
  let labHeaders: any[] = [];
  if (error) {
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
    });
  } else {
    weightScore = calculateLabWeight(response?.vital_signs?.bmi);
    systolicScore = calculateLabBloodPressureScore(
      response?.vital_signs?.systolic
    );

    let labSummary: any = {
      items: [
        { label: "น้ำหนัก (WT)", code: "WT", ...weightScore },
        { label: "ความดันโลหิต (BP)", code: "BP", ...systolicScore },
      ],
    };

    response?.labs?.forEach((lab: any) => {
      labHeaders.push({ name: lab.name, code: lab.code });
      lab?.items?.forEach((item: any) => {
        const score = calculateDMLabScore(item);
        labSummary.items.push({ label: item.name, code: item.code, ...score });
      });
    });
    console.log("----- calculateDMLabScore", labSummary);
    controller.setState({
      loadingStatus: false,
      errorMessage: null,
      successMessage: null,
      labResultHeader: labHeaders,
      labSummary: labSummary,
    });
  }
};

// selected
export const handleSelectedPatient: Handler = (controller, params) => {
  controller.setState({ selectedPatient: params });
};

//
export const GetPatientClinicalList: Handler = async (controller, params) => {
  const res = await controller.db
    .collection("PatientClinical")
    .where("oaId", "==", controller.getState().oaId)
    .get();

  const patientList = res.docs.map((doc: any) => ({
    id: doc.id,
    ...doc.data(),
  }));

  let patientSegmentMap: Map<string, any> = new Map();
  for (const patient of patientList) {
    if (!patientSegmentMap.get(patient.result)) {
      patientSegmentMap.set(patient.result, []);
    }
    // const resPatient = await controller.db.collection("Patient_core")
    //                          .doc(patient.patient_id)
    //                          .get();
    patientSegmentMap.get(patient.result).push({
      ...patient,
      // ...resPatient.data(),
      id: patient.patient_id,
      patient: patient.patient_id,
    });
  }

  let patientSegmentList: any[] = [];
  for (let segment of Array.from(patientSegmentMap.keys())) {
    const segmentData = {
      result: segment,
      data: patientSegmentMap.get(segment),
    };
    patientSegmentList.push(segmentData);
  }

  controller.setState({
    patientClinicalList: patientSegmentList,
    patientSegmentIndex: patientSegmentList.length > 0 ? 0 : null,
  });

  // Switch to storage later -- use above for prototyping
  // controller.storage.refFromURL('gs://mybplus-segment-dev')
  // .child("dm")
  // .getDownloadURL()
  // .then((url: string) => {
  //   axios.get(url)
  //   .then(res => {
  //     console.log(res.data)
  //     // controller.setState({
  //     //   patientSegmentList: res.data,
  //     //   patientSegmentIndex: res.data.length > 0 ? 0 : null
  //     // })
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   })
  // });

  // Test get segment
};

export const GetPatientMarketingList: Handler = async (controller, params) => {
  const res = await controller.db
    .collection("PatientMarketing")
    .where("oaId", "==", controller.getState().oaId)
    .get();

  const patientList = res.docs.map((doc: any) => ({
    id: doc.id,
    ...doc.data(),
  }));

  let patientSegmentMap: Map<string, any> = new Map();
  for (const patient of patientList) {
    if (!patientSegmentMap.get(patient.result)) {
      patientSegmentMap.set(patient.result, []);
    }
    // const resPatient = await controller.db.collection("Patient_core")
    //                          .doc(patient.patient_id)
    //                          .get();
    patientSegmentMap.get(patient.result).push({
      ...patient,
      // ...resPatient.data(),
      id: patient.patient_id,
      patient: patient.patient_id,
    });
  }

  let patientSegmentList: any[] = [];
  for (let segment of Array.from(patientSegmentMap.keys())) {
    const segmentData = {
      result: segment,
      data: patientSegmentMap.get(segment),
    };
    patientSegmentList.push(segmentData);
  }
  console.log(patientSegmentList);

  controller.setState({
    patientMarketingList: patientSegmentList,
    patientSegmentIndex: patientSegmentList.length > 0 ? 0 : null,
  });

  // Switch to storage later -- use above for prototyping
  // controller.storage.refFromURL('gs://mybplus-segment-dev')
  // .child("dm")
  // .getDownloadURL()
  // .then((url: string) => {
  //   axios.get(url)
  //   .then(res => {
  //     console.log(res.data)
  //     // controller.setState({
  //     //   patientSegmentList: res.data,
  //     //   patientSegmentIndex: res.data.length > 0 ? 0 : null
  //     // })
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   })
  // });

  // Test get segment
};

export const SetPatientIndex: Handler = (controller, params) => {
  // if (params.index == null) {
  //   controller.setState({
  //     patientIndex: null,
  //     patientId: null
  //   })
  // } else {
  //   const patient = controller.getState().patientList[params.index]
  //   if (patient) {
  //     controller.setState({
  //       patientIndex: params.index,
  //       patientId: patient.id
  //     })
  //     PatientDetailI.GetDMDetail(controller, { patientId: patient.id })
  //   }
  // }
};

// common
export const listPatientDataList: Handler = async (controller, params) => {
  const state = controller.getState();

  if (!state.selectedPatient.proxy_patient) {
    return ["No patient.", null];
  }
  const [response, error, network] = await PatientDataList.list({
    apiToken: controller.apiToken,
    params: {
      proxy_patient: state.selectedPatient.proxy_patient,
      ...params,
    },
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const ListDivisionList: Handler = async (controller, params) => {
  const [response, error, network] = await DivisionList.list({
    apiToken: controller.apiToken,
    params: params,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const ListDoctorList: Handler = async (controller, params) => {
  const [response, error, network] = await DoctorList.list({
    apiToken: controller.apiToken,
    params: params,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

// common Patient Segment
export const commonCreatePatientSegment: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let data: any = {
    segment_type: SEGMENT_TYPE.CLINICAL,
    segment_group: state.selectedDivision?.division_code,
    division_code: state.selectedDivision?.division_code,
    patients: [...params.patients],
  };

  const [response, error, network] = await PatientSegmentList.create({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: data,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonCreatePatientSegmentChild: Handler = async (
  controller,
  params
) => {
  const state = controller.getState();

  let data: any = {
    segment_type: SEGMENT_TYPE.CLINICAL,
    segment_group: state.selectedDivision?.division_code,
    division_code: state.selectedDivision?.division_code,
    patients: [],
  };

  const today: moment.Moment = moment();
  params?.forEach((item: any) => {
    let result: string = "";

    const dob: moment.Moment = moment(item.birthdate, BACKEND_DATE_FORMAT);
    const diffDate = today.diff(dob);
    const duration = moment.duration(diffDate);
    const ageYear = duration.years();
    const ageMonth = duration.months();

    if (ageYear >= 10) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_MORE_10_YEAR}`;
    } else if (6 <= ageYear && ageYear < 10) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_6_10_YEAR}`;
    } else if (3 <= ageYear && ageYear < 6) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_3_6_YEAR}`;
    } else if (1 <= ageYear && ageYear < 3) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_1_3_YEAR}`;
    } else if (6 <= ageMonth && ageMonth < 12) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_6_12_MONTH}`;
    } else if (4 <= ageMonth && ageMonth < 6) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_4_6_MONTH}`;
    } else if (2 <= ageMonth && ageMonth < 4) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_2_4_MONTH}`;
    } else if (0 <= ageMonth && ageMonth < 2) {
      result = `${CHILD_RESULT} ${CHILD_SUB_RESULT.CHILD_0_2_MONTH}`;
    }
    data.patients.push({ patient: item?.patient, result: result });
  });

  const [response, error, network] = await PatientSegmentList.create({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    data: data,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonListPatientSegmentList: Handler = async (
  controller,
  params
) => {
  const [response, error, network] = await PatientSegmentList.list({
    apiToken: controller.apiToken,
    params: params,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonDeletePatientSegment: Handler = async (
  controller,
  params
) => {
  const [response, error, network] = await PatientSegmentDetailM.delete({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    pk: params.id,
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

// common Proxy Patient
export const commonListProxyPatient: Handler = async (controller, params) => {
  const [response, error, network] = await ProxyPatientListView.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { ...params },
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const commonListProxyPatientNoUser: Handler = async (
  controller,
  params
) => {
  const [response, error, network] = await ProxyPatientNoUserListView.list({
    apiToken: controller.apiToken || Cookies.get("apiToken"),
    params: { ...params },
  });
  if (error) {
    return [error, null];
  } else {
    return [false, response];
  }
};

export const handleSearchPatient: Handler = async (controller, params) => {
  const state = controller.getState();
  const [error, response] = await commonListPatientSegmentList(controller, {
    segment_type: "CLINICAL",
    segment_group: state.selectedDivision?.division_code,
    have_tk_detail: "true",
    keyword: params?.search,
  });

  if (error) {
    controller.setState({
      loadingPatientSegment: false,
      errorMessage: error,
      successMessage: null,
      patientSegmentList: { next: null, previous: null, total: 0, items: [] },
      selectedPatient: {},
      onSearch: false,
    });
  } else {
    controller.setState({
      loadingPatientSegment: false,
      errorMessage: null,
      successMessage: null,
      patientSegmentList: response,
      selectedPatient: {},
      onSearch: true,
    });
  }

  // const [response, error, network] = await ProxyPatientListView.list({
  //   apiToken: controller.apiToken || Cookies.get("apiToken"),
  //   params: { ...params },
  // });

  // return [response.items, error, network];
};

export const handleSelectPatientSearch: Handler = (controller, params) => {
  controller.setState({ patientSearch: params.data });
};

export const handleSelectedDivisionId: Handler = async (controller, params) => {
  const state = controller.getState();
  let divisionHasUser = state.divisionHasUser;
  let divisionId = params.divisionId;

  console.log("handleSelectedDivisionId params ", params);
  // console.log('divisionHasUser: ', divisionHasUser);
  // console.log("handleSelectedDivisionId params ", params)
  // console.log("handleSelectedDivisionId divisionHasUser: ", divisionHasUser);
  // console.log('divisionId: ', divisionId);
  let segment = divisionHasUser?.find(
    (item: any) => item.division_id === divisionId
  )?.segment;

  let division_code = divisionHasUser?.find(
    (item: any) => item.division_id === divisionId
  )?.division_code;
  // console.log('division_code: ', division_code);
  let showSegment = false
  if (divisionHasUser?.find((item: any) => item.division_id === divisionId)?.extra?.includes("show_auto_segment")) {
    var obj = JSON.parse(divisionHasUser?.find((item: any) => item.division_id === divisionId)?.extra);
    showSegment = obj?.show_auto_segment
  }


  // console.log("handleSelectedDivisionId division_code: ", division_code);
  if (divisionId && division_code) {
    let divisionPatient = null;
    /// division-patient
    try {
      divisionPatient = await axios.get(
        `${FAST_API}/api/segment/division-patient/`,
        {
          params: {
            division_id: divisionId,
            segment_group: division_code,
          },
          headers: {
            Authorization: `Token ${controller.apiToken}`,
          },
        }
      );
      console.log(divisionPatient.data);
      if (divisionPatient.data) {
        controller.setState({
          divisionPatient: divisionPatient.data,
        });
      }
    } catch (e) {
      console.log("Error getting division patients");
      console.log((e as Error).toString());
    }
    /// get-clinical-segment
    try {
      const clinicalSeg = await axios.get(
        `${FAST_API}/api/segment/get-clinical-segment/`,
        {
          params: {
            segment_group: segment || "dm",
          },
          headers: {
            Authorization: `Token ${controller.apiToken}`,
          },
        }
      );
      if (clinicalSeg?.data) {
        controller.setState({
          clinicalSegment: clinicalSeg.data.items,
        });
      }
    } catch (e) {
      console.log("Error getting clinical segments from fastapi");
      console.log((e as Error).toString());
    }
  }
  controller.setState({
    selectedDivisionId: divisionId,
    selectedDivision: divisionHasUser?.find(
      (item: any) => item.division_id === divisionId
    ),
    showSegment: showSegment
  });

  controller.handleEvent({ message: "handleGetMarketingSegmentList" });
};

export const PreferedDidMount: Handler = async (controller, params) => {
  console.log("PreferedDidMount");

  const state = controller.getState();

  const [medProgram, medProgramError] = await MedProgramListView.list({
    apiToken: controller.apiToken,
    ...(state?.selectedOrganizationId && {
      params: { organization: state?.selectedOrganizationId },
    }),
  });
  console.log("PreferedDidMount medProgram: ", medProgram);
  console.log("PreferedDidMount medProgramError: ", medProgramError);

  const [subspecialties, subspecialtiesError] =
    await ProxySubspecialtyListView.list({
      apiToken: controller.apiToken,
      ...(state?.selectedOrganizationId && {
        params: { organization: state?.selectedOrganizationId },
      }),
    });
  console.log("PreferedDidMount subspecialtiesError: ", subspecialtiesError);
  console.log("PreferedDidMount subspecialties: ", subspecialties);

  controller.setState({
    ...(medProgram && { medProgramList: medProgram?.items }),
    ...(medProgramError && { medProgramList: [] }),
    ...(subspecialties && { subspecialtiesList: subspecialties?.items }),
    ...(subspecialtiesError && { subspecialtiesList: [] }),
  });
};

export const handleSelectedOrganization: Handler = async (
  controller,
  params
) => {
  const [medProgram, medProgramError] = await MedProgramListView.list({
    apiToken: controller.apiToken,
    ...(params?.organizeCode && {
      params: { organization: params?.organizeCode },
    }),
  });

  const [subspecialties, subspecialtiesError] =
    await ProxySubspecialtyListView.list({
      apiToken: controller.apiToken,
      ...(params?.organizeCode && {
        params: { organization: params?.organizeCode },
      }),
    });

  controller.setState({
    selectedOrganizationCode: params.organizeCode,
    selectedOrganizationId: params.organizeId,
    ...(medProgram && { medProgramList: medProgram?.items }),
    ...(medProgramError && { medProgramList: [] }),
    ...(subspecialties && { subspecialtiesList: subspecialties?.items }),
    ...(subspecialtiesError && { subspecialtiesList: [] }),
    servicesOfHospitalFB: [],
    servicesOfHospitalDJ: [],
  });
};

export const GetServicePreferred: Handler = async (controller, params) => {
  const state = controller.getState();

  if (state?.selectedOrganizationId) {
    console.log(
      "state?.selectedOrganizationId: ",
      state?.selectedOrganizationId
    );
    const [services, error] = await TopServiceListCreateView.list({
      apiToken: controller.apiToken,
      params: {
        organization: state?.selectedOrganizationId,
      },
    });

    let servicesItems = services?.items?.sort((a: any, b: any) => b.id - a.id);
    servicesItems = servicesItems?.map((item: any) => ({
      ...item,
      ...((item.icon || item.icon_url) && {
        currentIcon: item.icon != null ? item.icon : item.icon_url,
      }),
    }));

    controller.setState({ servicesOfHospitalDJ: servicesItems });

    console.log("GetServicePreferred error: ", error);
    console.log("GetServicePreferred services: ", services);
  }
};

export const DeleteServicePreferredIcon: Handler = async (
  controller,
  params
) => {
  console.log("DeleteServicePreferredIcon: ");

  let service = params?.serviceItem;
  let state = controller?.getState();

  if (!service?.id) {
    return;
  }

  if (state?.selectedOrganizationId) {
    let data = { ...service };
    console.log("data: ", data);

    data.icon = "";
    delete data.id;
    delete data.currentIcon;
    data.icon = null;
    data.icon_url = "";

    const [detail, error] = await TopServiceDetail.patch({
      apiToken: controller.apiToken,
      pk: service?.id,
      data: data,
    });

    if (detail) {
      GetServicePreferred(controller, params);
    }
  }
};

export const SaveServicePreferred: Handler = async (controller, params) => {
  let service = params?.serviceItem;
  let state = controller?.getState();

  if (!service?.id) {
    return;
  }

  // ทำให้ ค่า แว๊ป ต้อง กัน ออก
  // if (params?.card) {
  //   controller.setState({
  //     preferredLoading: { ...state.preferredLoading, [params?.card]: true },
  //   });
  // }

  if (state?.selectedOrganizationId) {
    // "type": <"med_program" | "subspecialty">,
    // "display": "Vaccine",
    // "display_en": "",
    // "icon": null,
    // "icon_url": "https://www.iconpacks.net/icons/2/free-icon-injection-3674.png",
    // "seq": 1,
    // "active": false,
    // "organization": <org_id>,
    // "med_programs": [ <id>, ... ],
    // "subspecialties": [ <id>, ... ]

    let data = { ...service };
    console.log("data: ", data);
    delete data.id;
    delete data.currentIcon;

    // update แบบ มีไฟล์​

    if (data?.bufferIconFile) {
      // send multipart file
      let bufferIconFileicon = data.bufferIconFile;
      const [detail, error] = await TopServiceDetailM.patch({
        apiToken: controller.apiToken,
        pk: service?.id,
        data: { icon: bufferIconFileicon },
      });

      if (detail) {
        GetServicePreferred(controller, params);
      }

      console.log("Upload file error: ", error);
      console.log("Upload file detail: ", detail);

      if (error?.icon) {
        alert(
          `เกิดความผิดพลาดในการ upload file ${data.icon} \nกรุณาเลือก ไฟล์ใหม่ อีกครั้ง \n\n${error?.icon}`
        );
        return;
      }

      delete data.icon;
      const [detail2, error2] = await TopServiceDetail.patch({
        apiToken: controller.apiToken,
        pk: service?.id,
        data: data,
      });

      if (detail2) {
        GetServicePreferred(controller, params);
      }
    } else {
      // update ปกติ
      data.icon = null;

      const [detail, error] = await TopServiceDetail.patch({
        apiToken: controller.apiToken,
        pk: service?.id,
        data: data,
      });

      console.log("error: ", error);
      console.log("detail: ", detail);
      if (detail) {
        GetServicePreferred(controller, params);
      }
    }
  }
};

export const AddNewServicePreferred: Handler = async (controller, params) => {
  const state = controller.getState();

  if (state?.selectedOrganizationId) {
    console.log(
      "state?.selectedOrganizationId: ",
      state?.selectedOrganizationId
    );
    const [services, error] = await TopServiceListCreateView.create({
      apiToken: controller.apiToken,
      params: {
        organization: state?.selectedOrganizationId,
      },
      data: {
        ...params,
        organization: state?.selectedOrganizationId,
      },
    });

    // controller.setState({ servicesOfHospitalDJ: services?.items });

    if (services) {
      GetServicePreferred(controller, params);
    }

    console.log("GetServicePreferred error: ", error);
    console.log("GetServicePreferred services: ", services);
  }
};

export const DeleteServicePreferred: Handler = async (controller, params) => {
  const state = controller.getState();
  console.log("DeleteServicePreferred params: ", params);

  if (state?.selectedOrganizationId) {
    console.log(
      "state?.selectedOrganizationId: ",
      state?.selectedOrganizationId
    );

    const [services, error] = await TopServiceDetail.delete({
      apiToken: controller.apiToken,
      pk: params?.id,
    });

    if (!error) {
      GetServicePreferred(controller, params);
    }

    console.log("GetServicePreferred error: ", error);
    console.log("GetServicePreferred services: ", services);
  }
};

export const GetServiceFromFirestore: Handler = async (controller, params) => {
  console.log("GetServiceFromFirestore controller: ", controller);
  const { selectedOrganizationCode } = controller.getState();
  console.log("selectedOrganizationCode: ", selectedOrganizationCode);

  if (selectedOrganizationCode) {
    console.log("if (selectedOrganizationCode: ", selectedOrganizationCode);
    const data = await controller.db
      .collection(SERVICE_HOSPTIAL)
      .doc(selectedOrganizationCode)
      .get();
    let listOfService = [...(data?.data()?.ServiceItems || [])]?.reverse();
    console.log("GetServiceFromFirestore listOfService: ", listOfService);
    console.log("data: ", data);
    // Optimize Cost
    controller.setState({
      servicesOfHospitalFB: listOfService,
    });
    return;
  }
};
export const DeleteIconFromFirebase: Handler = async (controller, params) => {
  let service = params?.serviceItem;
  let state = controller?.getState();
  let firebase_project = CONFIG.FIREBASE_PROJECT;

  if (params?.card) {
    controller.setState({
      preferredLoading: { ...state.preferredLoading, [params?.card]: true },
    });
  }

  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  console.log("Delete !! ", service?.savedIconDocIdFirebase);
  try {
    controller.storage
      .refFromURL(`${config?.serviceGsBucketName}`)
      .child(service?.savedIconDocIdFirebase)
      .delete()
      .then(() => {
        console.log("Success delete");
      })
      .catch((error) => {
        console.error("Delete error", error);
      });
  } catch (error) {
    console.error("controller.storage error", error);
  }

  // then update
  service.iconNameFromLocalFile = "";
  service.iconPathFromFirebase = "";
  service.iconExternalURL = "";
  service.savedIconDocIdFirebase = "";

  let allService = [...(state.servicesOfHospitalFB || [])]?.reverse();
  let idx = allService?.findIndex((item: any) => item.id === service.id);
  allService[idx] = service;
  console.log("allService: ", allService);

  controller.db
    .collection(SERVICE_HOSPTIAL)
    .doc(state.selectedOrganizationCode)
    .set({ ServiceItems: allService })
    .then(() => {
      console.log("Document successfully written!");
      if (params?.card) {
        GetServiceFromFirestore(controller, params);
        controller.setState({
          preferredLoading: {
            ...state.preferredLoading,
            [params?.card]: false,
          },
        });
      }
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      if (params?.card) {
        controller.setState({
          preferredLoading: {
            ...state.preferredLoading,
            [params?.card]: false,
          },
        });
      }
    });
};
export const SaveServiceItemToFirebase: Handler = async (
  controller,
  params
) => {
  console.log("SaveServiceItemToFirebase params: ", params);

  let service = params?.serviceItem;
  let state = controller?.getState();
  let firebase_project = CONFIG.FIREBASE_PROJECT;

  if (!service?.id) {
    return;
  }

  if (params?.card) {
    controller.setState({
      preferredLoading: { ...state.preferredLoading, [params?.card]: true },
    });
  }

  if (firebase_project === "") {
    console.error("Not config FIREBASE_PROJECT in base.json");
  }
  let config = firebaseConfig.find(
    (item: any) => item.projectId === firebase_project
  );

  // Optimize cost
  let path = "";
  if (
    service?.bufferIconFile &&
    service?.iconPathFromFirebase &&
    service?.savedIconDocIdFirebase
  ) {
    // have new File , and have old file in storage
    console.log("Delete !! ", service?.savedIconDocIdFirebase);
    controller.storage
      .refFromURL(`${config?.serviceGsBucketName}`)
      .child(service?.savedIconDocIdFirebase)
      .delete()
      .then(() => {
        console.log("Success delete");
      })
      .catch((error) => {
        console.error("Delete error", error);
      });
  } else {
    console.log(" No file to delete");
  }

  if (service?.bufferIconFile || service?.iconExternalURL) {
    path = `icon/${state.selectedOrganizationCode}/${service?.id}/${service?.previewIconName}`;
    console.log("service?.iconExternalURL: ", service?.iconExternalURL);
    console.log("service.iconPathFromFirebase: ", service.iconPathFromFirebase);
    console.log("path: ", path);
    let uploadTask: any = {};

    if (service?.bufferIconFile) {
      /// --- Local File --- ///
      console.log("service?.bufferIconFile: ", service?.bufferIconFile);
      uploadTask = controller.storage
        .refFromURL(`${config?.serviceGsBucketName}`)
        .child(path)
        .put(service?.bufferIconFile);

      await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot: any) => {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                console.log("Upload is paused");
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                console.log("Upload is running");
                break;
            }
          },
          (error: any) => {
            // Handle unsuccessful uploads
            console.error(" Failed to upload ", error);
            service.savedIconDocIdFirebase = "";
            service.iconPathFromFirebase = "";
          },
          () => {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...
            uploadTask.snapshot.ref
              .getDownloadURL()
              .then((downloadURL: any) => {
                console.log(" Resolved downloadURL ", downloadURL);
                resolve(downloadURL);
                service.iconPathFromFirebase = downloadURL;
                service.savedIconDocIdFirebase = path;
              });
          }
        );
      });
    } else if (service?.iconExternalURL) {
      /// --- URL Icon --- ///
      console.log("service?.iconExternalURL: ", service?.iconExternalURL);
      service.iconPathFromFirebase = service?.iconExternalURL;
      service.savedIconDocIdFirebase = "";
    }
  }

  delete service.bufferIconFile;
  delete service.previewIconPathURL;
  delete service.previewIconName;

  let allService = [...(state.servicesOfHospitalFB || [])]?.reverse();
  let idx = allService?.findIndex((item: any) => item.id === service.id);
  allService[idx] = service;
  console.log("allService: ", allService);

  controller.db
    .collection(SERVICE_HOSPTIAL)
    .doc(state.selectedOrganizationCode)
    .set({ ServiceItems: allService })
    .then(() => {
      console.log("Document successfully written!");
      if (params?.card) {
        GetServiceFromFirestore(controller, params);
        controller.setState({
          preferredLoading: {
            ...state.preferredLoading,
            [params?.card]: false,
          },
        });
      }
    })
    .catch((error) => {
      console.error("Error writing document: ", error);
      if (params?.card) {
        controller.setState({
          preferredLoading: {
            ...state.preferredLoading,
            [params?.card]: false,
          },
        });
      }
    });
};

export const addNewServiceToFirebase: Handler = async (controller, params) => {
  console.log("addNewServiceToFirebase params: ", params);
  let state = controller.getState();
  // console.log("state.servicesOfHospitalFB: ", state.servicesOfHospitalFB);

  let newData = params;
  newData.id = uuidv4();

  // Optimize cost
  let addUpdateTask;
  var organizeCodeDocRef = controller.db
    .collection(SERVICE_HOSPTIAL)
    .doc(state.selectedOrganizationCode);

  let organ = await organizeCodeDocRef.get().catch((error) => {
    console.error("Error getting ", SERVICE_HOSPTIAL, " error: ", error);
  });

  let organRef = controller.db
    .collection(SERVICE_HOSPTIAL)
    .doc(state.selectedOrganizationCode);

  if (organ?.exists) {
    // Update
    addUpdateTask = await organRef
      .update({
        ServiceItems: firebase?.firestore.FieldValue.arrayUnion(newData),
      })
      .catch((error) => {
        console.error("Error Update ", newData);
      });
  } else {
    // New
    addUpdateTask = await organRef
      .set({
        ServiceItems: firebase?.firestore.FieldValue.arrayUnion(newData),
      })
      .catch((error) => {
        console.error("Error Add New ", newData);
      });
  }

  GetServiceFromFirestore(controller, params);
};
export const deleteServiceToFirebase: Handler = async (controller, params) => {
  let state = controller.getState();

  if (state.servicesOfHospitalFB) {
    let prepare = [...state.servicesOfHospitalFB];
    console.log("deleteServiceToFirebase params: ", params);
    let idx = state.servicesOfHospitalFB?.findIndex(
      (item: any) => item.id === params?.id
    );
    console.log(" state.servicesOfHospitalFB?: ", state.servicesOfHospitalFB);

    if (idx !== -1) {
      let savedIconDocIdFirebase = prepare[idx]?.savedIconDocIdFirebase;
      let firebase_project = CONFIG.FIREBASE_PROJECT;
      if (firebase_project === "") {
        console.error("Not config FIREBASE_PROJECT in base.json");
      }
      let config = firebaseConfig.find(
        (item: any) => item.projectId === firebase_project
      );

      if (savedIconDocIdFirebase) {
        controller.storage
          .refFromURL(`${config?.serviceGsBucketName}`)
          .child(savedIconDocIdFirebase)
          .delete()
          .then(() => {
            console.log("Success icon delete");
          })
          .catch((error) => {
            console.error("Delete error", error);
          });
      }

      prepare.splice(idx, 1);
      let reverse = [...prepare]?.reverse();

      controller.db
        .collection(SERVICE_HOSPTIAL)
        .doc(state.selectedOrganizationCode)
        .update({ ServiceItems: reverse })
        .then(() => {
          GetServiceFromFirestore(controller, params);
          console.log(" success delete ");
        })
        .catch((error) => console.error("failed to delete"));
    } else {
      console.error(" Can't find service for delete");
      GetServiceFromFirestore(controller, params);
    }
  }
};

const GetMarketing: Handler = async (
  controller,
  params: { collection: string; segmentName?: string; engagement: string }
) => {
  try {
    let query = controller.db.collection(params.collection);

    if (params.segmentName) {
      query = query.where(
        "segment",
        "array-contains",
        params.segmentName
      ) as any;
    }

    const result = await query.get();
    //
    const list = result.docs.map((doc: any) => ({
      id: doc.id,
      ...doc.data(),
    })) as any[];

    const promiseArray = list.map((item) => {
      return controller.db
        .collection(params.engagement)
        .where("id", "==", item.id)
        .get();
    });

    const response = await Promise.all(promiseArray);

    const mergeList = response.map((item, index) => {
      const data = item.docs[0]?.data?.() || {};
      return {
        ...list[index],
        likes: data.likes || [],
        clicks: data.clicks || [],
        saved: data.saved || [],
        shares: data.shares || [],
        follows: data.follows || [],
      };
    });

    return [null, mergeList];
  } catch (e) {
    console.log("Error getting clinic content from firebase");
    return [(e as Error).toString(), null];
  }
};

export const commonGetMarketingPost: Handler = (
  controller,
  params: { segmentName?: string }
): Promise<[any, PostExI.PostData[] | null]> => {
  return GetMarketing(controller, {
    collection: "PostDivision",
    engagement: "PostEngagement",
    segmentName: params.segmentName,
  });
};

export const commonGetMarketingPromotion: Handler = (
  controller,
  params: { segmentName?: string }
): Promise<[any, PromotionExI.PromotionData[] | null]> => {
  return GetMarketing(controller, {
    collection: "PromotionDivision",
    engagement: "PromotionEngagement",
    segmentName: params.segmentName,
  });
};

// Issue 60121
const groupingOrganization = (divisionList: any[]) => {
  // console.log("groupingOrganization divisionList: ", divisionList);
  let ids = divisionList?.map((d: any) => d?.organization?.id);
  let uniqueId = Array.from(new Set(ids));
  let udl = uniqueId?.map((uid: any) => {
    return divisionList.find(
      (division: any) => division?.organization?.id === uid
    )?.organization;
  });
  return udl;
};
