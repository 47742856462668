import WasmController from "../../react-lib/frameworks/WasmController";

import { History } from "history";

// APIS
import FastAPIRequestView from "issara-sdk/apis/FastAPIRequestViewM";
// PRX
import V3TopServicesListView from "issara-sdk/apis/V3TopServicesListView_apps_PRX";
import V3MedServicesListView from "issara-sdk/apis/V3MedServicesListView_apps_PRX";
import V3SearchProgramSpecialtyListView from "issara-sdk/apis/V3SearchProgramSpecialtyListView_apps_PRX";
import V3MedProgramsDetailView from "issara-sdk/apis/V3MedProgramsDetailView_apps_PRX";
// USERS
import UserFamilyAPI from "issara-sdk/apis/UserFamilyAPI_users";

// Types
import * as Types from "./Types";

// Interface
import { State as MainState } from "../MobSmartAppointmentInterface";

// Const.
import { APIS, LIMIT_SEARCH, URLS } from "./Constants";

export type State = Partial<{
  topServiceList: Types.TopServiceListType[];
  centerGroupList: Types.CenterGroupType[];
  appointmentCenterList: Types.AppointmentCenterType[];
  availableDoctorSearch?: Types.AppDoctorResponse;
  allAvailableDoctorList: Types.AppDoctorResponse;
  doctorSearchList: Types.AppDoctorResponse | null;
  medServiceLists: Record<string, any>[];
  medProgramLists: Record<string, any>[];
}>;

type Picked = Partial<
  Pick<
    MainState,
    "selectedHospital" | "loadingStatus" | "loadingSearch" | "textSearch"
  >
>;

export const StateInitial: State = {};

export type Event =
  // Handler
  | {
      message: "HandleDoctorAppointmentAction";
      params: {
        doctor: Types.AppointmentDoctorType;
        action?: Types.DoctorAppointmentActionType;
        history: History<any>;
        storedState?: Record<string, any>;
        card?: string;
        division?: Record<string, any>;
      };
    }
  // Get
  | {
      message: "GetListCenterGroup";
      params: { hospital?: string; card?: string; lang: string };
    }
  | {
      message: "GetListAppointmentCenter";
      params: { hospital?: string; lang: string };
    }
  | {
      message: "GetListAvailableDoctor";
      params: {
        card: string;
        page: number;
        concat?: boolean;
        refresh?: boolean;
        status?: boolean;
      } & Types.FilterDoctorType;
    }
  | { message: "GetListMedService"; params: { hospital: string; card: string } }
  | {
      message: "GetListMedProgram";
      params: { hospital: string; med_program: string; card: string };
    }
  | {
      message: "HandleGetTopService";
      params: { code?: string; simulator?: boolean; card?: string };
    }
  | {
      message: "HandleSearchListDoctor";
      params: { name: string; emptyClear?: boolean };
    }
  | {
      message: "HandleConfirmCheckupType";
      params: {
        history: History<any>;
        checkup: Record<string, any>;
        card: string;
      };
    };

export type Data = {};

type Handler<P = any, R = any> = (
  controller: WasmController<State & Picked, Event, Data>,
  params: P
) => R;

type Params<A extends Event["message"]> = Extract<
  Event,
  { message: A }
>["params"];

/* ------------------------------------------------------ */

/*                         Handler                        */

/* ------------------------------------------------------ */
export const HandleGetTopService: Handler<
  Params<"HandleGetTopService">
> = async (controller, params) => {
  let state = controller.getState();
  const card = params.card || "";

  if (params.card) {
    controller.setState({
      loadingStatus: { ...state.loadingStatus, [card]: true },
    });
  }

  let temp: any[] = [[null, null], {}];

  if ((params?.simulator && params?.code) || !params?.simulator) {
    temp = await Promise.all([GetTopService(controller, params), {}]);
  }

  const [[resTopService], configPrxEnableEcoupon] = temp;

  const teleConsult = {
    active: true,
    display: "Tele-consult",
    icon: null,
    icon_url: "/images/Feed/service-vaccine-green.png",
    id: "telemed",
    is_telemed: true,
  };

  const ecoupon = {
    active: true,
    display: "E-coupon",
    icon: null,
    icon_url: "/images/Feed/schedule-green.png",
    id: "ecoupon",
    is_ecoupon: true,
  };

  const topService = [teleConsult, ecoupon, ...(resTopService?.items || [])];

  if (!configPrxEnableEcoupon) {
    topService.splice(1, 1);
  }

  state = controller.getState();

  controller.setState({
    topServiceList: topService,
    loadingStatus: { ...state.loadingStatus, [card]: false },
  });
};

export const HandleSearchListDoctor: Handler = async (controller, params) => {
  const state = controller.getState();

  if (params.emptyClear && !params.name) {
    return controller.setState({
      loadingSearch: false,
      textSearch: params.name,
      doctorSearchList: null,
    });
  }

  controller.setState({ loadingSearch: true, textSearch: params.name });

  const result = await GetAvailableDoctorFilterNOrder(controller, {
    ...params,
    hospital: state.selectedHospital?.code,
    refresh: true,
  });

  controller.setState({
    loadingSearch: false,
    doctorSearchList: result.result,
  });
};

export const GetListAvailableDoctor: Handler<
  Params<"GetListAvailableDoctor">
> = async (controller, params) => {
  let state = controller.getState();
  const items = state.availableDoctorSearch?.items || [];

  const card = params.card || "";
  let status = params.status ? "LOADING" : true;

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: status },
  });

  const result = await GetAvailableDoctorFilterNOrder(controller, {
    ...params,
    page: params.page,
    pagination: true,
  });

  state = controller.getState();
  status = params.status ? "SUCCESS" : false;

  const data = {
    total: result.result?.total,
    items: result.filter,
    page: params.page,
  };

  if (params.concat && data.items) {
    data.items = [...items, ...data.items];
  }

  controller.setState({
    availableDoctorSearch: data,
    loadingStatus: { ...state.loadingStatus, [card]: status },
    allAvailableDoctorList: result.result,
  });
};

export const GetListCenterGroup: Handler<Params<"GetListCenterGroup">> = async (
  controller,
  params
) => {
  let state = controller.getState();

  const card = params.card || "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: true },
  });

  const [result] = await FastAPIRequestView.get({
    bearer: controller.cookies.get("bearer"),
    endpoint: APIS.CENTER_GROUP_APP,
    params: {
      hospital: params.hospital,
    },
  });

  let items: any[] = Object.values(result || {});

  items = items.map((item) => ({
    ...item,
    name: params.lang === "th" ? item.name_th : item.name_en,
    image: item.icon_url,
    appointment_type: item.name_en.includes("Check-up")
      ? "check_up"
      : "specialty",
  }));

  state = controller.getState();

  controller.setState({
    centerGroupList: items,
    loadingStatus: { ...state.loadingStatus, [card]: false },
  });
};

export const GetListAppointmentCenter: Handler<
  Params<"GetListAppointmentCenter">
> = async (controller, params) => {
  const [result] = await FastAPIRequestView.get({
    bearer: controller.cookies.get("bearer"),
    endpoint: APIS.CENTER_APP,
    params: {
      hospital: params.hospital,
    },
  });

  let items: any[] = Object.values(result || {});

  items = items.map((item) => ({
    ...item,
    name: params.lang === "th" ? item.full_title_th : item.full_title_en,
    image: item.image_url,
  }));

  controller.setState({
    appointmentCenterList: items,
  });
};

export const GetListMedService: Handler<Params<"GetListMedService">> = async (
  controller,
  params
) => {
  let state = controller.getState();
  const card = params.card || "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: true },
  });

  const [res] = await V3MedServicesListView.list({
    params: {
      hospital: params.hospital,
    },
    apiToken: controller.cookies.get("apiToken"),
  });

  state = controller.getState();

  const seq = {
    First: 1,
    Follow: 2,
    Other: 3,
  } as any;

  let items = (res?.items || []).map((item: any) => {
    const seqNumber =
      seq[/First|Follow|Other/g.exec(item.name_en)?.[0] || ""] || 99;

    return {
      ...item,
      seq: seqNumber,
      ...(seqNumber === 1 && { first_consultation: true }),
    };
  });

  items = items.sort((a: any, b: any) => a.seq - b.seq);

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: false },
    medServiceLists: items,
  });
};

export const GetListMedProgram: Handler<Params<"GetListMedProgram">> = async (
  controller,
  params
) => {
  let state = controller.getState();
  const card = params.card || "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: true },
  });

  const [res] = await V3SearchProgramSpecialtyListView.list({
    apiToken: controller.cookies.get("apiToken"),
    params: {
      hospital: params.hospital,
      med_program: params.med_program,
    },
  });

  const items = (res?.items || []).filter(
    (item: any) => item.type === "med_program"
  );

  state = controller.getState();

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: false },
    medProgramLists: items,
  });
};

export const HandleDoctorAppointmentAction: Handler<
  Params<"HandleDoctorAppointmentAction">
> = async (controller, params) => {
  const state = controller.getState();
  const locState = params.history.location.state || {};
  const card = params.card || "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: true },
  });

  let families = await GetFamilies(controller, { refresh: params.action });

  const doctor = params.doctor;
  const singleLocation = doctor.divisions?.length === 1;
  const isFamily = !!families?.length;
  const action = params.action || locState.appointmentLocation;

  const url = DetermineRedirectUrl({
    doctor,
    singleLocation,
    isFamily,
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: false },
  });

  if (url) {
    const division = doctor.divisions?.[0] || {};

    const formattedDivision = {
      id: division.division_id,
      name: division.division_name,
      price: division.doctor_fee,
      is_telemed: !!division.is_telemed,
    };

    const state = {
      doctor,
      appointmentLocation: action,
      division: params.division ? params.division : formattedDivision,
      appointmentType: "doctor",
      families,
      hospital: {
        code: doctor.hospital_code,
        name: doctor.hospital_name,
      },
    } as any;

    if (singleLocation && !isFamily) {
      state.appointmentFor = "myself";
    }

    controller.handleEvent({
      message: "HandleHistoryPushState" as any,
      params: {
        pathname: url,
        history: params.history,
        state,
        storedState: params.storedState,
      } as any,
    });
  }
};

const DetermineRedirectUrl = (params: {
  doctor: any;
  isFamily: boolean;
  singleLocation: boolean;
}) => {
  let url = "";

  const doctor = params.doctor;
  const pathname = globalThis.location.pathname;
  const multiLocations = (doctor.divisions?.length || 0) > 1;

  // กรณีที่แพทย์ออกตรวจมากกว่า 1 location
  if (multiLocations) {
    url = URLS.SELECT_CLINIC;

    if (pathname.includes(URLS.SELECT_CLINIC)) {
      url = params.isFamily ? URLS.APPOINTMENT_FOR : URLS.APPOINTMENT_REASON;
    }
  }
  // กรณีที่แพทย์ออกตรวจแค่ 1 location (ผูก family)
  else if (params.singleLocation && params.isFamily) {
    url = URLS.APPOINTMENT_FOR;
  }
  // กรณีที่แพทย์ออกตรวจแค่ 1 location (ไม่ได้ผูก family)
  else if (params.singleLocation) {
    url = URLS.APPOINTMENT_REASON;
  } else {
    url = URLS.APPOINTMENT_REASON;
  }

  return url;
};

export const HandleConfirmCheckupType: Handler<
  Params<"HandleConfirmCheckupType">
> = async (controller, params) => {
  const state = controller.getState();
  const card = params.card || "";

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: true },
  });

  const [res] = await V3MedProgramsDetailView.retrieve({
    apiToken: controller.cookies.get("apiToken"),
    pk: params.checkup.id,
  });

  controller.setState({
    loadingStatus: { ...state.loadingStatus, [card]: false },
  });

  controller.handleEvent({
    message: "HandleHistoryPushState",
    params: {
      history: params.history,
      pathname: URLS.SELECT_DATETIME,
      state: {
        checkup: { ...params.checkup, ...res },
      },
    },
  } as any);
};

const GetFamilies: Handler = async (controller, params) => {
  let families = globalThis.history.state?.state?.families || [];

  if (params.refresh) {
    const [result] = await GetUserFamily(controller, {});

    families = result.results || [];
  }

  return families;
};

/* ------------------------------------------------------ */

/*                          APIS                          */

/* ------------------------------------------------------ */

const GetUserFamily: Handler = async (controller, params) => {
  return await UserFamilyAPI.list({
    params: params,
    apiToken: controller.cookies.get("apiToken"),
  });
};

const GetTopService: Handler<{ code?: string }> = async (
  controller,
  params
) => {
  let state = controller.getState();
  let lang = "";

  if (globalThis.mobile.iOSLang) {
    lang = globalThis.window.navigator?.language;
  }

  const [res, error, network] = await V3TopServicesListView.list({
    apiToken: controller.cookies.get("apiToken"),
    ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
    params: {
      hospital: params.code ? params.code : state.selectedHospital?.code,
    },
  });

  return [res, error, network];
};

// -const GetAvailableDoctor: Handler<
//   {
//     limit?: number;
//   } & FilterDoctorType
// > = async (controller, params) => {
//   let lang = "";
//   if (globalThis.window.mobile.iOSLang) {
//     lang = globalThis.window.navigator?.language;
//   }
//   const [res, error, network] = await V3AvailableDoctorListView.list({
//     apiToken: controller.cookies.get("apiToken"),
//     params: {
//       limit: params.limit ? params.limit : LIMIT_SEARCH,
//       offset: LIMIT_SEARCH * ((params.page || 1) - 1),
//       gender: params.gender,
//       name: params.name,
//       centergroup: params.centergroup,
//       center: params.center,
//       hospital: params.hospital,
//       telemed: params.telemed,
//       onsite: params.onsite,
//       score: params.score,
//     },
//     ...(lang && { extra: { headers: { "Accept-Language": lang } } }),
//   });
//   return [res, error, network];
// };

const GetAvailableDoctorFilterNOrder: Handler<
  {
    refresh?: boolean;
    limit?: number;
    pagination?: boolean;
  } & Types.FilterDoctorType,
  Promise<{ result: any; filter: any[] }>
> = async (controller, params) => {
  const state = controller.getState();

  let result: { items: any[]; total: number } | undefined;

  if (params.refresh) {
    const res = await FastAPIRequestView.get({
      bearer: controller.cookies.get("bearer"),
      endpoint: APIS.DOCTOR_APP,
      params: {
        search: params.name || undefined,
        center: params.defaultCenter,
      },
    });

    let items = (res[0] || []).map((item: any) => ({
      ...item,
      centerids: item.centers.map((acc: any) => acc.center_id),
      doctor_fee: Math.min(
        ...item.divisions.map((division: any) => Number(division.doctor_fee))
      ),
    }));

    items = items.filter((item: any) => item.centers?.length);

    result = { items: items, total: items.length || 0 };
  } else {
    result = state.allAvailableDoctorList;
  }

  const limit = params.limit ? params.limit : LIMIT_SEARCH;
  const page = params.page || 1;
  const offset = (page - 1) * limit;

  let filter = (result?.items || []).filter((item: any) => {
    return (
      // compareValue(item.full_name, params.name, "in") &&
      compareValue(item.score, params.score, "gte") &&
      compareValue(item.is_telemed, params.telemed) &&
      compareValue(item.is_onsite, params.onsite) &&
      compareValue(item.gender, params.gender) &&
      compareValue(item.hospital_code, params.hospitals, "in") &&
      compareValue(item.centerids, params.center, "in")
    );
  });

  if (params.order) {
    filter = sortDataByField(filter, params.order);
  }

  return {
    result,
    filter: filter.slice(offset, offset + limit),
  };
};

/* ------------------------------------------------------ */

/*                          Utils                         */

/* ------------------------------------------------------ */
const compareValue = (
  data: number | string | boolean | number[],
  compare?: number | string | boolean | string[],
  operator: "eq" | "in" | "gte" = "eq"
) => {
  const isCompare = Array.isArray(compare) ? compare.length : compare;

  if (!isCompare) {
    return true;
  }

  const operators = {
    eq: () => data === compare,
    gte: () => compare && data >= compare,
    in: () => {
      if (typeof data === "string") {
        if (Array.isArray(compare)) {
          return compare.includes(data);
        } else if (typeof compare === "string") {
          return data.includes(compare);
        }
      } else if (Array.isArray(data) && typeof compare === "number") {
        return data.includes(compare);
      }
      return false;
    },
    // Add more operators as needed
  };

  return operators[operator]();
};

const sortDataByField = (data: any[], order: Types.OrderByType) => {
  const sortFunctions = {
    asc: (a: any, b: any) => parseFloat(a) - parseFloat(b),
    desc: (a: any, b: any) => parseFloat(b) - parseFloat(a),
  };

  const sortedData = data.sort((a, b) => {
    const valueA = a[order.field];
    const valueB = b[order.field];

    if (valueA === null || valueA === undefined) return 1;

    if (valueB === null || valueB === undefined) return -1;

    return sortFunctions[order.by](valueA, valueB);
  });

  return sortedData;
};
